import React, { Component } from "react";
import SampleForm from "./SampleForm";
import { Form } from "semantic-ui-react";
import LoadingStandIn from "../LoadingStandIn";
import { TopLevelWrapper, SaveButton } from "../Wrappers";
import { SubmitErrorMessage } from "../SubmitErrorMessage";
import {submitRequestAndRedirect } from "../SubmitUtils";

class SampleEdit extends Component {
  async getSample() {
    fetch("/api/samples/" + this.props.sample_id)
      .then((res) => res.json())
      .then((res_sample) =>
        this.setState({ loading1: false, sample: res_sample })
      );
  }

  componentDidMount() {
    this.getSample();
  }

  state = {
    loading1: true,
    sample: {},
    submitErrors: [],
  };

  handleSampleChange = (sample) => {
    this.setState({sample: sample});
  }
  
  submitAndSave = (e) => {
    e.preventDefault();
    
    submitRequestAndRedirect("put", "samples", this.state.sample,
      (errors) => this.setState({submitErrors: errors})
    );
  };

  render() {
    return(
      <TopLevelWrapper icon="clipboard list" header="enterSample">
        <LoadingStandIn active={this.state.loading1} >
          <SubmitErrorMessage
            errors={this.state.submitErrors}
            contextPrefix="collectionModify"
            onDismiss={() => this.setState({submitErrors: []})}
          />        
          <Form>
            <SampleForm
              onChange={this.handleSampleChange}
              defaultSample={this.state.sample}
            />
          </Form>
          <SaveButton
            onClick={this.submitAndSave}
          />
        </LoadingStandIn>
      </TopLevelWrapper>
    );
  }
}

export default SampleEdit;
