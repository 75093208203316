import React, { Component } from "react";
import {
  Icon,
  Table,
  Header
} from "semantic-ui-react";
import { intlU } from "../../../intl";
import axios from "axios";
import { SampleLink } from "../SampleUtils"

function extractGAPPId(str) {
  const gappMatch = /(GAP)-(\d+)/.exec(str);
  if(gappMatch) {
      const gapp_number = gappMatch[2].padStart(4, "0")
      const gapp_id = "GAP-" + gapp_number;
      
      return gapp_id;
  } else {
    return null;
  }
}

class GenotypeStatus extends Component {
  state = {
    sample: {},
    status: "unknown",
    error: null
  }
  
  fetchAnimal = () => {
    this.setState({sample: {}, status: "fetching"})
    
    // By default, we try to find the animal using its ID.
    var fetchURL = "/api/samples/findByAnimalId/" + this.props.animalId;
    
    // However, if the string contains a GAPP ID, we'll use that.
    const gapp_id = extractGAPPId(this.props.animalId);
    if(gapp_id) {
      fetchURL = "/api/samples/findByGAPPId/" + gapp_id;
    }
    
    fetch(fetchURL)
      .then((res) => {
        if(res.ok) {
          res.json().then((sample) => this.setState({sample: sample, status: "waiting"}));
          this.props.onFetchSuccess(this.props.sampleIndex, "found")
        } else if(res.status === 404) {
          this.setState({sample: {}, status: "notfound"})
          this.props.onFetchSuccess(this.props.sampleIndex, "notfound")
        } else {
          this.setState({sample: {}, status: "fetcherror"})
          this.props.onFetchError(this.props.sampleIndex)
        }
      })
  }
  
  componentDidMount() {
    this.fetchAnimal()
  }
  
  readyToImport = () => {
    return (this.state.status === "waiting" || this.state.status === "notfound") &&
           this.props.vcfFile &&
           (this.state.sample.speciesId || this.props.speciesId) &&
           (this.state.sample.groups || this.props.groups);
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.animalId !== this.props.animalId) {
      this.fetchAnimal()
    } else if(prevProps.startImport !== this.props.startImport && 
              this.props.startImport &&
              this.readyToImport()) {
      this.attachGenotype();
    }
  }
  
  attachFile = () => {
    this.setState({status: "attaching"})    
    
    const data = new FormData();
    var blob = new Blob([this.props.vcfFile], {type: 'plain/text'});
    data.append("sampleFile", blob);
  
    axios.post("/api/samples/attach_file/" + this.state.sample.id, data)
      .then((res) => {
        if (res.status === 200) {
          this.setState({status: "success"})
          this.props.onImportSuccess(this.props.sampleIndex)
        } else {
          this.setState({status: "error", error: "attachError"})
          this.props.onImportError(this.props.sampleIndex)
        }
      })
      .catch((err) => {
        this.setState({status: "error", error: "attachError"})
        this.props.onImportError(this.props.sampleIndex)
      });    
  }
  
  createAnimal = () => {
    this.setState({status: "creating"})
    console.log("Creating animal.")
    const gapp_id = extractGAPPId(this.props.animalId);
    
    var newAnimal = {
      animalId: this.props.animalId,
      gappId: gapp_id,
      speciesId: this.props.speciesId,
      groups: this.props.groups,
    }

    axios.post("/api/samples", newAnimal)
      .then((res) => {
        if (res.status === 200) {
          this.setState({sample: res.data}, this.attachFile)
        } else {
          this.setState({status: "error", error: "sampleCreateError"})
          this.props.onImportError(this.props.sampleIndex)
        }
      })
      .catch((err) => {
        this.setState({status: "error", error: "sampleCreateError"})
        this.props.onImportError(this.props.sampleIndex)
      });
  }
  
  attachGenotype = () => {
    if(this.readyToImport()) {
      if(this.state.sample.id) {
        this.attachFile()
      } else {
        this.createAnimal()
      }
    }
  }    
  
  getIcon() {
    if(this.readyToImport()) {
      return <Icon name="play" color="green" onClick={this.attachGenotype} />
    } else if((this.state.status==="waiting" || this.state.status==="notfound") && !this.readyToImport()) {
      return <Icon name="play" color="grey" disabled={true} />
    } else if(this.state.status==="fetching") {
      return <Icon name="spinner" color="grey" />
    } else if(this.state.status==="attaching" || this.state.status==="creating") {
      return <Icon name="spinner" color="green" />      
    } else if(this.state.status==="error") {
      return <Icon name="warning circle" color="red" />
    } else if(this.state.status==="success") {
      return <Icon name="checkmark" color="green" />
    } else {
      return null;
    }
  }
      
  animalFound() {
    if(this.state.status === "fetching") {
      return <Icon name="spinner" color="grey" />
    } else if(this.state.status === "fetcherror") {
      return <><Icon name="warning circle" color="red" />{intlU("fetchError")}</>
    } else if(this.state.status === "notfound") {
      return <><Icon name="warning" color="yellow" />{intlU("sampleNotfound")}</>
    } else {
      return <SampleLink sample={this.state.sample} />;
    }
  }
      
  animalGenotype() {
    if(this.state.status === "fetching") {
      return <Icon name="spinner" color="grey" />
    } else if(this.state.status === "fetcherror") {
      return <><Icon name="warning circle" color="red" />{intlU("fetchError")}</>
    } else if(this.state.status === "notfound") {
      return null;
    } else if(this.state.status === "success") {
      return <><Icon name="checkmark" color="green" />{intlU("genotypeAttached")}</>
    } else {
      if(this.state.sample.hasGenotype) {
        return <><Icon name="warning circle" color="yellow" />{intlU("existingGenotype")}</>
      } else {
        return <><Icon name="checkmark" color="green" />{intlU("noGenotype")}</>
      }
    }    
  }
  
  render() {
    return <Table.Row key={this.props.sampleIndex}>
      <Table.Cell>
       {this.getIcon()}
      </Table.Cell>
      <Table.Cell>
        {this.props.animalId}
      </Table.Cell>
      <Table.Cell>
        {this.animalFound()}
      </Table.Cell>
      <Table.Cell>
        {this.animalGenotype()}
      </Table.Cell>      
    </Table.Row>
  }  
}

class ImportGenotypeTable extends Component {
  state = {
    importStatus: []
  };

  componentDidUpdate(prevProps) {
    if(this.props.sampleList && (this.props.sampleList !== prevProps.sampleList)) {
      this.setState({importStatus: Array(this.props.sampleList.length).fill("fetching")});
    }

    if(this.props.startImport && (this.props.startImport !== prevProps.startImport)) {
      var newImportStatus = [...this.state.importStatus];
      newImportStatus[0] = "importing";
      this.setState({importStatus: newImportStatus})
    }
  }

  changeImportStatus = (index, status) => {
    var newImportStatus = [...this.state.importStatus];
    newImportStatus[index] = status;
    
    if(status === "importSuccess" || status === "importError" || status === "sampleCreateError") {
      newImportStatus[newImportStatus.indexOf("waiting")] = "importing";
    }
    
    this.setState({importStatus: newImportStatus})              
  }

  onFetchSuccess = (index, status) => {
    this.changeImportStatus(index, "waiting")
  }

  onFetchError = (index) => {
    this.changeImportStatus(index, "fetchError")
  }
  
  onImportSuccess = (index) => {
    this.changeImportStatus(index, "importSuccess")     
  }
  
  onImportError = (index) => {
    this.changeImportStatus(index, "importError")
  }

  genotypeList() {
    var results = [];
    for(var i=0; i < this.props.sampleList.length; ++i) {
      var sampleName = this.props.sampleList[i]
      results.push(<GenotypeStatus 
          startImport={this.state.importStatus[i]==="importing"} 
          animalId={sampleName} 
          key={sampleName + "_" + i}
          sampleIndex={i}
          vcfFile={this.props.sampleFiles ? this.props.sampleFiles[i] : null} 
          speciesId={this.props.speciesId}
          groups={this.props.groups}
          createNew={false}
          onFetchSuccess={this.onFetchSuccess}
          onFetchError={this.onFetchError}
          onImportSuccess={this.onImportSuccess}
          onImportError={this.onImportError}
      />);
    }
    
    return results;
  }
  
  makeHeader(iconName, iconColor, status, message) {
    var countItems = this.state.importStatus.filter((x) => x === status).length
    
    if(countItems > 0) {
      return <Header as="h3">
        <Icon name={iconName} color={iconColor} />
        {countItems} {intlU(message)}
      </Header>
    } else {
      return null;
    }
  }
  
  tableHeader() {
    if(this.props.sampleList.length > 0) {
      return <>
        {this.makeHeader("spinner", "grey", "fetching", "xGenotypesFetching")}
        {this.makeHeader("hourglass half", "black", "waiting", "xGenotypesWaiting")}
        {this.makeHeader("warning circle", "red", "importError", "xGenotypesImportError")}
        {this.makeHeader("checkmark", "green", "importSuccess", "xGenotypesImportSuccess")}
      </>;
    } else {
      return <Header as="h3">{intlU("selectFileToBegin")}</Header>;
    }
  }
  
  render() {
    return (
      <>
        {this.tableHeader()}
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{intlU("ready")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("animalId")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("id")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("genotypeFile")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.genotypeList()}
          </Table.Body>
        </Table>        
      </>
    );
  }
}

export default ImportGenotypeTable;
