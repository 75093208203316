import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { intlU } from "../intl";
import "./RangeInputCSS.css";

class RangeInput extends Component {
  state = {
    lower: null,
    upper: null
  }
  
  handleOnChange = (whichInput) => (event) => {
    this.setState({[whichInput]: event.target.value},
                  () => this.props.onChange(event, {value:[this.state.lower, this.state.upper]}))
  }

  calculateSize = () => {
    return Math.max(this.props.min.toString().length, this.props.max.toString().length);
  }

  render() {
    return(
      <Form.Group inline  widths='equal'>
        <Form.Field required={this.props.isRequired}>
          <label>{intlU(this.props.label)}</label><br />
          <input
            onChange={this.handleOnChange("lower")}
            type={"number"}
            min={this.props.min}
            max={this.props.max}
            disabled={this.props.isDisabled}
            size={this.calculateSize()}
            className="RangeInput"
            style={{"MozAppearance": "textfield"}}
          />
          <>-</>
          <input
            onChange={this.handleOnChange("upper")}
            type={"number"}
            min={this.props.min}
            max={this.props.max}
            disabled={this.props.isDisabled}
            size={this.calculateSize()}
            style={{"MozAppearance": "textfield"}}
          />
        </Form.Field>        
      </Form.Group>
    );
  }
}

export default RangeInput;