import React, { Component } from "react";
import {
  Header,
  Icon,
  Table,
  Container,
  Button,
} from "semantic-ui-react";
import { intlU, intlIcon } from "../../intl";
import axios from "axios";
import { SampleLink } from "../samples/SampleUtils";
import LoadingStandIn from "../LoadingStandIn";
import { submitRequestAndRedirect } from "../SubmitUtils";
import { TopLevelWrapper, SectionWrapper, DeleteModal } from "../Wrappers";
import { SubmitErrorMessage } from "../SubmitErrorMessage";

const cookie = require("cookie");

class AnalysisAttributes extends Component {
  startAnalysis = () => {
    axios
      .post("/api/analyses/" + this.props.analysis.id + "/start")
      .then((res) => {
        if (res.status === 200) {
          window.location.assign("/analyses/" + this.props.analysis.id);
        } else {
          alert(intlU("error") + res.data.name + "\n" + res.data.message);
          window.location.assign("/analyses/" + this.props.analysis.id);
        }
      })
      .catch((err) => {
        alert(intlU("error") + err);
      });
  };  
  
  
  render() {
    const cookieLocale = cookie.parse(document.cookie).locale || "fr-CA";    
    return (
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{intlU("type")}</Table.Cell>
            <Table.Cell>
              <Icon name={intlIcon(this.props.analysis.type)} />
              {intlU(this.props.analysis.type)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{intlU("createdAt")}</Table.Cell>
            <Table.Cell>
              {new Date(this.props.analysis.createdAt).toLocaleDateString(cookieLocale)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{intlU("status")}</Table.Cell>
            <Table.Cell>
              <Icon name={intlIcon(this.props.analysis.status)} />
              {intlU(this.props.analysis.status)}
              {this.props.analysis.status === "waiting" && 
                <Button
                  icon
                  labelPosition="left"
                  onClick={this.startAnalysis}
                >
                  <Icon name="play" />
                  {intlU("startAnalysis")}
                </Button>
              }
            </Table.Cell>
          </Table.Row>
          { !this.props.analysis.sample ? null : 
            <Table.Row>
              <Table.Cell>{intlU("sample")}</Table.Cell>
              <Table.Cell>
                <SampleLink sample={this.props.analysis.sample} />
              </Table.Cell>
            </Table.Row>
          }
          <Table.Row>
            <Table.Cell>{intlU("collection")}</Table.Cell>
            <Table.Cell>
              <a href={"/collections/" + this.props.analysis.collection.id}>
                {this.props.analysis.collection.name}
              </a>
            </Table.Cell>
          </Table.Row>          
          
          <Table.Row>
            <Table.Cell>{intlU("comments")}</Table.Cell>
            <Table.Cell>{this.props.analysis.comments ? this.props.analysis.comments : intlU("nocomments")}</Table.Cell>
        </Table.Row>
        </Table.Body>
      </Table>  
    );
  }
}

class AnalysisResults extends Component {
  resultsByType() {
	  if(this.props.analysis.type === "assignment") {
      return(
	      <div>
	        <Header as="h3">{intlU("pcPlot1")}</Header>		 
		      <img alt="Plot 1" width="50%" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={"/api/analyses/" + this.props.analysis.id + "/plot1"}></img>
		      <Header as="h3">{intlU("pcPlot2")}</Header>		 
		      <img alt="Plot 2" width="50%" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={"/api/analyses/" + this.props.analysis.id + "/plot2"}></img>
		    </div>
	    );
	  } else if(this.props.analysis.type === "diversity") {
      return(
	      <div>
	        <Header as="h3">{intlU("diversityPlot1")}</Header>		 
		      <img alt="Plot 1" width="50%" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={"/api/analyses/" + this.props.analysis.id + "/plot1"}></img>
		      <Header as="h3">{intlU("diversityPlot2")}</Header>		 
		      <img alt="Plot 2" width="50%" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={"/api/analyses/" + this.props.analysis.id + "/plot2"}></img>
		    </div>
	    );
    } else if(this.props.analysis.type === "relatedness") {
      return(
	      <div>
	        <Header as="h3">{intlU("relatednessPlot1")}</Header>		 
		      <img alt="Plot 1" width="50%" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={"/api/analyses/" + this.props.analysis.id + "/plot1"}></img>
		      <Header as="h3">{intlU("relatednessPlot2")}</Header>		 
		      <img alt="Plot 2" width="50%" style={{marginLeft: "auto", marginRight: "auto", display: "block"}} src={"/api/analyses/" + this.props.analysis.id + "/plot2"}></img>
		    </div>      
      );
    } else {
      return("");
	  }
  }  
  
  render() {
	  if(this.props.analysis.status === "waiting" || this.props.analysis.status === "processing") {
		  return("");
	  } else if(this.props.analysis.status === "error") {
		  return(
      <div>
			  <Header as="h2">{intlU("results")}</Header>
        <Header as="h3">{intlU("consoleError")}</Header>
        <Container text>{<pre>{this.props.stderr}</pre>}</Container>
			</div>
		  );		  
    } else {
		  return(
      <div>
			  <Header as="h2">{intlU("results")}</Header>
        <Header as="h3">{intlU("consoleOutput")}</Header>
        <Container text>{<pre>{this.props.stdout}</pre>}</Container>
        <Header as="h3">{intlU("consoleError")}</Header>
        <Container text>{<pre>{this.props.stderr}</pre>}</Container>
			  {this.resultsByType()}
			</div>
		  );
	  }
  }    
}

class AnalysisShow extends Component {
  state = {
    loading: true,
    analysis: {},
	  stdout: "",
	  stderr: "",
    submitErrors: []
  };
  
  async getAnalysis() {
    fetch("/api/analyses/" + this.props.analysis_id)
      .then((res) => res.json())
      .then((res_analysis) =>
        this.setState({ loading: false, analysis: res_analysis })
      );
  }
  async getStdout() {
    fetch("/api/analyses/" + this.props.analysis_id + "/stdout")
    .then(res => res.text())
    .then(res_text => this.setState({stdout: res_text}))
  }

  async getStderr() {
    fetch("/api/analyses/" + this.props.analysis_id + "/stderr")
    .then(res => res.text())
    .then(res_text => this.setState({stderr: res_text}))
  }

  componentDidMount() {
    this.getAnalysis();
    this.getStdout();
    this.getStderr();	
  }

  deleteOnClick = (e) => {
    e.preventDefault();
    
    submitRequestAndRedirect("delete", "analyses", this.state.analysis,
      (errors) => this.setState({submitErrors: errors}))
  };

  // startEnabled = () => {
  //   return this.state.analysis.status === "waiting" || this.state.analysis.status === "completed" || this.state.analysis.status === "processing" || this.state.analysis.status === "error";
  // };

  // pauseEnabled = () => {
  //   return this.state.analysis.status === "processing";
  // };

  // pauseAnalysis = () => {
  //   alert(intlU("pauseAnalyses"));
  // };

  render() {
    return(
      <TopLevelWrapper
        icon={intlIcon(this.state.analysis.type)}
        header={this.state.analysis.name}
      >
        <LoadingStandIn active={this.state.loading}>
          <SubmitErrorMessage
            errors={this.state.submitErrors}
            onDismiss={() => this.setState({submitErrors:[]})}
          />
          <SectionWrapper header="parameters">
            <AnalysisAttributes
              analysis={this.state.analysis}
            />
          </SectionWrapper>
          <SectionWrapper>
		        <AnalysisResults
              analysis={this.state.analysis}
              stdout={this.state.stdout}
              stderr={this.state.stderr}
            />  
          </SectionWrapper>
          <DeleteModal 
            objectType="analyses" 
            object={this.state.analysis} 
            redirectURL="/analyses"
            contextPrefix="deleteAnalysis"
          />
        </LoadingStandIn>
      </TopLevelWrapper>
    );
  }
}

export default AnalysisShow;
