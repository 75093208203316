import React, { Component } from "react";
import FilterableAnalysesTable from "./FilterableAnalysesTable";
import {TopLevelWrapper} from "../Wrappers";

class AnalysesList extends Component {
  render() {
    return (
      <TopLevelWrapper
        icon="laptop"
        header="analysesList"
      >
        <FilterableAnalysesTable />
      </TopLevelWrapper>
    );
  }
}

export default AnalysesList;
