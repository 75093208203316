import React, { Component } from "react";
import {
  Icon,
  Table,
  Modal,
  Button,
  List,
  Popup,
  Header
} from "semantic-ui-react";
import axios from "axios";
import SampleForm from "../SampleForm";
import {SampleDate, Species, Ecotype, Country, Province, Sector, Latitude, Longitude, Population, SampleType, Sex, Age, Owner, validateSample } from "../SampleUtils";
import { intlU } from "../../../intl";

class BatchImportIcon extends Component {
  
  makePopup(iconName, iconColor, header, content) {
    return <Popup
        trigger={<Icon 
          name={iconName} 
          color={iconColor} 
          loading={this.props.importStatus==="importing"}
        />}
      >
        <Popup.Header>
          {header}
        </Popup.Header>
        <Popup.Content>
          {content}
        </Popup.Content>
      </Popup>;
  }
  
  contentFromErrorList = (errors) => {
    var errorListElements = errors.map((x) => 
      <List.Item key={x.field + x.key}>
        <List.Icon name='warning' color="red" />
        <List.Content>{intlU(x.field)} {intlU("error" + x.key)}</List.Content>
      </List.Item>
    );
    
    return <>
      {intlU("sampleImportErrorLong")}
      <List>
        {errorListElements}
      </List>
    </>;
  }
  
  render() {
    if(this.props.importStatus === "waiting") {
      var errors = validateSample(this.props.animal);
      
      if(errors.length === 0) {
        return this.makePopup("play", "green", intlU("ready"), intlU("noError"));
      } else {
        return this.makePopup("warning sign", "yellow", intlU("sampleImportErrorShort"), this.contentFromErrorList(errors));
      }
    } else if(this.props.importStatus === "importing") {
      return this.makePopup("spinner", "grey", intlU("importing"), intlU("importing"))
    } else if(this.props.importStatus === "error") {
      return this.makePopup("warning circle", "red", intlU("sampleImportErrorShort"), this.contentFromErrorList(this.props.importErrors))
    } else if(this.props.importStatus === "done") {
      return this.makePopup("checkmark", "green", intlU("imported"), intlU("imported"))
    } else {
      // Shouldn't get here.
      return this.makePopup("warning circle", "red", intlU("error"), intlU("error"))
    }
  }
}

class StatusCell extends Component {
  relevantError() {
    if(this.props.sample.errors) {
      return this.props.sample.errors.find((x) => x.key === this.props.attribute)
    } else {
      return null;
    }
  }
  
  displayWarning() {
    return this.relevantError() && !this.props.sample[this.props.attribute];
  }
  
  notifications() {
    const error = this.relevantError();
    if(!error) {
      return null;
    } else {
      return <Popup
        trigger={<Icon name="warning" color="yellow" />}
      >
        <Popup.Header>
          {intlU("sampleCSVErrorHeader")}
        </Popup.Header>
        <Popup.Content>
          {error.value} {intlU(error.type)}
        </Popup.Content>
      </Popup>;
    }
  }
  
  content() {
    if(this.displayWarning()) {
      return <>
        {!!this.relevantError() && this.notifications()}
        {!!this.relevantError() && this.relevantError().value}
      </>;
    } else {
      return this.props.children;
    }
  }
  
  render() {
    return <Table.Cell warning={this.displayWarning()}>
      <div style={{ whiteSpace: 'nowrap' }}>
        {this.content()} 
      </div>
    </Table.Cell>
  }
}

class BatchEditRow extends Component {
  state = {
    modalIsOpen: false,
    importStatus: "waiting",
    importErrors: {},
    importAnimal: null,
  }
  
  successCB = () => {
    if(this.props.onSuccess) {
      this.props.onSuccess(this.props.rowNumber - 1, this.state.importAnimal)
    }
  }
  
  errorCB = () => {
    if(this.props.onError) {
      this.props.onError(this.props.rowNumber - 1, this.state.importErrors)
    }
  }
  
  importSample = () => {
    if(this.state.importStatus === "waiting") {
      this.setState({importStatus: "importing", importErrors: []})
      var importAnimal = {...this.props.animal};
      //importAnimal.groups = this.props.animal.groups.map((x) => x.id)
      
      axios.post("/api/samples", importAnimal)
        .then((res) => {
          if (res.status === 200) {
            this.setState({importStatus: "done", importAnimal: res.json}, this.successCB)
          } else {
            this.setState({importStatus: "error", importErrors: res.json}, this.errorCB)
          }
        }).catch((err) => {this.setState({importStatus: "error", importErrors: err.response.data}, this.errorCB)});
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.startImport !== this.props.startImport) {
      this.importSample();
    }
  }
  
  onChange = (animal) => {
    this.props.onChange(this.props.rowNumber - 1, animal)
  }
  
  editModal() {
    return <Modal
        onOpen={() => this.setState({modalIsOpen: true})}
        onClose={() => this.setState({modalIsOpen: false})}
        trigger={<Button>{intlU("edit")}</Button>}
        open={this.state.modalIsOpen}
      >
      <Modal.Header>{intlU("editSample")}</Modal.Header>
      <Modal.Content>
        <SampleForm
          onChange={this.onChange}
          defaultSample={this.props.animal}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => this.setState({modalIsOpen: false})}>{intlU("done")}</Button>
      </Modal.Actions>
    </Modal>;
  }
  
  render() {
    return <Table.Row>
        <Table.Cell>
          {this.props.rowNumber}
          <BatchImportIcon animal={this.props.animal} importStatus={this.state.importStatus} importErrors={this.state.importErrors} />
        </Table.Cell>
        <Table.Cell>
          {this.editModal()}
        </Table.Cell>
        <Table.Cell>
          {this.props.animal.animalId}
        </Table.Cell>
        <StatusCell sample={this.props.animal} attribute="sampleDate">
          <SampleDate sample={this.props.animal} />
        </StatusCell>
        <StatusCell sample={this.props.animal} attribute="species">
          <Species sample={this.props.animal} />
        </StatusCell>
        <StatusCell sample={this.props.animal} attribute="ecotype">
          <Ecotype sample={this.props.animal} />
        </StatusCell>    
        <StatusCell sample={this.props.animal} attribute="country">
          <Country sample={this.props.animal} />
        </StatusCell>          
        <StatusCell sample={this.props.animal} attribute="province">
          <Province sample={this.props.animal} />
        </StatusCell>          
        <StatusCell sample={this.props.animal} attribute="sector">
          <Sector sample={this.props.animal} />
        </StatusCell>                  
        <StatusCell sample={this.props.animal} attribute="population">
          <Population sample={this.props.animal} />
        </StatusCell>          
        <StatusCell sample={this.props.animal} attribute="latitude">
          <Latitude sample={this.props.animal} />
        </StatusCell>          
        <StatusCell sample={this.props.animal} attribute="longitude">
          <Longitude sample={this.props.animal} />
        </StatusCell>     
        <StatusCell sample={this.props.animal} attribute="samples_type">
          <SampleType sample={this.props.animal} />
        </StatusCell>  
        <StatusCell sample={this.props.animal} attribute="sex">
          <Sex sample={this.props.animal} />
        </StatusCell>  
        <StatusCell sample={this.props.animal} attribute="age">
          <Age sample={this.props.animal} />
        </StatusCell>          
        <StatusCell sample={this.props.animal} attribute="age">
          <Owner sample={this.props.animal} />
        </StatusCell>                  
      </Table.Row>;
  }  
}

class BatchEdit extends Component {
  onAnimalChange = (index, animal) => {
    const newAnimals = [...this.props.animals];
    newAnimals[index] = animal;
    
    this.props.onAnimalChange(newAnimals);
  }

  getHeader() {
    if(this.props.animals.length === 0) {
      return <Header as="h3">{intlU("selectFileToBegin")}</Header>      
    } else {
      if(this.props.importStatus.some((x) => x !== "waiting")) {
        return <>
          <Header as="h3">
            <Icon name="hourglass half" />{this.props.importStatus.filter((x) => x === "waiting").length} {intlU("showingXSamplesImportWaiting")}
          </Header>
          <Header as="h3">
            <Icon name="warning circle" color="red" />{this.props.importStatus.filter((x) => x === "error").length} {intlU("showingXSamplesImportError")}
          </Header>
          <Header as="h3">
            <Icon name="checkmark" color="green" />{this.props.importStatus.filter((x) => x === "success").length} {intlU("showingXSamplesImportSuccess")}
          </Header>        
        </>
      } else {
        return <Header as="h3">
          {intlU("showingXSamplesImportBefore")} {this.props.animals.length} {intlU("showingXSamplesImportAfter")}
        </Header>
      }        
    }      
  }


  render() {
    var animalList = [];
    for(var i=0; i< this.props.animals.length; ++i) {
      animalList.push(<BatchEditRow
                         animal={this.props.animals[i]}
                         onChange={this.onAnimalChange}
                         key={i}
                         rowNumber={i+1}
                         startImport={this.props.startImport[i]}
                         onSuccess={this.props.onSuccess}
                         onError={this.props.onError}
                      />);
    }
    

    if(animalList.length === 0) {
      animalList = 
        <Table.Row>
          <Table.Cell colSpan={5}  textAlign="center">
          {intlU("noSample")}
          </Table.Cell>
        </Table.Row>
    }
    
    return(
      <>
        {this.getHeader()}
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>{intlU("ready")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("animalId")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("sampleDate")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("species")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("ecotype")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("country")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("province")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("sector")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("population")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("latitude")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("longitude")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("sampleType")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("sex")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("age")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("owner")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {animalList}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default BatchEdit;
