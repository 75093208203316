import React, { Component } from "react";
import {
  Icon,
  Container,
  Grid,
  Image,
  Segment,
  Dropdown,
  Header,
} from "semantic-ui-react";
import { intlL, intlU } from "../intl";
import MainPageMenu from "./MainPageMenu";

class MainPageHeader extends Component {
  logout = (e) => {
    e.preventDefault();
    fetch("/api/logout", {
      method: "GET",
    }).then(() => {
      document.location.href = "/login";
    });
  };

  preferences = (e) => {
    e.preventDefault();
    document.location.href = "/preferences";
  };
  render() {
    return (
      <Segment vertical style={{"backgroundColor": "#217666", color:"white"}}>
        <Container fluid>
          <Grid verticalAlign="middle">
            <Grid.Row centered>
              <Grid.Column textAlign="left" width={2}>
                <MainPageMenu
                  userrole={this.props.userrole}
                  firstname={this.props.firstname}
                  lastname={this.props.lastname}
                />
              </Grid.Column>
              <Grid.Column textAlign="center" width={11}>
                <Header
                  as="h1"
                  style={{
                    color: "white",
                    fontSize: "xx-large",
                    fontWeight: "normal",
                  }}
                >
                  <Image src="/logo-no-acronym.png" as="a" href="/" circular />
                  <span style={{ fontWeight: "bold" }}>CAR</span>ibou{" "}
                  <span style={{ fontWeight: "bold" }}>L</span>ims{" "}
                  {intlL("and")} <span style={{ fontWeight: "bold" }}>A</span>
                  {intlL("nalysis")}
                </Header>
              </Grid.Column>
              <Grid.Column textAlign="right" width={2}>
                <Dropdown
                  trigger={
                    <span>
                      {this.props.firstname + " " + this.props.lastname}
                      <Icon name="user circle" size="huge" />
                    </span>
                  }
                  icon={null}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.preferences}>
                      {intlU("preferences")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.logout}>
                      {intlU("logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}
export default MainPageHeader;
