import React, { Component } from "react";
import {
  Form,
  Select,
  Input
} from "semantic-ui-react";
import { intlU } from "../../intl";
import LoadingStandIn from "../LoadingStandIn";
import {TopLevelWrapper, SectionWrapper, SaveButton} from "../Wrappers";
import {submitRequest } from "../SubmitUtils";
import { SubmitErrorMessage } from "../SubmitErrorMessage";

class ChangeEmail extends Component {
  state = {
    emailError: null,
    confirmEmailError: null,
    email: "",
    confirmEmail: ""
  }
  
  submitChangeEmail = (e) => {
    e.preventDefault();

    var user_obj = {id: this.props.user.id, email: this.state.email}

    submitRequest("put", "users", user_obj,
      () => {window.location.assign("/preferences/")},
      (errors) => this.props.onError(errors)
    );
  };
  
  handleChangeInput = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  blurEmail = (e) => {
    e.preventDefault();
    if (!this.validateEmailPattern()) {
      this.setState({emailError: { content: intlU("invalidEmail"), pointing: "below" }});
    } else {
      this.setState({ emailError: null });
    }
  };
  
  blurConfirmEmail = (e) => {
    e.preventDefault();
    if (!this.emailMatch()) {
      this.setState({confirmEmailError: { content: intlU("emailMismatch"), pointing: "below" }});
    } else {
      this.setState({ confirmEmailError: null });
    }
  };
  
  validateEmailPattern = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
    );
    
    return pattern.test(this.state.email);
  };  
  
  emailMatch = () => {
    return this.state.email === this.state.confirmEmail;
  };

  emailValid = () => {
    return this.emailMatch() && this.state.email !== "" && this.validateEmailPattern();
  };  
  
  render() {
    return <Form>
      <Form.Field
        label={intlU("changeEmail")}
        control={Input}
        placeholder={intlU("enterNewEmail")}
        onChange={this.handleChangeInput("email")}
        onBlur={this.blurEmail}
        error={this.state.emailError}
        defaultValue=""
      />
      <Form.Field
        label={intlU("confirmNewEmail")}
        control={Input}
        placeholder={intlU("confirmNewEmail")}
        onChange={this.handleChangeInput("confirmEmail")}
        onBlur={this.blurConfirmEmail}
        error={this.state.confirmEmailError}
        defaultValue=""
      />
      <SaveButton
        disabled={!this.emailValid()}
        onClick={this.submitChangeEmail}
      />
    </Form>;
  }
}

class ChangePassword extends Component {
  state= {
    password: "",
    confirmPassword: "",
    passwordError: null,
    confirmPasswordError: null
  }
  
  submitChangePassword = (e) => {
    e.preventDefault();
    
    var user_obj = {id: this.props.user.id, password: this.state.password}

    submitRequest("put", "users", user_obj,
      () => {window.location.assign("/preferences/")},
      (errors) => this.props.onError(errors)
    );
  };  
  
  handleChangeInput = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };  
  
  blurConfirmPassword = (e) => {
    e.preventDefault();
    if (!this.passwordMatch()) {
      this.setState({confirmPasswordError: {content: intlU("passwordMismatch"), pointing: "below",}});
    } else {
      this.setState({ confirmPasswordError: null });
    }
  };

  blurPassValid = (e) => {
    e.preventDefault();
    if (!this.passwordStrong()) {
      this.setState({passwordError: { content: intlU("strongPassword"), pointing: "below" }});
    } else {
      this.setState({ passwordError: null });
    }
  };

  passwordStrong = () => {
    console.log("Testing password strength")
    var pattern2 = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{8,})/);
    console.log(pattern2.test(this.state.password));
    return (pattern2.test(this.state.password));
  };

  passwordMatch = () => {
    return this.state.password === this.state.confirmPassword;
  };

  passwordValid = () => {
    return this.passwordMatch() && this.state.password !== "" && this.passwordStrong();
  };
  
  render() {
    return <Form>
      <Form.Field
        label={intlU("changePassword")}
        control={Input}
        placeholder={intlU("enterNewPassword")}
        onChange={this.handleChangeInput("password")}
        onBlur={this.blurPassValid}
        error={this.state.passwordError}
        type="password"
        defaultValue=""
      />
      <Form.Field
        label={intlU("confirmNewPassword")}
        control={Input}
        placeholder={intlU("confirmNewPassword")}
        onChange={this.handleChangeInput("confirmPassword")}
        onBlur={this.blurConfirmPassword}
        error={this.state.confirmPasswordError}
        type="password"
        defaultValue=""
      />
      <SaveButton
        disabled={!this.passwordValid()}
        onClick={this.submitChangePassword}
      />
    </Form>;
  }
}

class ChangeLocale extends Component {
  state={
    locale: null
  }    
  handleChangeSelect = (input) => (event, value) => {
    this.setState({ [input]: value.value });
  };

  submitChangeLocale = (e) => {
    e.preventDefault();
    var user_obj = {id: this.props.user.id, locale: this.state.locale}

    submitRequest("put", "users", user_obj,
      () => {window.location.assign("/preferences/")},
      (errors) => this.props.onError(errors)
    );
  };  
  
  render() {
    return <Form>            
      <Form.Field>
        <label>{intlU("changeLocale")}</label>
        <Select
          options={[
            { text: intlU("french"), value: "fr-CA" },
            { text: intlU("english"), value: "en-CA" },
          ]}
          onChange={this.handleChangeSelect("locale")}
          defaultValue={this.props.user.locale}
        />
      </Form.Field>
      <SaveButton
        onClick={this.submitChangeLocale}
        disabled={!this.state.locale}
      />
    </Form>;
  }
}

class Preferences extends Component {
  state = {
    loading: true,
    user: {},
    submitErrors: []
  };
  
  async getUser() {
    fetch("/api/users/" + this.props.userid)
      .then((res) => res.json())
      .then((res_user) => this.setState({ loading: false, user: res_user }));
  }

  componentDidMount() {
    this.getUser();
  }

  onSubmitError = (errors) => {
    this.setState({submitErrors:errors});
  }

  render() {
    return (
      <TopLevelWrapper icon="address card" header="userPreferences">
        <LoadingStandIn active={this.state.loading} >
          <SubmitErrorMessage
            errors={this.state.submitErrors}
            contextPrefix="preferencesChange"
            onDismiss={() => this.setState({submitErrors: []})}
          />        
          <SectionWrapper>  
            <ChangeEmail user={this.state.user} onError={this.onSubmitError} />
          </SectionWrapper>
          <SectionWrapper>  
            <ChangePassword user={this.state.user} onError={this.onSubmitError} />
          </SectionWrapper>  
          <SectionWrapper>  
            <ChangeLocale user={this.state.user} onError={this.onSubmitError} />
          </SectionWrapper>
        </LoadingStandIn>
      </TopLevelWrapper>
    );
  }
}

export default Preferences;
