import React, { Component } from "react";
import {
  Progress
} from "semantic-ui-react";
import { intlU } from "../../../intl";


function parseVCFHeaderFromLines(lineArray) {
  var results = {
    header: "",
    headerFields: [],
    samples: [],
    lineCount: null
  }
  
  // We'll loop over the file lines one by one.  
  var headerCounter = 0;
  do {
    // Read the current line.
    var currentLine = lineArray[headerCounter]
    // Check if we're in the header portion of the file.
    if(currentLine.substring(0,2) === "##") {
      // If so, add the line to the complete header result.
      results.header = results.header.concat(currentLine + "\n")
    
    // Now check if we're in the column names + sample name line.
    } else if(currentLine.substring(0,1) === "#") {
      // If so, parse the field names and the sample names.
      results.headerFields = currentLine.split("\t").slice(0,9);
      results.samples = currentLine.split("\t").slice(9);
      results.lineCount = headerCounter;
    // If this is neither, we've runinto an invalid VCF file.
    } else {
      return null;
    }
    
    // Up the counter so we move on to the next line.
    ++headerCounter;
  // Keep on looping until we've read all lines or we found a column header.
  } while(headerCounter < lineArray.length && results.headerFields.length === 0);
  
  return results;
}

class VCFProgress extends Component {
  state = {
    processedLines: 0,
    totalLines: 50000,    
    readState: "none"
  }
  
  componentDidUpdate(prevProps) {
    if(this.props.vcfFile && (prevProps.vcfFile !== this.props.vcfFile)) {
      this.parseVCF();
    }
  }
  
  parseVCF() {
    console.log("Parsing")
    // Keep only the top of the file so we can read the header.
    // 131072 bytes (2^17) is VERY generous for a VCF file header.
    this.setState({readState: "header"})
    var fileSubset = this.props.vcfFile.slice(0, 131072)
    
    const fr = new FileReader();
    fr.onload = () => {
      var fullFile = fr.result;
      var lineArray = fullFile.split("\n");
      
      var results = parseVCFHeaderFromLines(lineArray);
      if(results) {
        this.setState({readState: "reading"})
        this.props.onHeaderRead(results.samples, results);

        const worker = new Worker(new URL('./VCFWorker.js', import.meta.url));
        worker.postMessage({
          headerInfo: results,
          vcfFile: this.props.vcfFile
        });
        worker.onmessage = (message) => {
          if(message.data.type === "progress") {
            this.setState({processedLines: message.data.processedLines, totalLines: message.data.totalLines})
          } else if(message.data.type === "results") {
            this.setState({readState: "success"})
            this.props.onSuccess(message.data.results)
          } else {
            this.setState({readState: "errorGenotype"})
            this.props.onError("genotype")
          }
        };  
      } else {
        this.setState({readState: "errorHeader"})
        this.props.onError("header")
      }
    }
    fr.readAsText(fileSubset)
  }    

  progressLabel() {
    if(this.state.readState==="success") {
      return intlU("vcfParseSuccess")
    } else if(this.state.readState==="errorGenotype") {
      return intlU("errorVCFGenotype")
    } else if(this.state.readState==="reading") {
      return intlU("readingVCFGenotypes")
    }
  }
  
  render() {
    if(this.state.readState !== "none" && this.state.readState !== "errorHeader") {
      return <Progress 
          indicating 
          value={this.state.processedLines} 
          total={this.state.totalLines} 
          progress="ratio" 
          success={this.state.readState==="success"} 
          error={this.state.readState==="error"} 
          label={this.progressLabel()}
      />    
    } else {
      return null;
    }
  }
}

export default VCFProgress;