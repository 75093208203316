import React, { Component } from "react";
import {
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import CollectionTables from "../collection/CollectionsTable";
import axios from "axios";
import LoadingStandIn from "../LoadingStandIn";
import { CollectionAttributesForm } from "./CollectionForm";
import { intlU } from "../../intl";

class CollectionsList extends Component {
  state = {
    loading1: true,
    collections: [],
    filter: {},
    selectedCollections: []
  };

  async getCollections() {
    fetch("/api/collections/")
      .then((res) => res.json())
      .then((collections) =>
        this.setState({ loading1: false, collections: collections })
      );
  }

  componentDidMount() {
    this.getCollections();
  }

  filter = () => {
    axios
      .post("/api/collections/filter/", this.state.filter)
      .then((res) => res.data)
      .then((collections) => this.setState({ collections: collections }));
  };

  onFilterChange = (filter) => {
    this.setState({filter: filter}, () => this.filter());
  }
  
  collectionSelect = (collectionId) => {
    var newSelectedCollections = [];
    if(this.props.selectUnique) {
      if(this.state.selectedCollections.includes(collectionId)) {
        newSelectedCollections = [];
      } else {
        newSelectedCollections = [collectionId];
      }
    } else {
      var collectionSet = new Set(this.state.selectedCollections);
      if(collectionSet.has(collectionId)) {
        collectionSet.delete(collectionId);
      } else {
        collectionSet.add(collectionId);
      }      
      
      newSelectedCollections = Array.from(collectionSet)
    }

    console.log("Inside collectionSelect");
    this.setState({selectedCollections: newSelectedCollections}, 
                  () => {if(this.props.onSelectChange) this.props.onSelectChange(this.state.selectedCollections)});
  }

  render() {
    //var columns=["name", "species", "userGroupId"];
    var columns=["name"];
    if(this.props.onSelectChange) {
      columns.push("select");
    }
    return (
      <LoadingStandIn active={this.state.loading1}>
        <Segment raised secondary vertical  style={{"padding-left":"1em", "padding-right":"1em"}}>
          <Header as="h2">
            <Icon name="lab" />
            <Header.Content>{this.props.header}</Header.Content>
          </Header>
          <Segment raised tertiary>
            <Header as="h3">
              <Icon name="filter" />
              <Header.Content>{intlU("collectionFilter")}</Header.Content>
            </Header>          
            <CollectionAttributesForm
              onChange={this.onFilterChange}
              isFilter
            />
          </Segment>
          <Segment raised tertiary>
            <Header as="h3">{intlU("showingXCollectionsBefore")} {this.state.collections.length} {intlU("showingXCollectionsAfter")}.</Header>  
            <CollectionTables
              columns={columns}
              collections={this.state.collections}
              onSelectClick={this.collectionSelect}
              selectedCollections={this.state.selectedCollections}
            />
          </Segment>
        </Segment>
      </LoadingStandIn>
    );
  }
}

export default CollectionsList;
