import React, { Component } from "react";
import {
  Icon,
  Table,
  Flag,
} from "semantic-ui-react";
import { intlU, intlIcon, intlVocab } from "../../intl";
import { makeHeader, makeRow } from "./TableUtils";
import { SampleGroups } from "./SampleUtils";

class AnimalTable extends Component {
  printSpecies = () => {
    var ecoTypeStr = "";
    if (this.props.sample.ecotype) {
      ecoTypeStr = (
        <span>
          , <Icon name={intlIcon(this.props.sample.ecotype.name)} />
          {intlVocab(this.props.sample.ecotype)}
        </span>
      );       
    }

    return (
      <span>
        {intlVocab(this.props.sample.species)}
        {ecoTypeStr}
      </span>
    );
  };

  printSex = () => {
    return (
      <span>
        {intlU(this.props.sample.sex)}, <Icon name={intlIcon(this.props.sample.sex)} />
      </span>
    );
  };
  
  printLocation = () => {
    var country = "";
    if(this.props.sample.country) {
      country = <>
        <Flag name={intlIcon(this.props.sample.country.name)} />
        {intlVocab(this.props.sample.country)}
      </>;
    }
    var provinceString = this.props.sample.province ? ", " + intlVocab(this.props.sample.province) : "";
    var sectorString = this.props.sample.sector ? ", " + intlVocab(this.props.sample.sector) : "";
    var lat = "";
    if (this.props.sample.latitude > 0) {
      lat = "N";
    } else if (this.props.sample.latitude < 0) {
      lat = "S";
    }
    var long = "";
    if (this.props.sample.longitude > 0) {
      long = "E";
    } else if (this.props.sample.longitude < 0) {
      long = "W";
    }
    var coordinateString = "";
    if(this.props.sample.latitude) {
      coordinateString = " (" +
          intlU(lat) +
          " " +
          this.props.sample.latitude +
          ", " +
          intlU(long) +
          " " +
          this.props.sample.longitude +
          ")";
    }

    if (this.props.sample.country || this.props.sample.latitude) {
      return (
        <>
          {country}
          {provinceString}
          {sectorString}
          {coordinateString}
        </>
      );
    }
  };
  
  user = () => {
    return <SampleGroups sample={this.props.sample} />;
  };

  population = () => {
    var populationString = this.props.sample.population ? intlVocab(this.props.sample.population) : "";
    return (
      <span>
        {populationString}
      </span>
    );
  };  
  
  render() {
    return (
        <Table>
          {makeHeader("sample")}
          <Table.Body>
            {makeRow("groups", this.user())}
            {makeRow("sampleDate", this.props.sample.sampleDate)}
            {makeRow("sampleType", intlVocab(this.props.sample.samples_type))}
            {makeRow("species", this.printSpecies())}
            {makeRow("sex", this.printSex())}
            {makeRow("age", intlVocab(this.props.sample.age))}
            {/*this.makeRow("sample", this.printSample(values))*/}
            {makeRow("population", this.population())}
            {makeRow("location", this.printLocation())}
            {makeRow("owner", intlVocab(this.props.sample.owner))}
            {makeRow("followupTele", intlU(this.props.sample.followupTele))}
            {makeRow("followupCond", intlU(this.props.sample.followupCond))}
            {makeRow("comments", this.props.sample.comments)}
          </Table.Body>
        </Table>
    );
  }
}

export default AnimalTable;