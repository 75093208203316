import React, { Component } from "react";
import { CollectionAttributesForm } from "./CollectionForm";
import LoadingStandIn from "../LoadingStandIn";
import { SubmitErrorMessage } from "../SubmitErrorMessage";
import {submitRequestAndRedirect } from "../SubmitUtils";
import { TopLevelWrapper, SaveButton } from "../Wrappers";

class CollectionsEdit extends Component {
  state = {
    loading: true,
    collection: {},
    submitErrors: [],
  };

  async getCollection() {
    fetch("/api/collections/" + this.props.collection_id + "?samples=1")
      .then((res) => res.json())
      .then((collection) => {
        collection.samples = collection.samples.map((x) => x.id)
        this.setState({ loading: false, collection: collection })
      });
  }

  componentDidMount() {
    this.getCollection();
  }

  onCollectionChange = (collection) => {
    var newCollection = {...this.state.collection}
    newCollection.name = collection.name
    newCollection.comments = collection.comments
    
    this.setState({collection: newCollection})
  }

  submitAndSave = (e) => {
    e.preventDefault();

    submitRequestAndRedirect("put", "collections", this.state.collection,
      (errors) => this.setState({submitErrors: errors})
    );
  };
  
  validateCollection() {
    return this.state.collection && this.state.collection.name;
  }
  
  render() {
    return (
      <TopLevelWrapper icon="clipboard list" header="collectionEdit">
        <LoadingStandIn active={this.state.loading}>
          <SubmitErrorMessage
            errors={this.state.submitErrors}
            contextPrefix="collectionModify"
            onDismiss={() => this.setState({submitErrors: []})}
          />
          <CollectionAttributesForm
            defaultCollection={this.state.collection}
            onChange={this.onCollectionChange}
            modify
          />
          <SaveButton
            onClick={this.submitAndSave}
            disabled={!this.validateCollection()}
          />
        </LoadingStandIn>
      </TopLevelWrapper>
    );
  }
}

export default CollectionsEdit;
