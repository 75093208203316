const intlRaw = {
  en: {
    // Common words
    unknown: "Unknown",
    other: "Other",
    yes: "Yes",
    no: "No",
    ready: "Ready",

    animal: "Animal",
    animalId: "Animal ID",
    gappId: "GAPP ID",
    select2: "Select",
    select: "Select",
    filter: "Filtered",
    sampleFilter: "Samples filter options",
    collectionFilter: "Collections filter options",

    // Species info
    species: "Species",
    speciesId: "Species",

    // Ecotype info
    ecotype: "Ecotype",
    speciesEcotype: "Species",

    // Sample type info
    sample: "Sample",
    sampleType: "Sample Type",
    sampletypes: "Sample Type",
    conservationType: "Conservation Type",

    // Sex info
    sex: "Sex",
    male: "Male",
    female: "Female",

    // Country info
    country: "Country",

    // Province info
    province: "Province/State",

    // Region info
    region: "Region",
    sector: "Sector",
    population: "Population",
    sub_population: "Sub Population",

    // Age info
    age: "Age",
    ages: "Age",

    location: "Location",
    latitude: "Latitude",
    longitude: "Longitude",
    W: "West",
    E: "Est",
    S: "South",
    N: "North",

    owner: "Owner",
    comments: "Comments",
    enterComments: "Enter any additional comments",
    nocomments: "No comments",

    leftEarTag: "Left Ear Tag",
    rightEarTag: "Right Ear Tag",
    leftEarNumber: "Left ear tag number",
    leftEarColor: "Left ear tag color",
    rightEarNumber: "Right ear tag number",
    rightEarColor: "Right ear tag color",    
    
    collarPTT: "PTT Collar",
    collarVHF: "VHF Collar",
    eartag: "Ear Tag",
    left: "Left",
    right: "Right",
    side: "Side",
    noTags: "No ear tags available",
    color: "Color",
    white: "White",
    yellow: "Yellow",
    number: "Number",
    numberRequired: "Number Required",
    followed: "Followed",
    available: "Available",

    antlers: "Antlers",
    lengthTotal: "Total length",
    lengthTotalRange: "(30-400cm)",
    lengthFoot: "Foot length",
    lengthFootRange: "(1-80cm)",
    lengthMetatarsus: "Metatarsus length",
    lengthMetatarsusRange: "(1-65cm)",
    weight: "Weight",
    weigth: "Weight",
    weigthRange: "(1-400kg)",

    telemetry: "Telemetry",
    followupTele: "Telemetry follow-up",
    physicalCondition: "Physical Condition",
    followupCond: "Condition follow-up",

    edit: "Edit",
    delete: "Delete",
    save: "Save",
    cancel: "Cancel",
    addA: "Add a",
    enterThe: "Enter the",

    home: "Home",
    samples: "Samples",
    samplesList: "List Samples",
    samplesListAll: "List of all samples",
    samplesNew: "New Sample",
    collection: "Collection",
    collections: "Collections",
    collectionsList: "List Collections",
    collectionsNew: "New Collection",
    batchImportNew: "Batch import of samples",
    batchImportGenotype: "Batch import of genotypes",
    collectionEdit: "Edit Collection",
    collectionSuppExpire:
      "Time limits for deletion has expired, only administrators can delete it",
    analyses: "Analyses",
    analysesList: "List Analyses",
    analysesNew: "New analysis",
    pauseAnalyses: "This is where we pause the analysis",
    contactUs: "Contact Us",
    enterNameCollection: "Enter a name for the collection",

    samplesRecent: "My recent samples",
    analysesRecent: "My recent analyses",
    welcomeToCARLA: "Welcome to CARLA",

    and: "and",
    nalysis: "nalysis",
    preferences: "Preferences",
    logout: "Logout",
    partners: "Partners",

    connectPrompt: "Connect to your account",
    password: "Password",
    connectVerb: "Connect",
    send: "Send",
    noAccount: "Don't have an account?",
    signUp: "Sign-Up",
    errorSignUp: "Error signing-up, please try again",
    confirmEmail: "Confirm Email",
    confirmPassword: "Confirm Password",
    ForgotPassword: "Forgot your password",
    resetPassword: "Reset Password",

    createAccount: "Create an account",
    firstname: "First Name",
    lastname: "Last Name",
    language: "Language",
    french: "French",
    fr: "French",
    english: "English",
    en: "English",
    manageUsers: "Manage Users",
    manageGroups: "Manage Groups",
    name: "Name",
    usergroups: "User groups",
    userGroupId: "Owner Group",
    authorizedGroup: "Authorized Groups",
    addGroups: "Add Group",
    deleteGroups: "Delete Group",
    userrole: "Role",
    root: "Root",
    sadmin: "Admin",
    user: "User",
    awaitApproval: "Awaiting Approval",
    usersList: "Manage Users",
    newSample: "New sample",
    enterSample: "Please enter sample information",
    enterBatchImport: "Enter batch import information",
    loading: "Loading",
    editSample: "Edit Sample",
    back: "Back",

    // Preference strings
    userPreferences: "User Preferences",
    changePassword: "Change Password",
    enterNewPassword: "Enter new password",
    confirmNewPassword: "Confirm new password",
    changeEmail: "Change Email",
    enterNewEmail: "Enter new email",
    confirmNewEmail: "Confirm new email",
    changeLocale: "Change user interface language",
    passwordMismatch: "Passwords do not match",
    passwordError: "Incorrect Password",
    awaitAuthorization: "Your account has been created, but is awaiting review by an administrator.",
    emailMismatch: "Emails do not match",
    invalidEmail: "Invalid Email",
    strongPassword:
      "Passwords must contain the following characters: one lower case, one upper case, one number, one special character and at least eight characters",

    emailPrompt: "Email Address",
    passwordPrompt: "Password",

    genotypeFile: "Genotype File",
    noGenotypeFile: "No genotype file available",
    download: "Download",
    upload: "Upload",
    uploadGenotypeFile: "Upload genotype file",

    noSample: "No sample available",
    noCollection: "No collection available",
    sampleDate: "Sampling Date",
    sampleDateFromTo: "Sampling Date (From - To)",
    dateUnknown: "Date Unknown",
    continue: "Do you want to continue?",
    chooseGenotypeFile: "Choose a genotype file to upload",
    noanalysisavailable: "No analysis available",

    type: "Type",
    createdAt: "Created At",
    status: "Status",
    assignment: "Assignment",
    genomics: "Genomics",
    populationStructure: "Population Structure",
    populationMetrics: "Population Metrics",
    individualMetrics: "Individual Metrics",
    diversity: "Genetic diversity",
    relatedness: "Relatedness network",
    completed: "Completed",
    processing: "Processing",
    waiting: "Waiting",
    error: "Error",
    errorUpload: "Error during upload:",
    collectionSampleList: "List of samples in collection",
    chooseCollectionSampleList: "Choose samples to be included in the collection",
    parameters: "Parameters",
    results: "Results",
    consoleOutput: "Analysis Output",
	  consoleError: "Errors and messages",
	  pcPlot1: "Principal Components 1 and 2",
	  pcPlot2: "Principal Components 2 and 3",	
    diversityPlot1: "Percentage of Heterozygosity by population",
    diversityPlot2: "Percentage of Heterozygosity by ecotype",
    relatednessPlot1: "Network of individuals with IBD >= 0.25",
    relatednessPlot2: "Network of individuals with IBD >= 0.5",    
    errorLogin: "Error logging in please try again",
    errorRecupPassWord: "The reset token has expired. Please try again.",
    login: "Login",
    errorUser: "User not found",
    fileNoFound: "File not found",
    email: "Email",
    readyForImport: "Ready to import",
    noError: "There are no errors.",
    sampleImportErrorShort: "Sample has errors",
    sampleImportErrorLong: "Sample has errors that prevents it from being imported.",
    isEmpty: "is empty",    
    done: "Done",
    selectGroupToProceed: "Please select a group before proceeding.",
    addFileToImport: "Click here or drop a file to import",
    selectFileToBegin: "Select a file to begin",    
    
    errormin: "is below the minimum value.",
    errormax: "is above the maximum value.",
    erroris_null: "must not be empty.",
    errornot_unique: "must be unique, but the provided value already exists in the database.",
    
    sampleNotfound: "Sample does not exist. It will be created.",
    fetchError: "There was an error fetching data.",
    existingGenotype: "A genotype is already associated with this sample. It will be overwritten.",
    noGenotype: "No associated genotype.",    
    errorVCFHeader: "There was an error processing the VCF file header. Are you sure this is a valid, uncompressed VCF file?",
    vcfParseSuccess: "VCF file successfully parsed",
    errorVCFGenotype: "There was an error reading the VCF file",
    readingVCFGenotypes: "Reading genotypes from VCF file", 
    vcfFile: "VCF File",    
    importAllSamples: "Import all samples",
    importAll: "Import all genotypes",
    genotypeAttached: "Genotype successfully attached.",
    hasGenotype: "Genotype available",
    any: "Any value",
    none: "No value",  
    selectSample: "Select sample for analysis",
    selectCollection: "Select sample collection for analysis",
    showingXSamplesBefore: "Showing",
    showingXSamplesAfter: "samples matching filtering criteria",
    showingXSamplesImportBefore: "Showing",
    showingXSamplesImportAfter: "samples imported from CSV file",    
    enterCollectionInfo: "Please enter information about the collection",

    showingXSamplesImportWaiting: "samples pending",
    showingXSamplesImportError: "samples could not be imported",
    showingXSamplesImportSuccess: "samples successfully imported",
    
    xGenotypesFetching: "samples being fetched from database",
    xGenotypesWaiting: "genotypes ready for import",
    xGenotypesImportError: "genotypes could not be imported",
    xGenotypesImportSuccess: "genotypes were successfully imported",
    
    projectHead: "Project Head",
    webDevelopment: "Web developper",
    
    emailSent: "An email with recovery information has been sent to the email address registered to this account.",    
    success: "Success",
    goToLogin: "Go to login page",
    forgotPasswordNoEmail: "No account is associated with this email address.",    
    forgotPasswordErrorUnknown: "An unknown error occured. Contact a system administrator.",
    
    signupErrorUserExists: "A user with this email already exists.",
    signupErrorUnknown: "An unknown error occured. Contact a system administrator.",    
    
    sampleNewError: "An error occured while submitting the sample.",    
    sampleNewErrorUnknown: "An unknown error occured. Contact a system administrator.",    
    sampleNewErrorUnauthorized: "You do not have permission to perform this action.",

    sampleDeleteError: "An error occured while deleting the sample.",
    sampleDeleteErrorInUse: "The sample cannot be deleted because it is associated with elements within the database.",
    sampleDeleteErrorUnauthorized: "You do not have permission to perform this action.",
    sampleDeleteErrorUnknown: "An unknown error occured. Contact a system administrator.",
    
    userModifyErrorInUse: "The user cannot be deleted because it is associated with elements within the database.",
    userModifyErrorUnknown: "An unknown error occured. Contact a system administrator.",
    userModifyError: "An error occured while modifying the user.",  
    
    userDeleteErrorInUse: "The user cannot be deleted because it is associated with elements within the database.",
    userDeleteErrorUnknown: "An unknown error occured. Contact a system administrator.",
    
    userGroupCreateError: "An error occured when creating the group.",
    userGroupCreateErrorUnknown: "An unknown error occured. Contact a system administrator.",
    userGroupDeleteError: "An error occured when deleting the group.",
    userGroupDeleteInUse: "The user group cannot be deleted because it is associated with elements within the database.",
    userGroupDeleteUnknown: "An unknown error occured. Contact a system administrator.",    
    
    modifyCollectionErrorUnknown: "An unknown error occured. Contact a system administrator.",   
    modifyCollectionUnauthorized: "You do not have permission to perform this action.",
    modifyCollectionError:"An error occured while modifying the collection.",
    
    collectionNewErrorUnknown: "An unknown error occured. Contact a system administrator.",   
    collectionNewUnauthorized: "You do not have permission to perform this action.",
    collectionNewError: "An error occured while creating the collection.",    

    collectionDeleteError: "An error occured while deleting the collection.",   
    collectionDeleteErrorInUse: "The collection cannot be deleted because it is associated with elements within the database.",
    collectionDeleteErrorUnauthorized: "You do not have permission to perform this action.",
    collectionDeleteErrorUnknown: "An unknown error occured. Contact a system administrator.",
    
    analysisNewErrorUnknown: "An unknown error occured. Contact a system administrator.",   
    analysisNewUnauthorized: "You do not have permission to perform this action.",
    analysisNewError: "An error occured while creating the analysis.",    

    confirmDeletionHeader: "Confirm deletion",
    confirmDeletionContent: "Are you certain you wish to delete this object? This operation cannot be undone.",
    
    vocabularyNewErrorUnknown: "An unknown error occured. Contact a system administrator.",   
    vocabularyNewUnauthorized: "You do not have permission to perform this action.",
    vocabularyNewError: "An error occured while creating the new controlled vocabulary element.",

    vocabularyDeleteErrorInUse: "The vocabulary term cannot be deleted because it is associated with elements within the database.",
    vocabularyDeleteErrorUnauthorized: "You do not have permission to perform this action.",
    vocabularyDeleteErrorUnknown: "An unknown error occured. Contact a system administrator.",
    
    group: "Group",
    groups: "Groups",
    accessLevel: "Access level",
    accessNone: "None",
    read: "Read (limited)",
    read_full: "Read (complete)",
    write: "Write",
    
    enterTerm: "Enter both a french and an english version of the term you wish to add.",
    enterFrench: "French version",
    enterEnglish: "English version",   

    manageVocabularies: "Manage controlled vocabularies",
    vocabularies: "Vocabularies",
    ecotypes: "Ecotypes",
    countries: "Countries",
    provinces: "Provinces/States",
    populations: "Populations",
    sub_populations: "Subpopulations",
    regions: "Regions",
    sectors: "Sectors",
    colors: "Colors",
    conservations: "Conservation mediums",
    
    batchImportMesageHeader: "Import multiple samples",
    batchImportMesageContentBefore: "To import multiple samples, download the",
    batchImportMesageContentFile: "model file",
    batchImportMesageContentAfter: "and fill it out, then upload it here.",
    
    noDeleteNoWriteAccess: "You do not have permissions to modify or delete this element.",
    noDeleteInUse: "This element is linked to other elements in the database and cannot be delete.",

    uploadGenotypeFile: "Upload a genotype file",    
    uploadWrongFormat: "The uploaded file does not seem to be in the right format. Please make sure it is an uncompressed VCF file.",    
    uploadNoWriteAccess: "You do not have permissions to upload genotype data for this element.",
    uploadUnknownError: "An unknown error occured. Contact a system administrator.",
    
    genotype: "Genotype",
    addSamplesToCollection: "Add selected samples to collection",
    collectionNoSamplesSelected: "No samples selected.",
    collectionUseButtonToAdd: "Use the sample list and the \"Add\" button to add samples to the collection.",    
    collectionAddedSamples: "Samples added to the collection",    
    
    filterCriteria: "Filtering Criteria",
    filterAction: "Apply"
  },

  fr: {
    // Common words and values
    unknown: "Inconnu",
    other: "Autre",
    yes: "Oui",
    no: "Non",
    ready: "Prêt",

    animal: "Animal",
    animalId: "ID animal",
    gappId: "ID GAPP",
    select2: "Select",
    select: "Select",
    filter: "Filtre",
    sampleFilter: "Options de filtration des échantillons",
    collectionFilter: "Options de filtration des collections",

    // Species info
    species: "Espèce",
    speciesId: "Espèce",

    // Ecotype info
    ecotype: "Écotype",
    speciesEcotype: "Espèce",

    // Sample type info
    sample: "Échantillon",
    sampleType: "Type d'échantillon",
    sampletypes: "Type d'échantillon",
    conservationType: "Type de conservation",

    // Sex info
    sex: "Sexe",
    male: "Mâle",
    female: "Femelle",

    // Country info
    country: "Pays",

    province: "Province/État",
    region: "Région",
    population: "Population",
    sector: "Secteur",
    sub_population: "Sous Population",


    // Age info
    age: "Âge",
    ages: "Âge",
    fawn: "Faon (< 1 an)",
    young: "Jeune (1.5 à 2.5 ans)",
    adult: "Adulte (3.5 à 9.5 ans)",
    old: "Vieux (10.5 et +)",

    location: "Emplacement",
    latitude: "Latitude",
    longitude: "Longitude",
    W: "Ouest",
    E: "Est",
    S: "Sud",
    N: "Nord",

    owner: "Propriétaire",
    comments: "Commentaires",
    enterComments: "Entrez toute information additionelle.",
    nocomments: "Aucun commentaires",
    
    leftEarTag: "Étiquette d'oreille gauche",
    rightEarTag: "Étiquette d'oreille droite",
    leftEarNumber: "Numéro d'étiquette d'oreille (gauche)",
    leftEarColor: "Couleur d'étiquette d'oereille (gauche)",
    rightEarNumber: "Numéro d'étiquette d'oreille (droite)",
    rightEarColor: "Couleur d'étiquette d'oereille (droite)",
    
    collarPTT: "Collier PTT",
    collarVHF: "Collier VHF",
    eartag: "Étiquette d'oreille",
    side: "Côté",
    left: "Gauche",
    right: "Droite",
    noTags: "Aucune étiquette d'oreille disponible",
    color: "Couleur",
    white: "Blanc",
    yellow: "Jaune",
    number: "Numéro",
    numberRequired: "Numéro requit",
    followed: "Suivi",
    available: "Disponible",

    antlers: "Bois",
    lengthTotal: "Longueur totale",
    lengthTotalRange: "(30-400cm)",
    lengthFoot: "Longueur pied",
    lengthFootRange: "(1-80cm)",
    lengthMetatarsus: "Longueur métatarse",
    lengthMetatarsusRange: "(1-65cm)",
    weight: "Poids",
    weigth: "Poids",
    weigthRange: "(1-400kg)",

    telemetry: "Télémetrie",
    followupTele: "Suivi télémétrique",
    physicalCondition: "Condition physique",
    followupCond: "Suivi condition",

    edit: "Modifier",
    delete: "Effacer",
    save: "Enregistrer",
    cancel: "Annuler",
    addA: "Ajouter un(e)",
    enterThe: "Entrez le/la",

    home: "Accueil",
    samples: "Samples",
    samplesList: "Liste des échantillons",
    samplesListAll: "Liste de tous les échantillons",
    collection: "Collection",
    collections: "Collections",
    collectionsList: "Liste des collections",
    collectionsNew: "Nouvelle collection",
    batchImportNew: "Importation de lot d'échantillons",
    batchImportGenotype: "Importation de lot de génotypes",
    collectionEdit: "Modifier la collection",
    collectionSuppExpire:
      "Délais pour la suppression est expiré, seul les adminstrateurs peuvent le supprimer",
    samplesNew: "Nouvel échantillon",
    analyses: "Analyses",
    analysesList: "Liste des analyses",
    analysesNew: "Nouvelle analyse",
    pauseAnalyses: "C'est là que nous interrompons l'analyse",
    contactUs: "Contactez-nous",
    enterNameCollection: "Nom",

    samplesRecent: "Mes échantillons récents",
    analysesRecent: "Mes analyses récentes",
    welcomeToCARLA: "Bienvenue sur CARLA",

    and: "et",
    nalysis: "nalyse",
    preferences: "Préférences",
    logout: "Se déconnecter",
    partners: "Partenaires",

    connectPrompt: "Connectez-vous à votre compte",
    password: "Mot de passe",
    connectVerb: "Se connecter",
    send: "Envoyer",
    noAccount: "Pas de compte?",
    signUp: "Enregistrez-vous",
    errorSignUp: "Erreur lors de l'inscription, veuillez réessayer",
    confirmEmail: "Confirmez votre adresse courriel",
    confirmPassword: "Confirmez votre mot de passe",
    ForgotPassword: "Mot de passe oublié",
    resetPassword: "Récupération de mot de passe",

    createAccount: "Créer un compte",
    firstname: "Prénom",
    lastname: "Nom de famille",
    language: "Langue",
    french: "Français",
    fr: "Français",
    english: "Anglais",
    en: "Anglais",
    manageUsers: "Gestion des usagers",
    manageGroups: "Gestion des groupes",
    name: "Nom",
    usergroups: "Groupes d'usagers",
    userGroupId: "Groupe propriétaire",
    authorizedGroup: "Groupes autorisés",
    addGroups: "Ajouter un groupe",
    deleteGroups: "Effacer un groupe",    
    userrole: "Rôle",
    root: "Root",
    sadmin: "Administrateur",
    user: "Usager",
    awaitApproval: "En attente d'approbation",
    usersList: "Gestion des usagers",
    newSample: "Nouveau spécimen",
    enterSample: "Veuillez entrez les informations sur le spécimen",
    enterBatchImport: "Entrez les informations sur l'importation de lot de spécimens",
    loading: "Chargement",
    editSample: "Modifier l'échantillon",
    back: "Retour",

    // Preferences strings
    userPreferences: "Préférences usager",
    changePassword: "Changer le mot de passe",
    enterNewPassword: "Entrer le nouveau mot de passe",
    confirmNewPassword: "Confirmer le nouveau mot de passe",
    changeEmail: "Changer l'adresse courriel",
    enterNewEmail: "Entrer la nouvelle adresse courriel",
    confirmNewEmail: "Confirmer la nouvelle adresse courriel",
    changeLocale: "Changer la langue de l'interface",
    passwordMismatch: "Les mots de passe ne correspondent pas",
    passwordError: "Mot de passe incorrect",
    awaitAuthorization: "Votre compte a été créé, mais doit d'abord être approuvé par un administrateur.",
    emailMismatch: "Les adresses courriels ne correspondent pas",
    requiredField: "Ce champ est requis",
    invalidEmail: "Email non valide",
    strongPassword:
      "Le mots de passe doit contenir les caractères suivant: une minuscule, une majuscule, un chiffre, un caractère spécial et au moins huit caractères",

    emailPrompt: "Adresse courriel",
    passwordPrompt: "Mot de passe",

    genotypeFile: "Fichier génotype",
    noGenotypeFile: "Génotype non-disponible",
    download: "Télécharger",
    upload: "Téléverser",

    noSample: "Aucun échantillon disponible",
    noCollection: "Aucune collection disponible",
    sampleDate: "Date d'échantillonage",
    sampleDateFromTo: "Date d'échantillonage (de - à)",
    dateUnknown: "Date Inconnu",
    continue: "Voulez vous continuer?",
    chooseGenotypeFile: "Choisissez un fichier de génotype à téléverser",
    noanalysisavailable: "Aucune analyse disponible",

    type: "Type",
    createdAt: "Créé le",
    status: "Statut",
    assignment: "Assignation",
    genomics: "Génomique",
    populationStructure: "Structure de population",
    populationMetrics: "Métriques populationnelles",
    individualMetrics: "Métriques individuelles",
    diversity: "Diversité génétique",
    relatedness: "Réseau d'apparentement",
    completed: "Complétée",
    processing: "En cours",
    waiting: "En attente",
    error: "Erreur",
    collectionSampleList: "Liste des échantillons de la collection",
    chooseCollectionSampleList: "Sélectionnez les échantillons à ajouter à la collection",
    errorUpload: "Erreur lors de l'envoie:",
    pauseAnalysis: "Pauser l'analyse",
    startAnalysis: "Démarrer l'analyse",
    parameters: "Paramètres",
    results: "Résultats",
    consoleOutput: "Sorties de l'analyse",
    consoleError: "Erreurs et messages",
	  pcPlot1: "Composantes principales 1 et 2",
	  pcPlot2: "Composantes principales 2 et 3",
    diversityPlot1: "Pourcentage d'hétérozygosité par population",
    diversityPlot2: "Pourcentage d'hétérozygosité par écotype",    
    relatednessPlot1: "Réseau d'individus avec un IBD >= 0.25",
    relatednessPlot2: "Réseau d'individus avec un  IBD >= 0.5",        
    errorLogin: "Erreur de connexion, veuillez réessayer",
    errorRecupPassWord: "Le délai d'attente est dépassé. Veuillez réessayer.",
    login: "Connexion",
    errorUser: "Usagé inexistant",
    fileNoFound: "Fichié inexistant",
    email: "Email",
    readyForImport: "Prêt à être importer",    
    noError: "Aucune erreur.",
    sampleImportErrorShort: "L'échantillon a des erreurs",
    sampleImportErrorLong: "L'échantillon a des erreurs qui l'empêche d'être importé.",    
    isEmpty: "est vide",
    done: "Terminé",
    selectGroupToProceed: "Sélectionnez un groupe avant de continuer.",    
    addFileToImport: "Cliquez ici ou faites glisser un fichier à importer",
    selectFileToBegin: "Choisissez un fichier pour débuter",
    
    errormin: "est sous la valeur minimale.",
    errormax: "surpasse la valeur maximale.",
    erroris_null: "ne doit pas être vide.",   
    errornot_unique: "doit être unique, mais la valeur fournie existe déjà dans la base de donnée.",    
    
    sampleNotfound: "L'échantillon n'existe pas. Il sera créé.",
    fetchError: "Une erreur a eu lieu en obtenant les données.",
    existingGenotype: "Un génotype est déjà associé à cet échantillon. Celui-ci sera remplacé.",
    noGenotype: "Aucun génotype associé.",
    errorVCFHeader: "Une erreur est survenue en lisant l'en-tête du VCF. Êtes-vous certain qu'il s'agit d'un fichier VCF non-compressé valide?",
    vcfParseSuccess: "Le fichier VCF a été lu avec succès.",
    errorVCFGenotype: "Une erreur est survenue en lisant le fichier VCF.",
    readingVCFGenotypes: "Lecture des génotypes du fichier VCF.",    
    vcfFile: "Fichier VCF",
    importAllSamples: "Importez tous les échantillons",
    importAll: "Importez tous les génotypes",
    genotypeAttached: "Génotype importé avec succès.",
    hasGenotype: "Génotype disponible",
    any: "N'importe quelle valeur",
    none: "Aucune valeur",
    selectSample: "Sélectionnez un échantillon pour l'analyse",
    selectCollection: "Sélectionnez une collection pour l'analyse",
    showingXSamplesBefore: "Affichage de",
    showingXSamplesAfter: "échantillons répondant aux critères de filtration",    
    showingXSamplesImportBefore: "Affichage de",
    showingXSamplesImportAfter: "échantillons importés du fichier CSV",    
    showingXCollectionsBefore: "Affichage de",
    showingXCollectionsAfter: "collections répondant aux critères de filtration", 
    enterCollectionInfo: "Veuillez entrer les informations sur la collection", 

    showingXSamplesImportWaiting: "échantillon(s) en attente",
    showingXSamplesImportError: "échantillon(s) n'ont pas pu être importé(s)",
    showingXSamplesImportSuccess: "échantillon(s) importé(s) avec succès",    
    
    xGenotypesFetching: "échantillons en attente d'une réponse de la base de donnée",
    xGenotypesWaiting: "génotypes prêts à être importés",
    xGenotypesImportError: "genotypes n'ont pu être importés",
    xGenotypesImportSuccess: "genotypes importés avec succès", 

    projectHead: "Chef de projet",
    webDevelopment: "Dévellopeur web",    

    emailSent: "Un courriel avec des informations de récupération a été envoyé à l'adresse associé à ce compte.",
    success: "Succès",
    goToLogin: "Retour à la page d'accueil",
    forgotPasswordNoEmail: "Aucun compte n'est associé avec cette adresse courriel.",
    forgotPasswordErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",    
    
    signupErrorUserExists: "Un usager existe déjà pour ce courriel.",
    signupErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",    
    
    sampleNewError: "Une erreur est survenue lors de la soumission de l'échantillon.",
    sampleNewErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",
    sampleNewErrorUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",

    sampleDeleteError: "Une erreur est survenue lors de la déletion de l'échantillon.",
    sampleDeleteErrorInUse: "L'échantillon ne peut pas être effacé car il est associé à d'autres éléments de la base de données.",
    sampleDeleteErrorUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",
    sampleDeleteErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",
    
    userModifyErrorInUse: "L'usager ne peut pas être effacé car il est associé à d'autres éléments de la base de données.",
    userModifyErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",
    userModifyError: "Une erreur est survenue en tentant de modifier l'usager.",  

    userDeleteErrorInUse: "L'usager ne peut pas être effacé car il est associé à d'autres éléments de la base de données.",
    userDeleteErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",
    
    userGroupCreateError: "Une erreur est survenue en tentant de créer le groupe.",
    userGroupCreateErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",
    userGroupDeleteError: "Une erreur est survenue en tentant d'effacer le groupe.",
    userGroupDeleteErrorInUse: "Le groupe ne peut pas être effacé car il est associé à d'autres éléments de la base de données.",
    userGroupDeleteErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",
    
    collectionModifyErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",  
    collectionModifyUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",
    collectionModifyError: "Une erreur est survenue en tentant de modifier la collection.",
    
    collectionNewErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.", 
    collectionNewUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",
    collectionNewError: "Une erreur est survenue en tentant de créer la collection.",    

    collectionDeleteError: "Une erreur est survenue en tentant d'effacer la collection.",
    collectionDeleteErrorInUse: "La collection ne peut pas être effacé car elle est associée à d'autres éléments de la base de données.",
    collectionDeleteErrorUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",
    collectionDeleteErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",

    analysisNewErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.", 
    analysisNewUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",
    analysisNewError: "Une erreur est survenue en tentant de créer l'analyse.",

    confirmDeletionHeader: "Confirmation de déletion",
    confirmDeletionContent: "Êtes-vous certain de vouloir effacer cet objet? Cette opération est irréversible.", 
    
    vocabularyNewErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.", 
    vocabularyNewUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",
    vocabularyNewError: "Une erreur est survenue en tentant de créer le nouvel élément de vocabulaire contrôlé.",  

    vocabularyDeleteErrorInUse: "Le terme de vocabulaire ne peut pas être effacé car il est associé à d'autres éléments de la base de données.",
    vocabularyDeleteErrorUnauthorized: "Vous n'avez pas les permissions nécessaires pour effectuer cette action.",
    vocabularyDeleteErrorUnknown: "Une erreur inattendue s'est produite. Contactez un administrateur.",

    group: "Groupe",
    groups: "Groupes",
    accessLevel: "Niveau d'accès",
    accessNone: "Aucune",
    read: "Lecture (limitée)",
    read_full: "Lecture (Complète)",
    write: "Écriture",
    
    enterTerm: "Entrez une version française et une version anglaise du nouveau terme.",
    enterFrench: "Version française",
    enterEnglish: "Version anglaise",
    
    manageVocabularies: "Gestion des vocabulaires contrôlés",
    vocabularies: "Vocabulaires",
    ecotypes: "Écotypes",
    countries: "Pays",
    provinces: "Provinces/États",
    populations: "Populations",
    sub_populations: "Sous-populations",
    regions: "Régions",
    sectors: "Secteurs",
    colors: "Couleurs",
    conservations: "Médiums de conservations",
    
    batchImportMesageHeader: "Importer plusieurs échantillons",
    batchImportMesageContentBefore: "Pour importer plusieurs échantillons, remplissez le",    
    batchImportMesageContentFile: "fichier modèle",    
    batchImportMesageContentAfter: ", puis téléverser le ici.",    
    
    noDeleteNoWriteAccess: "Vous n'avez pas les permissions nécessaires pour modifier ou supprimer cet élément.",
    noDeleteInUse: "Cet élément est lié à d'autres éléments de la base de donnée et ne peut être effacé.",    
    
    uploadGenotypeFile: "Téléverser un fichier génotype",
    uploadWrongFormat: "Le fichier soumis ne semble pas avoir le bon format. Assurez-vous qu'il s'agit d'un fichier VCF non compressé.",
    uploadNoWriteAccess: "Vous n'avez pas les permissions nécessaires pour associer des données génotypiques à cet élément.",
    uploadUnknownError: "Une erreur inconnue est survenue en tentant de téléverser le fichier génotype.",
    
    genotype: "Génotype",
    addSamplesToCollection: "Ajouter les échantillons sélectionnés à la collection",    
    collectionNoSamplesSelected: "Aucun échantillon sélectionné.",
    collectionUseButtonToAdd: "Utilisez la liste et le bouton \"Ajouter\" pour ajouter des échantillons.",        
    collectionAddedSamples: "Échantillons ajoutés à la collection",
    
    filterCriteria: "Critères de filtration",
    filterAction: "Appliquer"    
  },
};

const iconRaw = {
  male: "mars",
  female: "venus",
  poaching: "bullseye",
  roadkill: "car",
  predation: "envira gallery",
  migratory: "compass",
  forest: "tree",
  mountain: "mountain",
  ear: "deaf",
  blood: "tint",
  Canada: "ca",
  us: "us",
  norway: "no",
  finland: "fi",
  sweden: "se",
  russia: "ru",
  greenland: "gl",
  iceland: "is",
  unknown: "question circle",
  assignment: "crosshairs",
  genomics: "chart area",
  completed: "check",
  processing: "spinner",
  waiting: "hourglass start",
  error: "exclamation",
  telemetry: "barcode",
  animal: "paw",
  physicalCondition: "weight",
  location: "compass outline",
  tags: "tags",
  tag: "tag",
  sample: "flask",
  samples: "flask",
  collection: "list",
  yes: "checkmark",
  no: "x",
  relatedness: "project diagram icon",
  diversity: "chart bar"
};

function intlIcon(key) {
  return iconRaw[key];
}

function intlIcons(keys) {
  var res = {};
  for (var key of keys) {
    if (key in iconRaw) {
      res[key] = iconRaw[key];
    }
  }
  return res;
}

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  } else {
    return null;
  }
}

function getLocale() {
  var chosenLocale = getCookie("locale");
  if (chosenLocale === null) {
    var navLanguage = navigator.language;
    if (navLanguage) {
      if (navLanguage === "fr") {
        chosenLocale = "fr-CA";
      } else {
        chosenLocale = "en-CA";
      }
    } else {
      chosenLocale = "fr-CA";
    }
  }

  return chosenLocale;
}
function intlL(key) {
  var chosenLocale = getLocale();

  var intlMap = intlRaw.fr;
  if (chosenLocale === "en-CA") {
    intlMap = intlRaw.en;
  }

  if (key === null) {
    return "";
  } else if (key in intlMap) {
    return intlMap[key];
  } else {
    return key;
  }
}

// function capitalizeFirstLetter(string) {
//   if (string === "") {
//     return "";
//   } else {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   }
// }

function intlU(key) {
  return intlL(key);
}

function intlVocab(vocab) {
  if(!vocab) {
    return null;
  }

  var chosenLocale = getLocale();

  if (chosenLocale === "en-CA" && vocab.en) {
    return vocab.en;
  } else if(chosenLocale === "fr-CA" && vocab.fr) {
    return vocab.fr;
  } else if(vocab.name) {
    return intlU(vocab.name);
  } else if(typeof vocab === "string") {
    return intlU(vocab);
  } else {
    return "INTL ERROR";
  }
}

export { intlL, intlU, intlIcon, intlIcons, intlVocab };
