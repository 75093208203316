import React, { Component } from "react";
import {
  Form,
  Input,
  Segment,
  Header,
  Icon,
  Button,
  Label,
  List
} from "semantic-ui-react";
import { intlU, intlIcon } from "../../intl";
import SamplesList from "../samples/SamplesList"

class CollectionAttributesForm extends Component {
  state = {
    collection: {}
  }

  handleChange = (name) => (event, value) => {
    var newValue = null;
    if(value) newValue = value.value;
    else newValue= event.target.value;
    
    var newCollection = {...this.state.collection, [name]: newValue};
    this.setState({collection: newCollection}, () => this.props.onChange(this.state.collection));
  }
  
  render() {
    return (
      <Form>
        <Form.Group inline width="equals">
          <Form.Field>
            <label>{intlU("enterNameCollection")}</label>
            <Input
              style={{"min-width":"15em"}}
              fluid
              required
              onChange={this.handleChange("name")}
              defaultValue={this.props.defaultCollection ? this.props.defaultCollection.name : null}
            />
          </Form.Field>
        </Form.Group>        
        { this.props.isFilter ? null :
            <Form.TextArea
              label={intlU("comments")}
              placeholder={intlU("enterComments")}
              onChange={this.handleChange("comments")}
              defaultValue={this.props.defaultCollection ? this.props.defaultCollection.comments : null}
            />
        }
      </Form>  
    );
  }
}

class CollectionSamplesEdit extends Component {
  
  placeholder() {
    return <Segment placeholder>
      <Header icon>
        <Icon name={intlIcon("sample")} />
          {intlU("collectionNoSamplesSelected")}<br />{intlU("collectionUseButtonToAdd")}
      </Header>
    </Segment>
  }
  
  sampleList() {
    return <Segment>
      <Header>{intlU("collectionAddedSamples")}</Header>
      <List horizontal>
        {this.props.samples.map((x) => <List.Item><Label key={x.id} color="blue">
          {x.animalId}
          <Icon name="delete" onClick={() => this.props.onRemoveSample(x.id)} />
        </Label></List.Item>)}
      </List>
    </Segment>    
  }
  
  render() {
    if(!this.props.samples || !Array.isArray(this.props.samples) || this.props.samples.length === 0) {
      return this.placeholder();
    } else {
      return this.sampleList();
    }
  }
}

class CollectionForm extends Component {
  state = {
    collection: {},
    selectedSamples: [],
    addedSamples: [],
  };

  componentDidMount() {
    if(this.props.defaultCollection) {
      var newState = {
        collection: this.props.defaultCollection, 
        selectedSamples: this.props.defaultCollection.samples
      }
      this.setState(newState)
    }
  }

  getCollection = () => {
    var retVal = {...this.state.collection};
    retVal.samples = [...this.state.addedSamples.map((x) => x.id)]
    
    return retVal;
  }

  changeCallback = () => {
    if(this.props.onChange) 
      this.props.onChange(this.getCollection());
  }

  attributeChange = (collection) => {
    this.setState({collection: collection}, this.changeCallback);
  }

  selectChange = (selectedIds, selectedSamples) => {
    console.log(selectedIds)
    console.log(selectedSamples)
    this.setState({selectedSamples: selectedSamples}, this.changeCallback)
  }

  mergeSamples = () => {
    var newAddedSamples = [...this.state.addedSamples];

    this.state.selectedSamples.forEach((x) => {
      if(!this.state.addedSamples.find((y) => x.id === y.id)) {
        newAddedSamples.push(x)
      }
    })
    
    this.setState({addedSamples: newAddedSamples}, this.changeCallback)
  }

  removeSample = (id) => {
    var newAddedSamples = [...this.state.addedSamples];
    
    newAddedSamples = newAddedSamples.filter((x) => x.id !== id)
    this.setState({addedSamples: newAddedSamples})
  }

  render() {
    return (
      <>
        <Segment raised secondary>
          <Header as="h2">
            <Icon name="edit" />
            <Header.Content>{intlU("enterCollectionInfo")}</Header.Content>
          </Header>
          
          <Segment raised tertiary>
            <CollectionAttributesForm 
              onChange={this.attributeChange}
              defaultCollection={this.props.defaultCollection} />
          </Segment>
        </Segment>
        <SamplesList 
          select
          header={intlU("chooseCollectionSampleList")}
          onSelectionChange={this.selectChange}
          defaultSelectedSamples={this.props.defaultCollection ? this.props.defaultCollection.samples : []}
        >
          <Segment basic textAlign={"center"}>
            <Button 
              icon 
              labelPosition='left' 
              positive 
              onClick={this.mergeSamples}
              style={{margin:"auto"}}
            >
              <Icon name="add" />{intlU("addSamplesToCollection")}
            </Button>
          </Segment>
          <CollectionSamplesEdit samples={this.state.addedSamples} onRemoveSample={this.removeSample}/>
        </SamplesList>
      </>
    );
  }
}

export { CollectionAttributesForm, CollectionForm };
