import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";

import "./App.css";
import { intlU } from "./intl";

import withAuth from "./components/withAuth";
import MainPageHeader from "./components/MainPageHeader";
import MainPageFooter from "./components/MainPageFooter";
import WelcomePage from "./components/WelcomePage";
import SamplesList from "./components/samples/SamplesList";
import SampleNew from "./components/samples/SampleNew";
import CollectionsList from "./components/collection/CollectionsList";
import CollectionsNew from "./components/collection/CollectionsNew";
import UsersList from "./components/users/UsersList";
import GroupsList from "./components/users/GroupsList";
import SampleShow from "./components/samples/SampleShow";
import CollectionShow from "./components/collection/CollectionShow";
import CollectionEdit from "./components/collection/CollectionEdit";
import SampleEdit from "./components/samples/SampleEdit";
import AnalysisShow from "./components/analysis/AnalysisShow";
import AnalysesList from "./components/analysis/AnalysesList";
import AnalysisNew from "./components/analysis/AnalysisNew";
import LoginForm from "./components/users/LoginForm";
import SignUpForm from "./components/users/SignUpForm";
import Preferences from "./components/users/Preferences";
import forgotPassword from "./components/users/ForgotPassword";
import TermsAndConditions from "./components/TermsAndConditions";
import ContactUs from "./components/ContactUs";
import BatchImportNew from "./components/samples/BatchImport/BatchImportNew"
import BatchImportGenotypes from "./components/samples/BatchImport/BatchImportGenotypes"
import VocabularyManager from "./components/Vocabularies"

function SampleShowId({ match }) {
  return <SampleShow sample_id={match.params.id} />;
}
/*
function CollectionShowId({ match }) {
  return <CollectionShow collection_id={match.params.id} />;
}
*/
function AnalysisShowId({ match }) {
  return <AnalysisShow analysis_id={match.params.id} />;
}

function SampleEditId({ match }) {
  return <SampleEdit sample_id={match.params.id} />;
}

function CollectionEditId({ match }) {
  return <CollectionEdit collection_id={match.params.id} />;
}

class MainApp extends Component {
  sampleRoutes() {
    return (<Switch>
      <Route
        path="/samples/new"
        exact
        render={(props) => (
          <SampleNew {...props} userrole={this.props.userrole} />
        )}
      />
      <Route
        path="/samples/edit/:id"
        exact
        component={SampleEditId}
      />
      <Route path="/samples/:id" component={SampleShowId} />
      <Route
        path="/samples/"
        exact
        render={(props) => (
          <SamplesList
            {...props}
            header={intlU("samplesListAll")}
            userrole={this.props.userrole}
          />
        )}
      />
      <Route
        path="/batchImport/new"
        exact
        render={(props) => (
          <BatchImportNew {...props} userrole={this.props.userrole} />
        )}
      />      
      <Route
        path="/batchImport/genotype"
        exact
        render={(props) => (
          <BatchImportGenotypes {...props} userrole={this.props.userrole} />
        )}
      />      
    </Switch>);
  }
  
  collectionRoutes() {
    return(<Switch>
      <Route
        path="/collections/new"
        exact
        render={(props) => (
          <CollectionsNew 
            {...props} 
            userrole={this.props.userrole} 
            authorizedGroup={this.props.authorizedGroup} 
          />
        )}
      />  
      <Route
        path="/collections/edit/:id"
        exact
        component={CollectionEditId}
      />
      <Route 
        path="/collections/:id" 
        exact
        render={(routeProps) => 
          <CollectionShow 
            collection_id={routeProps.match.params.id} 
            userrole={this.props.userrole} 
            authorizedGroup={this.props.authorizedGroup} /> 
        }
      />                
      <Route
        path="/collections/"
        exact
        render={(props) => (
          <CollectionsList
            {...props}
            header={intlU("collectionsList")}
            userrole={this.props.userrole}
          />
        )}
      />
    </Switch>);
  }
  
  analysesRoutes() {
    return(<Switch>
      <Route
        path="/analyses/new"
        render={(props) => (
          <AnalysisNew
            {...props}
            userrole={this.props.userrole}
          />
        )}
      />
      <Route path="/analyses/:id" component={AnalysisShowId} />
      <Route
        path="/analyses/"
        exact
        render={(props) => (
          <AnalysesList {...props} userrole={this.props.userrole} />
        )}
      />    
    </Switch>);
  }
  
  userRoutes() {
    return(<Switch>
      <Route path="/users/" component={UsersList} />
      <Route path="/groups/" component={GroupsList} />
      <Route
        path="/preferences/"
        render={(props) => (
          <Preferences
            {...props}
            userid={this.props.userid}
            userrole={this.props.userrole}
          />
        )}
      />    
    </Switch>);
  }
  
  otherRoutes() {
    return(<Switch>
      <Route
        path="/"
        exact
        render={(props) => (
          <WelcomePage
            {...props}
            firstname={this.props.firstname}
            userrole={this.props.userrole}
            userId={this.props.userid}
          />
        )}
      />
      <Route
        path="/TermsAndConditions"
        component={TermsAndConditions}
      />
      <Route path="/ContactUs" component={ContactUs} />    
      <Route path="/vocabularies" render={(props) => <VocabularyManager {...props} userrole={this.props.userrole} />} />
    </Switch>);
  }
  
  allRoutes() {
    return(<>
      {this.sampleRoutes()}
      {this.collectionRoutes()}
      {this.analysesRoutes()}
      {this.userRoutes()}
      {this.otherRoutes()}
    </>);
  }

  unauthorized() {
    return(
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        <div>{intlU("awaitApproval")}</div>
      </div>    
    );
  }

  render() {
    return (
      <>
        <MainPageHeader
          userrole={this.props.userrole}        
          firstname={this.props.firstname}
          lastname={this.props.lastname}
        />
        <Segment>
          <Grid>
            <Grid.Column stretched width={16}>
              <Switch>
                {this.props.userrole === "unauthorized" ?
                  this.unauthorized() :
                  this.allRoutes()
                }
              </Switch>
            </Grid.Column>
          </Grid>
        </Segment>
        <MainPageFooter />
      </>
    );
  }
}

class App extends Component {
  state = {
    user: null,
  };

  setUser = (user) => {
    this.setState({
      user: user,
    });
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginForm} />
          <Route path="/signup" component={SignUpForm} />
          <Route path="/forgotPassword" component={forgotPassword} />
          <Route path="/" component={withAuth(MainApp)} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
