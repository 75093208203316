const ModelOptions = {

  sex: ["male", "female", "unknown"],

  age: ["fawn", "young", "adult", "old", "unknown"],

  yesno: ["yes", "no", "unknown"],

  yes: ["yes", "no"],

  sides: ["left", "right", "unknown"],

  antler: ["1", "2", "absent", "other"],

  //analysisTypes: ["assignment", "individualMetrics", "populationStructure", "populationMetrics", "genomics", "diversity"],  
  analysisTypes: ["assignment", "diversity", "relatedness"],  
};

module.exports = ModelOptions;
