import React from "react";
import GroupsTable from "./GroupsTable";
import { TopLevelWrapper } from "../Wrappers";

const GroupsList = () => (
  <TopLevelWrapper icon="users" header="manageGroups">
    <GroupsTable />
  </TopLevelWrapper>
);

export default GroupsList;
