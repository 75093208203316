import React from "react";
import {
  Container,
  Grid,
  Header,
  Image,
  Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { intlU } from "../intl";

const MainPageFooter = () => (
  <Segment vertical style={{"backgroundColor": "#217666", color:"white"}}>
    <Grid divided="vertically" stackable verticalAlign="middle">
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Header as="h3" inverted>{intlU("partners")}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered columns={7}>
        <Grid.Column>
          <Image
            as="a"
            href="https://mffp.gouv.qc.ca/"
            size="medium"
            src="/mffp-logo.png"
            circular
          />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Image
            as="a"
            href="https://www.genomecanada.ca"
            size="large"
            src="/gc-logo.png"
            circular
          />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Image
            as="a"
            href="https://www.computecanada.ca"
            size="large"
            src="/calcul-canada-logo.png"
            circular
          />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Image
            as="a"
            href="https://www.ulaval.ca"
            size="large"
            src="/ul-logo.png"
            circular
          />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Image
            as="a"
            href="https://www.genomequebec.com"
            size="large"
            src="/gq-logo.png"
            circular
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Container textAlign="center">
          <Link to={"/ContactUs"} style={{color:"white"}}>{intlU("contactUs")}</Link> - 
          (c) 2020 Université Laval
        </Container>
        <Container textAlign="center"></Container>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default MainPageFooter;
