import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Select,
  Message,
  Icon
} from "semantic-ui-react";
import { intlU } from "../../intl";

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      globalError: null,
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeSelect = (input) => (event, value) => {
    this.setState({ [input]: value.value });
  };

  signup = (e) => {
    e.preventDefault();
    if (!this.emailValid() || !this.passwordValid()) {
      return;
    }

    var registerObject = {
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      locale: this.state.language,
    };

    fetch("/api/register", {
      method: "POST",
      body: JSON.stringify(registerObject),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          fetch("/api/authenticate", {
            method: "POST",
            body: JSON.stringify({
              email: registerObject.email,
              password: registerObject.password,
            }),
            headers: { "Content-Type": "application/json" },
          })
            .then(() => {
              this.props.history.push("/");
            })
            .catch(() => {
              this.props.history.push("/");
            });
        } else {
          res.json().then((response) => {
            if(Array.isArray(response) && response.length > 0 && response[0].message === "email must be unique") {
              this.setState({globalError: "signupErrorUserExists"});
            } else {
              this.setState({globalError: "signupErrorUnknown"});
            }
          });
        }
      })
      .catch((err) => {
        this.setState({globalError: "signupErrorUnknown"});
      });
  };

  blurConfirmPassword = (e) => {
    e.preventDefault();
    if (!this.passwordMatch()) {
      this.setState({
        passwordError: {
          content: intlU("passwordMismatch"),
          pointing: "below",
        },
      });
    } else {
      this.setState({ passwordError: null });
    }
  };

  blurPassValid = (e) => {
    e.preventDefault();
    if (!this.passwordStrong()) {
      this.setState({
        passwordError2: { content: intlU("strongPassword"), pointing: "below" },
      });
    } else {
      this.setState({ passwordError2: null });
    }
  };

  passwordStrong = () => {
    var pattern2 = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{8,})/
    );
    if (pattern2.test(this.state.password)) {
      return true;
    } else {
      return false;
    }
  };

  passwordMatch = () => {
    if (this.state.password === this.state.confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  passwordValid = () => {
    return this.passwordMatch() && this.state.password !== "";
  };

  blurConfirmEmail = (e) => {
    e.preventDefault();
    if (!this.emailMatch()) {
      this.setState({
        emailError: { content: intlU("emailMismatch"), pointing: "below" },
      });
    } else {
      this.setState({ emailError: null });
    }
  };

  blurEmailValid = (e) => {
    e.preventDefault();
    if (!this.emailValidate()) {
      this.setState({
        emailError2: { content: intlU("invalidEmail"), pointing: "below" },
      });
    } else {
      this.setState({ emailError2: null });
    }
  };

  emailMatch = () => {
    if (this.state.email === this.state.confirmEmail) {
      return true;
    } else {
      return false;
    }
  };

  emailValidate = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
    );
    if (pattern.test(this.state.email)) {
      return true;
    } else {
      return false;
    }
  };

  emailValid = () => {
    return this.emailMatch() && this.state.email !== "";
  };

  nameValid = () => {
    return this.state.firstName !== "" && this.state.lastName !== "";
  };

  globalErrorMessage = () => {
    if(this.state.globalError) {
      return <Message icon negative><Icon name="exclamation circle"/><Message.Content>{intlU(this.state.globalError)}</Message.Content></Message>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="login-form">
        <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>

        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="black" textAlign="center">
              <Image src="/logo.png" />
              {intlU("createAccount")}
            </Header>
            {this.globalErrorMessage()}
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  label={intlU("firstname")}
                  fluid
                  icon="user"
                  iconPosition="left"
                  name="firstName"
                  onChange={this.handleInputChange}
                />
                <Form.Input
                  label={intlU("lastname")}
                  fluid
                  icon="user"
                  iconPosition="left"
                  name="lastName"
                  onChange={this.handleInputChange}
                />
                <Form.Input
                  label={intlU("email")}
                  fluid
                  icon="mail"
                  iconPosition="left"
                  name="email"
                  onChange={this.handleInputChange}
                  onBlur={this.blurEmailValid}
                  error={this.state.emailError2}
                />
                <Form.Input
                  label={intlU("confirmEmail")}
                  fluid
                  icon="mail"
                  iconPosition="left"
                  name="confirmEmail"
                  onChange={this.handleInputChange}
                  onBlur={this.blurConfirmEmail}
                  error={this.state.emailError}
                />
                <Form.Input
                  fluid
                  label={intlU("password")}
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="password"
                  onChange={this.handleInputChange}
                  onBlur={this.blurPassValid}
                  error={this.state.passwordError2}
                />
                <Form.Input
                  fluid
                  label={intlU("confirmPassword")}
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="confirmPassword"
                  onChange={this.handleInputChange}
                  onBlur={this.blurConfirmPassword}
                  error={this.state.passwordError}
                />
                <Form.Field>
                  <label>{intlU("language")}</label>
                  <Select
                    options={[
                      { text: intlU("french"), value: "fr-CA" },
                      { text: intlU("english"), value: "en-CA" },
                    ]}
                    onChange={this.handleChangeSelect("language")}
                    defaultValue={"fr-CA"}
                  />
                </Form.Field>
                <Button
                  style={{ background: "#217666", color: "white" }}
                  fluid
                  size="large"
                  disabled={
                    !this.emailValid() ||
                    !this.passwordValid() ||
                    !this.nameValid() ||
                    !this.passwordStrong()
                  }
                  onClick={this.signup}
                >
                  {intlU("signUp")}
                </Button>
              </Segment>
            </Form>
            <Message>
              <a href="/login">{intlU("login")}</a>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default SignUpForm;
