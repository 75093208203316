import React, { Component } from "react";
import SamplesList from "./SamplesList";

class MySamples extends Component {
  render() {
    return <SamplesList header={this.props.header}
      preFilter={{userId: this.props.userId}}
    />
  }
}

export default MySamples;
