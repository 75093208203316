import React from "react";
import UsersTable from "./UsersTable";
import { TopLevelWrapper } from "../Wrappers";

const UsersList = () => (
  <TopLevelWrapper icon="users" header="usersList">
    <UsersTable />
  </TopLevelWrapper>
);

export default UsersList;
