import ModelOptions from "../../../model_options";

const columnNameMap = [
  {names: ["espece", "species"], idColumn: "speciesId", objectColumn: "species", type: "remote"},
  {names: ["ecotype"], idColumn: "ecotypeId", objectColumn: "ecotype", type: "remote"},
  {names: ["sampleType", "samplesType", "TypeEchant", "type", "type Échantillon", "sample type"], idColumn: "samplesTypeId", objectColumn: "samples_type", type: "remote"},
  {names: ["country", "pays"], idColumn: "countryId", objectColumn: "country", type: "remote"},
  {names: ["province", "state", "province/state"], idColumn: "provinceId", objectColumn: "province", type: "remote"},
  {names: ["sector", "secteur"], idColumn: "sectorId", objectColumn: "sector", type: "remote"},
  {names: ["population", "pop"], idColumn: "populationId", objectColumn: "population", type: "remote"},
  {names: ["sexe", "sex"], column: "sex", synonyms: [{synonyms: ["M"], value: "male"}, {synonyms: ["F", "femelle"], value: "female"}], type: "implicit"},
  {names: ["followupTele", "suiviTélémétrique"], column: "followupTele", synonyms: [{synonyms: ["Y", "yes", "O", "oui"], value: "yes"}, {synonyms: ["N", "No", "Non"], value: "no"}], type: "implicit"},
  {names: ["followupCond", "suiviCondition"], column: "followupCond", synonyms: [{synonyms: ["Y", "yes", "O", "oui"], value: "yes"}, {synonyms: ["N", "No", "Non"], value: "no"}], type: "implicit"},
  {names: ["age"], idColumn: "ageId", objectColumn: "age", type: "remote"},
  {names: ["owner", "proprietaire", "propriete"], idColumn: "ownerId", objectColumn: "owner", type: "remote"},
];

function noSpace(string) {
  return string.replaceAll("_", "").replaceAll(" ", "").replaceAll("-", "");
}

function localeEqual(str1, str2) {
  return noSpace(str1).localeCompare(noSpace(str2), "fr", {sensitivity: "base"}) === 0;
}

function mapColumnName(columnName) {
  return columnNameMap.find((columnSpec) => columnSpec.names.find((x) => localeEqual(x, columnName)));
}

function mapVocabKeys(sample, valueMap) {
  for(const key in sample) {
    if(sample[key] === "" || sample[key] === "NA" || sample[key] === "-" || sample[key] === "unknown" || sample[key] === "inconnu") {
      sample[key] = null;
    }
    
    var columnSpec = mapColumnName(key);
    if(key==="Propriete") {
      console.log(sample)
      console.log(columnSpec)
    }
    if(columnSpec && columnSpec.type === "remote") {
      if(valueMap.hasTable(columnSpec.objectColumn) && (typeof sample[key] === "string")) {
        var stringValue = sample[key];
        var mappedForeignKeyValue = valueMap.getId(columnSpec.objectColumn, stringValue);
      
        if(mappedForeignKeyValue) {
          sample[columnSpec.objectColumn] = valueMap.getVocab(columnSpec.objectColumn, mappedForeignKeyValue);
          sample[columnSpec.idColumn] = mappedForeignKeyValue;
        } else {
          sample.errors.push({type: "unmatched", key: columnSpec.objectColumn, value: stringValue})
          sample[key] = null;          
        }
      }
    } else if(columnSpec && columnSpec.type === "implicit" && (typeof sample[key] === "string")) {
      const synonymMatch = columnSpec.synonyms.find((x) => x.synonyms.find((y) => localeEqual(y, sample[key])))
      if(synonymMatch) {
        sample[key] = synonymMatch.value
      }
      
      const valExists = ModelOptions[columnSpec.column].find((x) => localeEqual(x, sample[key]));
      if(!valExists) {
        sample.errors.push({type: "unmatched", key: columnSpec.column, value: sample[key]})
        sample[key] = null;        
      } else {
        sample[columnSpec.column] = sample[key]
      }
    }
  }    
}

function adjustDate(sample) {
  if(sample.sampleDate) {
    if(isNaN(Date.parse(sample.sampleDate))) {
      // Invalid date format. See if it could be DD-MM-YYYY.
      const dateRegExp = /^(\d\d)[-/ ](\d\d)[-/ ](\d\d\d\d)$/
      const regExpResults = dateRegExp.exec(sample.sampleDate);
      
      // If it fits the DD-MM-YYYY format, invert the values.
      if(regExpResults) {
        sample.sampleDate = regExpResults[3] + "-" + regExpResults[2] + "-" + regExpResults[1] + "T12:00:00Z"
      } else {
        // Otherwise, report the error.
        sample.errors.push({type: "wrong format", key: "sampleDate", value: sample.sampleDate})
        sample.sampleDate = null;
      }
    } else {
      var newDate = new Date(sample.sampleDate)
      newDate.setUTCHours(12)
      sample.sampleDate = newDate.toISOString()
    }
  }    
}

function validateNumber(sample, coordinate) {
  var converted = Number(sample[coordinate]);
  if(isNaN(converted) && sample[coordinate] && (typeof sample[coordinate] === 'string')) {
    // Try again by replacing commas with periods
    converted = Number(sample[coordinate].replaceAll(",", "."));
  }
  
  if(isNaN(converted)) {
    sample.errors.push({type: "wrong format", key: coordinate, value: sample[coordinate]})
    sample[coordinate] = null;
  } else {
    sample[coordinate] = converted;
  }
}

function processCSVSample(sample, valueMap, groups) {
  sample.errors = [];
  
  mapVocabKeys(sample, valueMap)
  adjustDate(sample)
  sample.groups = groups

  // Country is often omitted and is almost always Canada.
  if(!sample.country && sample.province && (sample.province.name === "QC" || sample.province.name === "TNL" || sample.province.name === "QC-TNL")) {
    sample.countryId = 1;
    sample.country = valueMap.getVocab("country", 1)
  }
  
  // Species is often omitted and is almost always caribou.
  if(!sample.species) {
    sample.speciesId = 1;
    sample.species = valueMap.getVocab("species", 1)
  }
  
  validateNumber(sample, "latitude");
  validateNumber(sample, "longitude");
  
  return sample;
}  

export default processCSVSample;