import React, { Component } from "react";
import { Message, Icon, List, Modal, Button } from "semantic-ui-react";
import { intlU } from "../intl";

function getErrorList(errors) {
  return errors.map((x) => <List.Item key={x.field + x.key}>
      <List.Icon name='warning' color="red" />
      <List.Content>{intlU(x.field)} {intlU("error" + x.key)}</List.Content>        
    </List.Item>
  )  
}

function getIntlError(errors, contextPrefix) {
  if(errors.length === 0) {
    return null;
  } else if(errors[0].field === "unknown") {
    return {header: intlU(contextPrefix + "Error"), content:intlU(contextPrefix + "ErrorUnknown")}
  } else if(errors[0].field === "unauthorized") {
    return {header: intlU(contextPrefix + "Error"), content:intlU(contextPrefix + "ErrorUnauthorized")}
  } else if(errors[0].field === "foreignKey") {
    return {header: intlU(contextPrefix + "Error"), content:intlU(contextPrefix + "ErrorInUse")}
  } else {
    return {
      header: intlU(contextPrefix + "Error"), 
      content: getErrorList(errors)
    };
  }  
}

class SubmitErrorMessage extends Component {
  errorList() {
    return this.props.errors.map((x) => <List.Item key={x.field + x.key}>
        <List.Icon name='warning' color="red" /><List.Content>{intlU(x.field)} {intlU("error" + x.key)}</List.Content>        
      </List.Item>
    )
  }
  
  getMessageContent() {
    var errorElements = getIntlError(this.props.errors, this.props.contextPrefix);
    return <Message.Content>
        <Message.Header>{errorElements.header}</Message.Header>
        {errorElements.content}
      </Message.Content>;
  }
  
  render() {
    if(this.props.errors.length > 0) {
        return <Message icon negative onDismiss={() => this.props.onDismiss && this.props.onDismiss() }>
          <Icon name="exclamation circle"/>
          {this.getMessageContent()}
        </Message>  
    } else {
      return null;
    }
  };    
};

class SubmitErrorModal extends Component {
  dismissModal = () => {
    if(this.props.onDismiss) {
      this.props.onDismiss()
    }
  }
  
  render() {
    if(this.props.errors.length > 0) {
        var errorElements = getIntlError(this.props.errors, this.props.contextPrefix)
      
        return <Modal open onClose={this.dismissModal}>
          <Modal.Header><Icon size="large" name="exclamation circle" color="red"/>{intlU(errorElements.header)}</Modal.Header>
          <Modal.Content>{errorElements.content}</Modal.Content>
          <Modal.Actions><Button primary onClick={this.dismissModal}>{intlU("OK")}</Button></Modal.Actions>
        </Modal>  
    } else {
      return null;
    }
  };    
}

export { SubmitErrorMessage, SubmitErrorModal };