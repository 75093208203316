import React, { Component } from 'react'
import { Icon } from "semantic-ui-react";
import { CSVReader } from 'react-papaparse'
import FetchValueMaps from "../../FetchValueMaps";
import { intlU } from "../../../intl";
import processCSVSample from "./ProcessCSVSample"

class CSVDragAndDrop extends Component {
  state = {
    mapReady: false,
    valueMap: null,
    samples: []
  }
  
  readyCB = () => {
    this.setState({mapReady: true})
  }
  
  componentDidMount() {
    this.setState({valueMap: new FetchValueMaps(this.readyCB)})
  }
  
  handleOnFileLoad = (data) => {
    var newAnimals = data.map((x) => processCSVSample(x.data, this.state.valueMap, this.props.groups));
    this.setState({samples: newAnimals}, () => {if(this.props.animalCB) this.props.animalCB(this.state.samples) })
  }

  handleOnError = (err, file, inputElem, reason) => {
    alert(intlU("errorUpload") + "\n" + err);
  }

  handleOnRemoveFile = (data) => {
    this.setState({samples: []}, () => {if(this.props.animalCB) this.props.animalCB(this.state.samples) })
  }

  render() {
    return (
    <div height="45" style={{marginTop: "10px"}}>
      <CSVReader
        onFileLoad={this.handleOnFileLoad}
        onError={this.handleOnError}
        config={{header:true, skipEmptyLines: true, comments: "#"}}
        onRemoveFile={this.handleOnRemoveFile}
        addRemoveButton
        style={{dropArea:{backgroundColor:"white"}}}
        noClick={this.props.groups.length === 0}
        noDrop={this.props.groups.length === 0}
      >
        <Icon name="file outline" size="massive" color="grey" /><span>{this.props.groups.length === 0 ? intlU("selectGroupToProceed") : intlU("addFileToImport")}</span>
      </CSVReader>
    </div>
    )
  }
}

export default CSVDragAndDrop;