import React, { Component } from "react";
import { intlIcon } from "../../intl";
import { CollectionForm } from "./CollectionForm";
import { SubmitErrorMessage } from "../SubmitErrorMessage";
import {submitRequestAndRedirect } from "../SubmitUtils";
import { TopLevelWrapper, SaveButton } from "../Wrappers";

class CollectionsNew extends Component {
  state = {
    collection: {},
    submitErrors: [],
  };

  submitAndSave = (e) => {
    e.preventDefault();
    
    submitRequestAndRedirect("post", "collections", this.state.collection,
      (errors) => this.setState({submitErrors: errors})
    );
  };

  onCollectionChange = (collection) => {
    this.setState({collection: collection});  
  }
  
  validateCollection() {
    return this.state.collection &&
      this.state.collection.samples &&
      this.state.collection.samples.length &&
      this.state.collection.name;
  }
  
  render() {
    return <TopLevelWrapper icon={intlIcon("collection")} header="collectionsNew">
      <SubmitErrorMessage
        errors={this.state.submitErrors}
        contextPrefix="collectionNew"
        onDismiss={() => this.setState({submitErrors: []})}
      />          
      <CollectionForm
        onChange={this.onCollectionChange}
      />
      <br />
      <SaveButton
        onClick={this.submitAndSave}
        disabled={!this.validateCollection}
      />
    </TopLevelWrapper>;
  }
}

export default CollectionsNew;
