import React, { Component } from "react";
import {
  Form,
  Input,
  Segment
} from "semantic-ui-react";
import { intlU } from "../../intl";
import VocabularySelector from "../VocabularySelector"
import ModelOptions from "../../model_options"
import SamplesList from "../samples/SamplesList";
import CollectionsList from "../collection/CollectionsList";

class AnalysisAttributesForm extends Component {
  state = {
    analysis: {}
  }

  handleChange = (name) => (event, value) => {
    var newValue = null;
    if(value) newValue = value.value;
    else newValue= event.target.value;
    
    var newAnalysis = {...this.state.analysis, [name]: newValue};
    this.setState({analysis: newAnalysis}, () => this.props.onChange(this.state.analysis));
  }
  
  render() {
    return (
      <Segment raised tertiary>
        <Form>
          <Form.Group inline width="equals">
            <Form.Field>
              <label>{intlU("name")}</label>
              <Input
                style={{"min-width":"15em"}}
                fluid
                required
                onChange={this.handleChange("name")}
                defaultValue={this.props.defaultAnalysis ? this.props.defaultAnalysis.name : null}
              />
            </Form.Field>
            <VocabularySelector
              source={ModelOptions.analysisTypes}
              label={"type"}
              onChange={this.handleChange("type")}
              defaultValue={this.props.defaultAnalysis ? this.props.defaultAnalysis.type : null}
            />
          </Form.Group>        
          <Form.TextArea
            label={intlU("comments")}
            placeholder={intlU("enterComments")}
            onChange={this.handleChange("comments")}
            defaultValue={this.props.defaultAnalysis ? this.props.defaultAnalysis.comments : null}
          />
        </Form>  
      </Segment>
    );
  }
}

class AnalysisForm extends Component {
  state = {
    analysis: {}
  }
  
  attributeChange = (analysis) => {
    var newAnalysis = {...this.state.analysis};
    newAnalysis.name = analysis.name ? analysis.name : null;
    newAnalysis.type = analysis.type ? analysis.type : null;
    newAnalysis.comments = analysis.comments ? analysis.comments : null;
    this.setState({analysis: newAnalysis}, () => this.props.onChange(this.state.analysis));
  }
  
  sampleSelectChange = (sampleArray) => {
    var newAnalysis = {...this.state.analysis};
    newAnalysis.sampleId = sampleArray.length > 0 ? sampleArray[0] : null
    this.setState({analysis: newAnalysis}, () => this.props.onChange(this.state.analysis));
  }
  
  collectionSelectChange = (collectionArray) => {
    var newAnalysis = {...this.state.analysis};
    newAnalysis.collectionId = collectionArray.length > 0 ? collectionArray[0] : null
    this.setState({analysis: newAnalysis}, () => this.props.onChange(this.state.analysis));    
  }
  
  targetSelect() {
    if(this.state.analysis.type) {
      return <>
        { this.state.analysis.type !== "assignment" ? "" : 
          <SamplesList 
            header={intlU("selectSample")}
            onSelectionChange={this.sampleSelectChange}
            selectUnique
            defaultSelectedSamples={this.props.defaultAnalysis ? [this.props.defaultAnalysis.sample.id] : []}
          />
        }
        <CollectionsList
          header={intlU("selectCollection")}
          onSelectChange={this.collectionSelectChange}
          selectUnique
          defaultSelectedCollection={this.props.defaultAnalysis ? [this.props.defaultAnalysis.collection.id] : []}
        />
      </>
    } else {
      return null;
    }
  }
  
  render() {
    return <>
      <AnalysisAttributesForm
        defaultAnalysis={this.props.defaultAnalysis}
        onChange={this.attributeChange}
      />
      {this.targetSelect()}
    </>;
  }
}

export { AnalysisAttributesForm, AnalysisForm };