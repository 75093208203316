import React, { Component } from "react";
import {
  Header,
  Icon,
  Table,
  Button,
  Segment
} from "semantic-ui-react";
import { intlU, intlIcon } from "../../intl";
import SampleGrid from "../samples/SampleGrid";
import LoadingStandIn from "../LoadingStandIn";
import { TopLevelWrapper, DeleteModal, EditButton } from "../Wrappers";
import { SubmitErrorMessage } from "../SubmitErrorMessage";

class CollectionAttributesTable extends Component {
  render() {
    return(
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{intlU("comments")}</Table.Cell>
            <Table.Cell>{this.props.collection.comments || intlU("nocomments")}</Table.Cell>              
          </Table.Row>
        </Table.Body>
      </Table>    
    );
  }
}

class CollectionShow extends Component {
  async getCollection() {
    fetch("/api/collections/" + this.props.collection_id + "?samples=1")
      .then((res) => res.json())
      .then((collection) =>
        this.setState({ loading2: false, collection: collection })
      );
  }

  componentDidMount() {
    this.getCollection();
  }

  state = {
    loading2: true,
    collection: {},
    submitErrors: []
  };

  printButtons = () => {
    return (
      <div>
        <DeleteModal 
          objectType="collections" 
          object={this.state.collection} 
          redirectURL="/collections"
          contextPrefix="collectionDelete"
        />
        <EditButton
          objectType="collections" 
          object={this.state.collection} 
        />
        <Button secondary icon labelPosition="left"  floated="right" as='a' href={'/api/collections/' + this.state.collection.id + "/download"}>
          <Icon name="download" />
          {intlU("download")}
        </Button>
          
      </div>
    );
  };

  headerText() {
    return intlU("collection") + " " + (this.state.collection.name ? this.state.collection.name : "");
  }

  render() {
    return (
    <TopLevelWrapper icon={intlIcon("collection")} header={this.headerText()}>
      <LoadingStandIn active={this.state.loading2} >
        <SubmitErrorMessage
          errors={this.state.submitErrors}
          onDismiss={() => this.setState({submitErrors:[]})}
        />
        <Segment raised secondary>
          <CollectionAttributesTable collection={this.state.collection} />
        </Segment>
        <Segment raised secondary>
          <Header as="h2">{intlU("collectionSampleList")}</Header>
          <SampleGrid
            samples={this.state.collection.samples}
            noDelete
          />
          <br />
        </Segment>
        {this.printButtons()}
      </LoadingStandIn>
    </TopLevelWrapper>
    );
  }
}

export default CollectionShow;
