import React, { Component } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import SampleForm from "./SampleForm";
import { intlU } from "../../intl";
import {submitRequest } from "../SubmitUtils";
import { SubmitErrorMessage } from "../SubmitErrorMessage";

class SampleEditModal extends Component {
  state = {
    modalIsOpen: false,
    sample: {},
    submitting: false,
    submitErrors: []
  }
  
  componentDidMount() {
    this.setState({sample: this.props.sample})
  }
  
  onChange = (sample) => {
    this.setState({sample: sample})
  }
  
  openModal = () => {
    this.setState({modalIsOpen: true})    
  }
  
  closeModal = () => {
    this.setState({modalIsOpen: false, submitting: false})
  }
  
  submitAndSave = (e) => {
    e.preventDefault();
    this.setState({submitting: true});
    
    submitRequest("put", "samples", this.state.sample,
      (res) => {this.closeModal(); this.props.onSuccess(this.state.sample)},
      (errors) => this.setState({submitErrors: errors, submitting: false})
    );
  };  
  
  trigger() {
    if(this.props.edit) {
      if(this.props.compact) {
        return <Icon link name="pen" onClick={this.openModal} />
      } else {
        return <Button onClick={this.openModal}>{intlU("edit")}</Button>
      }
    }
  }
  
  getHeader() {
    if(this.props.edit) {
      return intlU("editSample");
    }
  }
  
  getContent() {
    return <div style={{backgroundColor:"#e7e9eb", paddingLeft:"1em", paddingRight:"1em"}}>
      <SubmitErrorMessage
        errors={this.state.submitErrors}
        contextPrefix="sampleModify"
        onDismiss={() => this.setState({submitErrors: []})}
      />        
      <SampleForm
        onChange={this.onChange}
        defaultSample={this.props.sample}
      />
    </div>
  }
  
  getActions() {
    if(this.props.edit) {
      return [
          {key:"save", primary: true, loading:this.state.submitting, onClick:this.submitAndSave, content: intlU("save")},
          {key:"cancel", negative: true,  onClick:this.closeModal, content: intlU("cancel")},
      ];
    }
  }
  
  render() {
    return <Modal
        onOpen={() => this.setState({modalIsOpen: true})}
        onClose={this.closeModal}
        trigger={this.trigger()}
        open={this.state.modalIsOpen}
        header={this.getHeader()}
        content={this.getContent()}
        actions={this.getActions()}
      />;
  }
}

export default SampleEditModal;