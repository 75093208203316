import React, { Component } from "react";
import {
  Segment
} from "semantic-ui-react";
import { intlU, intlIcon } from "../../intl";

import AnimalTable from "./AnimalTable";
import {GenotypeTable} from "./GenotypeTable";
import LoadingStandIn from "../LoadingStandIn";
import {TopLevelWrapper, DeleteModal, EditButton} from "../Wrappers";

class SampleShowTables extends Component {
  render() {
    return (
      <div>
        <AnimalTable sample={this.props.sample} />
        <GenotypeTable sample={this.props.sample} />
      </div>
    );
  }
}

class SampleShow extends Component {
  async getSample() {
    fetch("/api/samples/" + this.props.sample_id)
      .then((res) => res.json())
      .then((res_sample) =>
        this.setState({ loading1: false, sample: res_sample })
      );
  }

  componentDidMount() {
    this.getSample();
  }

  state = {
    loading1: true,
    sample: {},
  };

  headerText() {
    return intlU("sample") + " " + (this.state.sample.animalId ? this.state.sample.animalId : "");
  }

  printButton()  {
    return <div>
      <DeleteModal 
        objectType="samples" 
        object={this.state.sample} 
        redirectURL="/samples"
        contextPrefix="sampleDelete"
      />
      <EditButton
        objectType="samples" 
        object={this.state.sample} 
      />
    </div>;       
  };

  render() {
    return (
      <TopLevelWrapper icon={intlIcon("sample")} header={this.headerText()}>
        <LoadingStandIn active={this.state.loading1} >
          <Segment raised secondary>
            <SampleShowTables
              sample={this.state.sample}
            />      
          </Segment>
          {this.printButton()}          
        </LoadingStandIn>
      </TopLevelWrapper>
    );
  }
}

export default SampleShow;
