import React from "react";
import { Icon, Flag, Label } from "semantic-ui-react";
import { intlU, intlIcon, intlVocab } from "../../intl";
const cookie = require("cookie");

function nameOrDefault(sample, attribute, defaultValue) {
  if(sample[attribute]) {
    return sample[attribute];
  }
  
  return defaultValue;
}

function GAPPId(props) {
  return <span style={{ whiteSpace: 'nowrap' }}>{props.sample.gappId}</span>;
}

function Ecotype(props) {
  var displayEcotype = nameOrDefault(props.sample, "ecotype", "unknown");
  return  <span style={{ whiteSpace: 'nowrap' }}>
            <Icon name={intlIcon(displayEcotype === "unknown" ? displayEcotype : displayEcotype.name)} />&nbsp;{intlVocab(displayEcotype)}
          </span>;
};

function Species(props) {
  var displaySpecies = nameOrDefault(props.sample, "species", "unknown");
  
  return <>{intlVocab(displaySpecies)}</>;
}

function SpeciesEcotype(props) {
  return <>
           <Species sample={props.sample} />
           <Ecotype sample={props.sample} />
         </>;
}

function SampleLink(props) {
  return <a href={"/samples/" + props.sample.id}>
           {props.sample.animalId}
         </a>  
}

function SampleDate(props) {
  if (!props.sample.sampleDate) {
    return null;
  } else {
    const cookieLocale = cookie.parse(document.cookie).locale || "fr-CA";
    return new Date(props.sample.sampleDate).toLocaleDateString(cookieLocale);
  }  
}

function SampleType(props) {
  if (!props.sample.samples_type) {
    return null;
  } else {
    return intlVocab(props.sample.samples_type);
  }  
}

function Sex(props) {
  if(!props.sample.sex) {
    return null;
  } else {
    return <span style={{ whiteSpace: 'nowrap' }}><Icon name={intlIcon(props.sample.sex)} />{intlU(props.sample.sex)}</span>;
  }
}

function FollowupTele(props) {
  if(!props.sample.followupTele) {
    return null;
  } else {
    return <Icon color={props.sample.followupTele === "yes" ? "green" : "red"}  name={intlIcon(props.sample.followupTele)} />;
  }
}

function FollowupCond(props) {
  if(!props.sample.followupCond) {
    return null;
  } else {
    return <Icon color={props.sample.followupCond === "yes" ? "green" : "red"} name={intlIcon(props.sample.followupCond)} alt="test" />;
  }
}

function Age(props) {
  if(!props.sample.age) {
    return null;
  } else {
    return intlVocab(props.sample.age);
  }
}

function Country(props) {
  if(!props.sample.country) {
    return null;
  } else {
    return <span style={{ whiteSpace: 'nowrap' }}>
        <Flag name={intlIcon(props.sample.country.name)} />
        {intlVocab(props.sample.country)}
      </span>;
  }  
}

function Province(props) {
  if(!props.sample.province) {
    return null;
  } else {
    return intlVocab(props.sample.province);
  }    
}

function Sector(props) {
  if(!props.sample.sector) {
    return null;
  } else {
    return intlVocab(props.sample.sector);
  }    
}

function Owner(props) {
  if(!props.sample.owner) {
    return null;
  } else {
    return intlVocab(props.sample.owner);
  }    
}

function Latitude(props) {
  if(!props.sample.latitude) {
    return null;
  } else {
    return props.sample.latitude > 0 ? "N" + props.sample.latitude.toFixed(2) : "S" + Math.abs(props.sample.latitude).toFixed(2);
  }    
}

function Longitude(props) {
  if(!props.sample.longitude) {
    return null;
  } else {
    return props.sample.longitude > 0 ? "E" + props.sample.longitude.toFixed(2) : "W" + Math.abs(props.sample.longitude).toFixed(2);
  }    
}
  
function Coordinates(props) {
  if(!props.sample.longitude) {
    return null;
  } else {
    return <><Icon name="map marker alternate" /><Latitude sample={props.sample} />, <Longitude sample={props.sample} /></>
  }
}  
  
function Location(props) {
  return <>
    <Country sample={props.sample} />
    {props.sample.province ? ", " : ""}
    <Province sample={props.sample} />
    {props.sample.sector ? ", " : ""}
    <Sector sample={props.sample} />
  </>
}  
  
function LocationCoordinates(props) {
  return <>
    <Country sample={props.sample} />
    {props.sample.province ? ", " : ""}
    <Province sample={props.sample} />
    {props.sample.sector ? ", " : ""}
    <Sector sample={props.sample} />
    {props.sample.latitude ? " (" : ""}
    <Latitude sample={props.sample} />
    {props.sample.longitude ? ", " : ""}
    <Longitude sample={props.sample} />
    {props.sample.longitude ? ")" : ""}
  </>
}   
  
function Population(props) {
  if(!props.sample.population) {
    return null;
  } else {
    return intlVocab(props.sample.population);
  }   
}

function sampleGroupString(groups) {
  if(!groups || !Array.isArray(groups)) {
    return null;
  } else {
    return groups.reduce((prev, element) => !prev ? intlVocab(element) : prev + ", " + intlVocab(element), "")
  }
}

function SampleGroups(props) {
  if(!props.sample.groups) {
    return null;
  } else {
    return props.sample.groups.map((x) => <Label horizontal key={x.id}>{intlVocab(x)}</Label>);
  }
}

function validateNumeric(sample, attribute, min, max) {
  var errors = [];
  
  if(sample[attribute] && parseInt(sample[attribute]) && parseInt(sample[attribute]) < min) {
    errors.push({field: attribute, key: "min"})
  }
  
  if(sample[attribute] && parseInt(sample[attribute]) && parseInt(sample[attribute]) > max) {
    errors.push({field: attribute, key: "max"})
  }  
  
  return errors;
}

function validateSample(sample) {
  var errors = [];
  if(!sample.animalId) {
    errors.push({field: "animalId", key: "is_null"})
  }
  
  if(!sample.speciesId) {
    errors.push({field: "speciesId", key: "is_null"})
  }

  if(!sample.groups) {
    errors.push({field: "groups", key: "is_null"})
  }

  errors = errors.concat(validateNumeric(sample, "latitude", -90, 90));
  errors = errors.concat(validateNumeric(sample, "longitude", -180, 180));
  
  errors = errors.concat(validateNumeric(sample, "lengthTotal", 30, 400));
  errors = errors.concat(validateNumeric(sample, "lengthFoot", 1, 30));
  errors = errors.concat(validateNumeric(sample, "lengthMetatarsus", 1, 30));
  errors = errors.concat(validateNumeric(sample, "weight", 1, 400));
  
  return errors;
}

export {Ecotype, Species, SpeciesEcotype, SampleLink, SampleDate, SampleType, 
  Country, Province, Sector, Population,
  Latitude, Longitude, Location, Sex, Age, Coordinates, LocationCoordinates, 
  sampleGroupString, SampleGroups, validateSample, FollowupTele, FollowupCond,
  Owner, GAPPId };