import React, { Component } from "react";
import { Form, Dropdown, Modal, Input, Button, Icon, Rail, Grid } from "semantic-ui-react";
import { intlU, intlL, intlVocab } from "../intl";
import { submitRequest } from "./SubmitUtils";
import { SubmitErrorModal } from "./SubmitErrorMessage"

class AddModal extends Component {
  state = {
    newName: "",
    modalOpen: false,
    submitErrors: [],
  }
  
  saveNew = () => {
    console.log("Saving")
    const newVocab = {
      name: this.state.fr + "_" + this.state.en,
      fr: this.state.fr,
      en: this.state.en
    };
    
    if(this.props.parentId && this.props.parentIdName) {
      newVocab[this.props.parentIdName] = this.props.parentId;
    }
    
    submitRequest("post", this.props.objectType, newVocab,
      () => {this.setState({modalOpen:false}); this.props.onAdd()},
      (errors) => {this.setState({submitErrors: errors})})
  };  
  
  onAddInputChange = (language) => (e, data) => {
    this.setState({[language]: data.value})  
  }
  
  render() {
    return(
      <Modal
        trigger={<Button size="tiny" icon='add' primary circular disabled={this.props.disabled} onClick={(e) => {e.preventDefault(); this.setState({modalOpen: true})}}/>}
        open={this.state.modalOpen}
      >
        <Modal.Header><Icon name="add" size="large" />{intlU("addA") + " " + intlL(this.props.label)}</Modal.Header>
        <Modal.Content>
          <h3>{intlU("enterTerm")}</h3>
          <label>{intlU("enterFrench")}</label>
          <Input
            fluid
            onChange={this.onAddInputChange("fr")}
          />
          <label>{intlU("enterEnglish")}</label>
          <Input
            fluid
            onChange={this.onAddInputChange("en")}
          />          
        </Modal.Content>
        <Modal.Actions>
          <Button 
            icon='cancel' 
            negative
            content={intlU("cancel")}
            onClick={() => this.setState({modalOpen: false})}
          />
          <Button
            content={intlU("save")}
            positive
            icon='checkmark'
            onClick={() => this.saveNew()}
            disabled={!this.state.fr || !this.state.en || this.state.fr === "" || this.state.en === ""}
          />
        </Modal.Actions>
        <SubmitErrorModal
          errors={this.state.submitErrors}
          onDismiss={() => this.setState({submitErrors:[]})}
          contextPrefix="vocabularyNew"
        />
      </Modal>
    );
  }  
}

class VocabularySelector extends Component {
  state = {
    vocabulary: [],
    loading: true,
  };
  
  isRemote = () => {
    return !Array.isArray(this.props.source);
  }
  
  fetchVocab = () => {
    if(!this.isRemote()) {
      var options = this.props.source.map((option) => {
        if(typeof option === "string") {
          return { name: intlU(option), id: option }
        } else {
          return option
        }
      });
      if(this.props.any) {
        options.unshift({name: intlU("any"), id: "NOT NULL"})
        options.unshift({name: intlU("none"), id: "NULL"})
      }
      this.setState({vocabulary: options, loading: false});
    } else {
      var fetchAddress = this.props.source;
      if(this.props.parentId && this.props.parentId !== "NOT NULL" && this.props.parentId !== "NULL") {
        fetchAddress = fetchAddress + "?parentId=" + this.props.parentId;
      }
      this.setState({loading: true});
      
      fetch(fetchAddress)
        .then((res) => res.json())
        .then((vocabulary) => {
          if(this.props.any) {
            vocabulary.unshift({name: intlU("any"), id: "NOT NULL"})
            vocabulary.unshift({name: intlU("none"), id: "NULL"})
          }
          this.setState({ loading: false, vocabulary: vocabulary })
        });
    }
  }
  
  componentDidMount() {
    this.fetchVocab();
  };      
    
  componentDidUpdate(prevProps) {
    if(this.props.parentId !== prevProps.parentId) {
      this.fetchVocab();
    }
  }
    
  isDisabled() {
    return (this.props.disableIfParentNull && !this.props.parentId) || this.state.vocabulary.length===0 || this.props.disabled;
  }    

  isAddDisabled() {
    return (this.props.disableIfParentNull && !this.props.parentId) || this.props.disabled;
  }   
    
  onChange = (event, data) => {
    if(this.props.onChange) {
      this.props.onChange(event, data)
    }
    
    if(this.props.onChangeVocab) {
      this.props.onChangeVocab(this.state.vocabulary.find((x) => x.id === data.value))
    }
  }    
    
  render() {
    return (
      <Form.Field required={!!this.props.isRequired}>
        { this.props.label && <label>{intlU(this.props.label)}</label> }
        <Grid verticalAlign='middle'>
          <Grid.Column width={14} style={{paddingRight:"0.4em"}}>
            <Dropdown
              clearable
              placeholder={intlU(this.props.placeholder)}
              options={this.state.vocabulary.map((vocab) => {return {value:vocab.id, text:intlVocab(vocab)}}) }
              onChange={this.onChange}
              search
              fluid
              selection
              disabled={this.isDisabled()}
              loading={this.state.loading}
              defaultValue={this.props.defaultValue}
              style={{paddingRight:"0.5em"}}
            />
          </Grid.Column>
          <Grid.Column width={2} style={{paddingLeft:"0.4em"}}>
          { this.isRemote() && this.props.allowAdd && 
            <AddModal 
              objectType={this.props.objectType}
              address={this.props.source}
              label={this.props.label}
              parentId={this.props.parentId}
              parentIdName={this.props.parentIdName}
              onAdd={() => this.fetchVocab()}
              disabled={this.isAddDisabled()}
            />
          }   
          </Grid.Column>          
        </Grid>
        { this.props.errorLabel }
      </Form.Field>
    );
  };    
};

export default VocabularySelector;