import React, { Component } from "react";
import {  Input, Button, Table } from "semantic-ui-react";
import { intlU, intlVocab } from "../intl";
import { TopLevelWrapper, SectionWrapper, SaveButton, DeleteModal } from "./Wrappers";
import { submitRequest } from "./SubmitUtils";
//import { SubmitErrorModal } from "./SubmitErrorMessage"

class VocabularyRow extends Component {
  state = {
  }
  
  handleChange = (attribute) => (event) => {
    this.setState({[attribute]: event.target.value});
  }

  saveChanges = () => {
    var newVocab = {...this.props.vocabulary};
    if(this.state.fr) {
      newVocab.fr = this.state.fr
    }

    if(this.state.en) {
      newVocab.en = this.state.en
    }

    submitRequest("put", this.props.model, newVocab, () => { window.location.assign("/vocabularies") }, () => {alert("Error")});
  }

  parentCell() {
    if(!this.props.parent) {
      return null;
    } else {
      return <Table.Cell>{intlVocab(this.props.vocabulary[this.props.parent])}</Table.Cell>;
    }
  }
  
  actionColumn() {
    return <Table.Cell>
      <Button.Group floated="right">
        <SaveButton notFluid onClick={this.saveChanges} />
        <DeleteModal objectType={this.props.model} object={this.props.vocabulary} contextPrefix="vocabularyDelete" redirectURL="/vocabularies" />
      </Button.Group>
    </Table.Cell>;
  }
  
  isAdmin() {
    return this.props.userrole === "root" || this.props.userrole === "sadmin";
  }
  
  inputOrText(language) {
    if(this.isAdmin()) {
      return <Input onChange={this.handleChange(language)} defaultValue={this.props.vocabulary[language]} />
    } else {
      return this.props.vocabulary[language]
    }
  }
  
  render() {
    return <Table.Row>
      <Table.Cell>{this.props.vocabulary.name}</Table.Cell>
      {this.parentCell()}
      <Table.Cell>{this.inputOrText("fr")}</Table.Cell>
      <Table.Cell>{this.inputOrText("en")}</Table.Cell>
      { this.isAdmin() ? this.actionColumn(): null }
    </Table.Row>
  }
}

class VocabularyTable extends Component {
  state = {
    vocabulary: [],
    loading: true,
  };
  
  fetchVocab = () => {
    this.setState({loading: true});
    
    fetch("/api/" + this.props.model)
      .then((res) => res.json())
      .then((vocabulary) => {
        this.setState({ loading: false, vocabulary: vocabulary })
      });
  }
  
  componentDidMount() {
    this.fetchVocab();
  };      
    
  componentDidUpdate(prevProps) {
    if(this.props.model !== prevProps.model) {
      this.fetchVocab();
    }
  }
    
  rows() {
    return this.state.vocabulary.map((x) => <VocabularyRow 
      vocabulary={x} 
      model={this.props.model} 
      parent={this.props.parent} 
      parentSource={this.props.parentSource} 
      userrole={this.props.userrole}
      key={x.name} 
    />)
  }
  
  isAdmin() {
    return this.props.userrole === "root" || this.props.userrole === "sadmin";
  }
  
  render() {
    return <SectionWrapper header={this.props.model}>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{intlU("name")}</Table.HeaderCell>
            {this.props.parent && <Table.HeaderCell>{intlU(this.props.parent)}</Table.HeaderCell>}
            <Table.HeaderCell>{intlU("french")}</Table.HeaderCell>
            <Table.HeaderCell>{intlU("english")}</Table.HeaderCell>
            { this.isAdmin() ? <Table.HeaderCell>{intlU("actions")}</Table.HeaderCell> : null }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.rows()}
        </Table.Body>
      </Table>
    </SectionWrapper>
  };    
};

const vocabList = [
  {name: "species"},
  {name: "ecotypes", parent: "species", parentSource: "species"},
  {name: "sampletypes"},
  {name: "countries"},
  {name: "provinces", parent: "country", parentSource: "countries"},
  {name: "populations", parent: "province"},
  {name: "sectors", parent: "region", parentSource: "regions"},
  {name: "groups", parent: null},
  {name: "ages", parent: null},
]

class VocabularyManager extends Component {
  render() {
    return <TopLevelWrapper icon="book" header="vocabularies">
      {vocabList.map((x) => <VocabularyTable model={x.name} parent={x.parent} parentSource={x.parentSource} key={x.name} userrole={this.props.userrole} />)}
    </TopLevelWrapper>
  }
}

export default VocabularyManager;