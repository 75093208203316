import React from "react";
import { Table } from "semantic-ui-react";
import { intlU } from "../../intl";

function makeRow(label, content) {
  if (content) {
    return (
      <Table.Row>
        <Table.Cell>{intlU(label)}</Table.Cell>
        <Table.Cell>{content}</Table.Cell>
      </Table.Row>
    );
  }
};

function makeHeader(label) {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan="2">{intlU(label)}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

export { makeRow, makeHeader };