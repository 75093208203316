import React, { Component } from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { intlU } from "../../intl";

class CollectionRow extends Component {
  render() {
    var cells = this.props.columns.map((column) => {
      var content = "";
      if (column === "select") {
        content = (
          <Checkbox
            checked={this.props.selected}
            onClick={() => this.props.onSelectClick(this.props.collection.id)}
          />
        );
      } else if (column === "name") {
        content = (
          <a href={"/collections/" + this.props.collection.id}>
            {this.props.collection.name}
          </a>
        );
      //} else if (column === "species") {
      //  content = intlU(this.props.collection.species.name);
      //} else if (column === "userGroupId") {
      //  content = intlU(this.props.collection.user_group.name);
      } else {
        content = this.props.collection[column];
      }
      
      return(<Table.Cell key={column}>{content}</Table.Cell>);
    });

    return (
      <Table.Row active={this.props.selected}>
        {cells}
      </Table.Row>
    );
  }
}

class CollectionTable extends Component {
  render() {
    let rows = this.props.collections.map((collection) => <CollectionRow
      collection={collection}
      key={collection.id}
      columns={this.props.columns}
      selected={this.props.selectedCollections ? this.props.selectedCollections.includes(collection.id) : false}
      onSelectClick={this.props.onSelectClick}
    />);
    
    if(rows.length === 0 ) {
      rows = <Table.Row>
          <Table.Cell colSpan={this.props.columns.length} textAlign="center">
            {intlU("noCollection")}
          </Table.Cell>
      </Table.Row>
    }

    let headerColumns = this.props.columns.map((column) =>
      <Table.HeaderCell key={column}>
        {intlU(column)}
      </Table.HeaderCell>
    );

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>{headerColumns}</Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  }
}

export default CollectionTable;
