import React, { Component } from "react";
import { AnalysisForm } from "./AnalysisForm";
import { SubmitErrorMessage } from "../SubmitErrorMessage";
import {submitRequestAndRedirect } from "../SubmitUtils";
import { TopLevelWrapper, SaveButton } from "../Wrappers";

class AnalysisNew extends Component {
  state = {
    analysis: {},
    submitErrors: [],
  };

  submitAndSave = (e) => {
    e.preventDefault();
    
    submitRequestAndRedirect("post", "analyses", this.state.analysis,
      (errors) => this.setState({submitErrors: errors}))
  };

  onAnalysisChange = (analysis) => {
    this.setState({analysis: analysis});  
  }
  
  validateAnalysis() {
    return this.state.analysis &&
           this.state.analysis.type &&
           (this.state.analysis.type !== "assignment" || this.state.analysis.sampleId) &&
           this.state.analysis.collectionId &&
           this.state.analysis.name;
  }
  
  render() {
    return (
      <TopLevelWrapper icon="clipboard list" header="analysesNew">
        <SubmitErrorMessage
          errors={this.state.submitErrors}
          contextPrefix="analysisNew"
          onDismiss={() => this.setState({submitErrors: []})}
        />      
        <AnalysisForm
          onChange={this.onAnalysisChange}
        />
        <br />
        <SaveButton
          onClick={this.submitAndSave}
          disabled={!this.validateAnalysis()}
        />
      </TopLevelWrapper>
    );
  }
}

export default AnalysisNew;
