import React, { Component } from "react";
import { intlU, intlVocab } from "../../intl";
import { SampleLink, Species, Ecotype, Country, Province, Region, Sector, SampleType, Sex, Age, Latitude, Longitude, sampleGroupString, SampleGroups, FollowupTele, FollowupCond, Owner, GAPPId} from "./SampleUtils"
//import { DataGrid, frFR, enUS } from '@mui/x-data-grid';
import { DataGridPro, frFR, enUS } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { GenotypeControls } from "./GenotypeTable";
import SampleEditModal from "./SampleEditModal";
import { DeleteModal} from "../Wrappers";


const cookie = require("cookie");

// This is the license key for the Pro version of Material UI, which allows column
// resizing and reordering of the DataGrid component. This license was bought by
// Bioinfo Eric fournier inc. on November 10th, 2022, and will be valid up to
// Novermber 10th, 2023.
//
// The license allows for perpetual use in production: IE, in the production 
// environment, it will always be valid as long as Material UI is not upgraded
// to a version which was released AFTER the license expired.
//
// In a development environment, the license is only valid until its expiration.
// 
// If development of this application is passed on to another developper or set 
// of developpers, then a new license will have to be obtained. 
// See https://mui.com/x/introduction/licensing for details.
LicenseInfo.setLicenseKey('489cb74353b4780e7aca212ecf8f9250Tz01NDE2NyxFPTE2OTk3MjE3MjA3NTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


function genericVocabColumn(name, vocabObject=null, width=100, component=null) {
  const objectAttr = vocabObject || name;
  const renderComponent = component || ((props) => <>{intlVocab(props.sample[vocabObject])}</>)
  
  return {
    field: name,
    headerName: intlU(name),
    width: width,
    editable: false,
    valueGetter: (params) => intlVocab(params.row[objectAttr]),
    renderCell: (params) => renderComponent({sample:params.row})
  }
}

class SampleGrid extends Component {
  state = {
    columns: [],
  }
  
  renderGenotypes = (params) => {
    return <GenotypeControls 
      sample={params.row} 
      onUploadSuccess={this.props.onSampleChange}
      onDeleteSuccess={this.props.onSampleChange}
      compact 
    />
  }
  
  renderActions = (params) => {
    return <>
      <SampleEditModal
        edit
        sample={params.row}
        onSuccess={this.props.onSampleChange}
        compact
      />
      { this.props.noDelete ? null : <DeleteModal 
          compact    
          objectType="samples" 
          object={params.row} 
          redirectURL="/samples"
          contextPrefix="sampleDelete"
          onSuccess={this.props.onSampleChange}
        />
      }
    </>;
  }
  
  buildColumnsArray() {
    const columns = [
      {
        field: 'animalId',
        headerName: intlU("animalId"),
        width: 200,
        editable: false,
        renderCell: (params) => <SampleLink sample={params.row} />,
        flex: 1,
      },
      {
        field: 'gappId',
        headerName: intlU("gappId"),
        width: 100,
        editable: false,
        renderCell: (params) => <GAPPId sample={params.row} />,
      },      
      {
        field: 'groups',
        headerName: intlU("groups"),
        width: 150,
        editable: false,
        valueGetter: (params) => sampleGroupString(params.row.groups),
        renderCell: (params) => <SampleGroups sample={params.row} />,
        flex: 1,
      },
      genericVocabColumn("species", "species", 150, Species),
      genericVocabColumn("ecotype", "ecotype", 150, Ecotype),
      genericVocabColumn("sampleType", "samples_type", 150, SampleType),
      {
        field: "sampleDate",
        headerName: intlU("sampleDate"),
        width: 100,
        editable: false,
        type: "date",
        valueGetter: (params) => new Date(params.row.sampleDate),
      },
      genericVocabColumn("sex", "sex", 75, Sex),
      genericVocabColumn("age", "age", 30, Age),
      genericVocabColumn("country", "country", 150, Country),
      genericVocabColumn("province", "province", 150, Province),
      genericVocabColumn("sector", "sector", 150, Sector),
      {
        field: "latitude",
        headerName: intlU("latitude"),
        width: 75,
        editable: false,
        type: "number",
        renderCell: (params) => <Latitude sample={params.row} />
      },
      {
        field: "longitude",
        headerName: intlU("longitude"),
        width: 75,
        editable: false,
        type: "number",        
        renderCell: (params) => <Longitude sample={params.row} />
      },  
      genericVocabColumn("owner", "owner", 150, Owner),
      genericVocabColumn("followupTele", "followupTele", 75, FollowupTele),
      genericVocabColumn("followupCond", "followupCond", 75, FollowupCond),
      {
        field: "hasGenotype",
        headerName: intlU("genotype"),
        width: 75,
        editable: false,
        type: "boolean",
        renderCell: this.renderGenotypes,
      },
      {
        field: "actions",
        headerName: intlU("edit"),
        width: 75,
        editable: false,
        valueGetter: (params) => params.row.accessLevel,
        renderCell: this.renderActions,
      }
    ];    
    
    return columns;
  }
  
  componentDidMount() {
    this.setState({columns: this.buildColumnsArray()});
  }
  
  render() {
    const cookieLocale = cookie.parse(document.cookie).locale || "fr-CA";
    const gridLocale = cookieLocale === "fr-CA" ? frFR : enUS;

    var initialState={
      columns: {
        columnVisibilityModel: {
          // Hide columns status and traderName, the other columns will remain visible
          species: false,
          country: false,
          longitude: false,
          latitude: false,
        },
      },
    }

    if(Array.isArray(this.props.defaultColumns) && this.props.defaultColumns > 1) {
      initialState.columns.columnVisibilityModel = this.props.defaultColumns
    }

    return (
      <>
        <div style={{ display: 'flex', height: '650px' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGridPro
              initialState={initialState}
              localeText={gridLocale.components.MuiDataGrid.defaultProps.localeText}
              sx={{backgroundColor: "white"}}
              rows={this.props.samples}
              columns={this.state.columns}
              autoheight
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              checkboxSelection={this.props.select}
              onSelectionModelChange={this.props.onSelectionChange}
              loading={this.props.loading}
            />
          </div>
        </div>      
      </>
    );
  }
}

export default SampleGrid;
