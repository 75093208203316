import React, { Component } from "react";
import { AnalysisAttributesForm } from "./AnalysisForm";
import FilteredAnalysesTable from "./FilteredAnalysesTable";
import {
  Segment
} from "semantic-ui-react";


class FilterableAnalysesTable extends Component {
  state = {
    filter: {},
  };

  onFilterChange = (filter) => {
    this.setState({filter: filter})
  }

  render() {
    return (
      <>
        <AnalysisAttributesForm
          onChange={this.onFilterChange}
        />
        <Segment raised tertiary>
          <FilteredAnalysesTable
            filter={this.state.filter}
          />
        </Segment>
      </>
    );
  }
}

export default FilterableAnalysesTable;
