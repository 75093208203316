import React, { Component } from "react";
import SampleForm from "./SampleForm";
import { Header, Form, Segment } from "semantic-ui-react";
import { intlU } from "../../intl";
import { validateSample} from "./SampleUtils";
import { SubmitErrorMessage } from "../SubmitErrorMessage";
import {submitRequestAndRedirect } from "../SubmitUtils";
import { TopLevelWrapper, SaveButton } from "../Wrappers";

class SampleNew extends Component {
  state = {
    sample: {},
    submitErrors: [],
  };

  componentDidMount() {
  }

  handleChangeFilter = (filter) => {
    this.setState({sample: filter})
  }

  submitAndSave = (e) => {
    e.preventDefault();
    
    submitRequestAndRedirect("post", "samples", this.state.sample,
      (errors) => this.setState({submitErrors: errors})
    );
  };

  render() {
    return (
      <TopLevelWrapper icon="clipboard list" header="newSample">
        <SubmitErrorMessage
          errors={this.state.submitErrors}
          contextPrefix="sampleNew"
          onDismiss={() => this.setState({submitErrors: []})}
        />
        <Header as="h2">
          <Header.Content>{intlU("enterSample")}</Header.Content>
        </Header>        
        <Segment raised tertiary>
          <Form>
            <SampleForm 
              onChange={this.handleChangeFilter}
            />          
          </Form>
        </Segment>
        <SaveButton
          disabled={validateSample(this.state.sample).length!==0}
          onClick={this.submitAndSave}
        />
      </TopLevelWrapper>
    );
  }
}

export default SampleNew;
