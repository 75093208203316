import React, { Component } from "react";
import axios from "axios";
import LoadingStandIn from "../LoadingStandIn";
import PureAnalysesTable from "./PureAnalysesTable";

class FilteredAnalysesTable extends Component {
  state = {
    loading: true,
    analyses: [],
  };

  async fetchAnalyses() {
    this.setState({loading: true, analyses: []});
    
    axios.post("/api/analyses/filter", this.props.filter)
      .then((res) => {console.log(res); console.log(res.data); return res.data;})
      .then((analyses) =>
        this.setState({ loading: false, analyses: analyses })
      );
  }

  componentDidMount() {
    this.fetchAnalyses();
  }

  componentDidUpdate(prevProps) {
    if(this.props.filter !== prevProps.filter) {
      console.log("It's different.");
      this.fetchAnalyses();
    }
  }

  render() {
      return (
        <LoadingStandIn active={this.state.loading} >
          <PureAnalysesTable
            analyses={this.state.analyses}
          />
        </LoadingStandIn>
      );
    }
}

export default FilteredAnalysesTable;
