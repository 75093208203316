import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
  Icon
} from "semantic-ui-react";
import { intlU } from "../../intl";
            
class LoginForm extends Component {
  state = {
    loginError: null,
  }
  
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const resetToken = urlParams.get("resetToken");
    const email = urlParams.get("email");
    if(resetToken && resetToken !== "") {
      this.forgotPasswordLogin(email, resetToken)
    }
  }

  forgotPasswordLogin(email, resetToken)  {
    console.log(resetToken)
    fetch("/api/resetPasswordLogin", {
      method: "POST",
      body: JSON.stringify({resetPassword:resetToken, email:email}),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
        if (res.status === 200) {
          this.props.history.push("/preferences/");
        } else {
          res.text().then((the_response) => console.log(the_response))
          this.setState({loginError: "errorRecupPassWord"});
        }
    }).catch((err) => {
      console.log(err)
      this.setState({loginError: "errorRecupPassWord"});
    });
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
      loginError: null
    });
  };

  login = (e) => {
    e.preventDefault();
    fetch("/api/authenticate", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.history.push("/");
        } else if (res.status === 404) {
          this.setState({loginError: "errorUser"})
        } else if (res.status === 401) {
          return res.text().then((text) => {
            if(text.indexOf("Await admin authorization") !== -1) {
              this.setState({loginError: "awaitAuthorization"})
            } else {
              this.setState({loginError: "passwordError"})
            }
          })
        } else {
          this.setState({loginError: "errorLogin"})
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({loginError: "errorLogin"})
      });
  };

  errorMessage() {
    if(this.state.loginError) {
      return <Message icon negative><Icon name="exclamation circle"/><Message.Content>{intlU(this.state.loginError)}</Message.Content></Message>
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="login-form">
        <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>

        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="black" textAlign="center">
              <Image src="/logo.png" />
              {intlU("connectPrompt")}
            </Header>
            {this.errorMessage()}
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder={intlU("emailPrompt")}
                  name="email"
                  onChange={this.handleInputChange}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder={intlU("passwordPrompt")}
                  type="password"
                  name="password"
                  onChange={this.handleInputChange}
                />

                <Button
                  style={{ background: "#217666", color: "white" }}
                  fluid
                  icon
                  size="large"
                  onClick={this.login}
                >
                  {intlU("connectVerb")} <Icon name="sign in alternate" />
                </Button>
              </Segment>
            </Form>
            <Message>
              {intlU("noAccount")} <a href="/signup">{intlU("signUp")}</a>
            </Message>
            <Message>
              <a href="/ForgotPassword">{intlU("ForgotPassword")}</a>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default LoginForm;
