import React, { Component } from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { intlU, intlVocab } from "../intl";

class GroupSelector extends Component {
  state = {
    groups: [],
    loadingGroups: true,
    permissions: [],
    loadingToken: true
  };
  
  levelEqualOrGreater(level1, level2) {
    if(!level2 || (level2 === level1)) {
      return true;
    } else if(level2 === "read" && level1) {
      return true;
    } else if(level2 === "read_full" && (level1 === "write")) {
      return true;
    } else {
      return false;
    }
  }
  
  componentDidMount() {
    fetch("/api/groups")
      .then((res) => res.json())
      .then((groups) => this.setState({ loadingGroups: false, groups: groups }));    

    fetch("/api/checkToken")
      .then((res) => res.json())
      .then((token) => this.setState({ loadingToken: false, permissions: token.permissions }));    
  };      
    
  dropdownOptions = () => {
    return this.state.groups
      .filter((group) => this.state.permissions.find((permission) => permission.groupId === group.id && this.levelEqualOrGreater(permission.level, this.props.minLevel)))
      .map((group) => {return {value:group.id, text:intlVocab(group)}});
  }
    
  onChange = (event, data) => {
    if(this.props.onChange) {
      this.props.onChange(event, data);
    }
    
    if(this.props.onVocabChange) {
      this.props.onVocabChange(this.state.groups.filter((x) => data.value.includes(x.id)))
    }
  }
    
  parseDefaultGroups() {
    if(this.props.defaultGroups && Array.isArray(this.props.defaultGroups)) {
      if(this.props.defaultGroups.every((x) => x.id)) {
        return this.props.defaultGroups.map((x) => x.id)
      } else if(this.props.defaultGroups.every((x) => !isNaN(Number(x)))){
        return this.props.defaultGroups
      } else {
        return []
      }
    } else {
      return []
    }
  }
    
  render() {
    return (
      <Form.Field required={!!this.props.isRequired}>
        <label>{intlU("groups")}</label>
        <Dropdown
          options={this.dropdownOptions()}
          onChange={this.onChange}
          search
          selection
          fluid
          clearable
          multiple
          loading={this.state.loadingGroups || this.state.loadingToken }
          defaultValue={this.parseDefaultGroups()}
          disabled={this.props.disabled}
        />
        {this.props.errorLabel}
      </Form.Field>
    );
  };     
};

export default GroupSelector;