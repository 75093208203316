import React, { Component } from "react";
import {
  Icon,
  Modal,
  Button,
  Header,
  Segment
} from "semantic-ui-react";
import SampleForm from "./SampleForm";
import { intlU } from "../../intl";

class SampleFormModal extends Component {
  
  getTrigger() {
    return <Button primary icon={this.props.icon} />;
  }
  
  render() {
    return <Modal
        trigger={this.getTrigger()}
        header={intlU(this.props.title)}
        actions={[{key: "done", content: this.props.action ? intlU(this.props.action) : intlU("done"), positive: true}]}
        content={<div style={{backgroundColor:"#e7e9eb", paddingLeft:"1em", paddingRight:"1em"}}><SampleForm
          onChange={this.props.onChange}
          defaultSample={this.props.animal}
          isFilter={this.props.isFilter}
        /></div>}
      >
    </Modal>;
  }
}

export default SampleFormModal;