import React, { Component } from "react";
import {
  Table,
  Icon,
} from "semantic-ui-react";
import { intlU, intlIcon } from "../../intl";

class AnalysisRow extends Component {
  render() {
    return (
      <Table.Row>
        <Table.Cell>
          <a href={"/analyses/" + this.props.analysis.id}>
            {this.props.analysis.name}
          </a>
        </Table.Cell>
        <Table.Cell>
          <Icon name={intlIcon(this.props.analysis.type)} />
          {intlU(this.props.analysis.type)}
        </Table.Cell>
        <Table.Cell>
          <Icon name={intlIcon(this.props.analysis.status)} />
          {intlU(this.props.analysis.status)}
        </Table.Cell>
      </Table.Row>
    );
  }
}

class PureAnalysisTable extends Component {
  render() {
      let rows = [];
      if (this.props.analyses.length > 0) {
        rows = this.props.analyses.map((analysis) =>
            <AnalysisRow
              analysis={analysis}
              key={analysis.id}
            />);
      } else {
        rows = (
          <Table.Row>
            <Table.Cell colSpan="4" textAlign="center">
              {intlU("noanalysisavailable")}
            </Table.Cell>
          </Table.Row>
        );
      }

      return (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{intlU("name")}</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>{intlU("status")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
      );
    }
}

export default PureAnalysisTable;
