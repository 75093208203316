import React, { Component } from "react";
import {
  Form, Modal, Button, Icon
} from "semantic-ui-react";
import axios from "axios";
import SampleFormModal from "./SampleFormModal";
import { intlU } from "../../intl";
import { TopLevelWrapper, SectionWrapper } from "../Wrappers";
import SampleGrid from "./SampleGrid";

class SamplesList extends Component {
  state = {
    loading: true,
    samples: [],
    filter: {}
  };

  componentDidMount() {
    if(this.props.preFilter) {
      this.setState({filter: this.props.preFilter}, () => this.filter())
    } else {
      this.filter();
    }
  }


  filter = () => {
    const filter_obj = {...this.state.filter};
    if(filter_obj.groups && Array.isArray(filter_obj.groups) && filter_obj.groups.every((x) => x.id)) {
      filter_obj.groups = filter_obj.groups.map((x) => x.id)
    }
    axios
      .post("/api/samples/filter/", filter_obj)
      .then((res) => res.data)
      .then((samples) => this.setState({ samples: samples, loading: false }));
  };

  onFilterChange = (newFilter) => {
    this.setState({filter: newFilter}, () => {this.filter()});
  }

  onSelectionChange = (selection) => {
    if(this.props.onSelectionChange) {
      this.props.onSelectionChange(selection, this.state.samples.filter((x) => selection.includes(x.id)));
    }
  }

  getHeader() {
    if(this.props.preFilter) {
      return null;
    } else {
      const headerText = intlU("showingXSamplesBefore")  + " " + this.state.samples.length + " " + intlU("showingXSamplesAfter");
      return <>
        <SampleFormModal 
          onChange={this.onFilterChange}
          isFilter
          icon="filter"
          action="filterAction"
          title="filterCriteria"
        />
        {headerText}
      </>;
    }
  }

  getGrid() {
    return <SampleGrid
      samples={this.state.samples}
      onSampleChange={this.filter}
      loading={this.state.loading}
      select={this.props.select || this.props.selectUnique}
      onSelectionChange={this.onSelectionChange}
    />;
  }
  
  getListWrapper() {
    if(!this.props.preFilter) {
      return <SectionWrapper header={this.getHeader()}>
        {this.getGrid()}
        {this.props.children}            
      </SectionWrapper>
    } else {
      return <>
        {this.getGrid()}
        {this.props.children}           
      </>
    }
  }

  render() {
    return(
      <TopLevelWrapper icon="lab" header={this.props.header}>
        {this.getListWrapper()}
      </TopLevelWrapper>
    );
  }
}

export default SamplesList;
