import React, { Component } from "react";
import { Segment, Header, Icon, Button, Modal, Popup } from "semantic-ui-react";
import { SubmitErrorModal } from "./SubmitErrorMessage"
import { intlU } from "../intl";
import {submitRequest, submitRequestAndRedirect } from "./SubmitUtils";

class TopLevelWrapper extends Component {
  render() {
    return  <Segment raised secondary vertical style={{"paddingLeft":"1em", "paddingRight":"1em"}}>
      <Header as="h1">
        {this.props.icon && <Icon name={this.props.icon} />}
        <Header.Content>{intlU(this.props.header)}</Header.Content>
      </Header>
      {this.props.children}
    </Segment>
  };
}

class SectionWrapper extends Component {
  header() {
    if(this.props.icon || this.props.header) {
      return <Header as="h2">
        {this.props.icon && <Icon name={this.props.icon} />}
        <Header.Content>{intlU(this.props.header)}</Header.Content>
      </Header>
    }
  }
  
  render() {
    return <Segment raised tertiary>
      {this.header()}
      {this.props.children}
    </Segment>
  }
}

class MainButton extends Component {
  render() {
    return <Button
        primary
        fluid
        icon
        labelPosition="left"    
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <Icon name={this.props.icon} />
        {intlU(this.props.label)}
    </Button>;
  }
}

class SaveButton extends Component {
  render() {
    return <Button
        primary
        fluid={!this.props.notFluid}
        floated={!this.props.notFluid ? null : "right"}        
        icon
        labelPosition="left"    
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <Icon name={"save"} />
        {intlU("save")}
    </Button>;
  }  
}

class DeleteButton extends Component {
  render() {
    return <Button
        negative
        fluid={!this.props.notFluid}
        icon
        floated={!this.props.notFluid ? null : "right"}
        labelPosition="left"    
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <Icon name={"delete"} />
        {intlU("delete")}
    </Button>;
  }  
}

class DeleteIcon extends Component {
  render() {
    return <Icon
      link
      name="trash alternate"
      color="red"
      onClick={this.props.onClick}
      disabled={this.props.disabled}
    />
  }
}

class DeleteModal extends Component {
  state = {
    submitErrors: [],
    open: false,
  }
  
  deleteOnClick = () => {
    if(!this.props.onSuccess) {
      submitRequestAndRedirect("delete", this.props.objectType, this.props.object,
        (errors) => this.setState({submitErrors: errors}),
        this.props.redirectURL)
    } else {
      submitRequest("delete", this.props.objectType, this.props.object,
        (res) => this.props.onSuccess(res),
        (errors) => this.setState({submitErrors: errors}))
    }
  }

  canDelete() {
    return (!("accessLevel" in this.props.object) || this.props.object.accessLevel === "write") && 
      (!("collectionCount" in this.props.object) || this.props.object.collectionCount === 0) &&
      (!("analysisCount" in this.props.object) || this.props.object.analysisCount === 0);
  }

  trigger() {
    if(this.props.compact) {
      return <DeleteIcon 
        disabled={this.props.disabled || !this.canDelete()} 
        onClick={() => this.setState({open:true})}
      />      
    } else {
     return <DeleteButton 
        disabled={this.props.disabled || !this.canDelete()} 
        notFluid={true}
        onClick={() => this.setState({open:true})}
      />    
    }
  }

  modalButton() {
    // Disabled buttons do not trigger pop-ups, so we need to wrap it in a div.
    return <div style={{ display: "inline-block", float:"right" }}>
      {this.trigger()}
    </div>;
  }

  disabledPopupContent() {
    if(this.props.object.accessLevel !== "write") {
      return intlU("noDeleteNoWriteAccess");
    } else {
      return intlU("noDeleteInUse");
    }
  }

  modalTrigger() {
    return <Popup 
      trigger={this.modalButton()}
      content={this.disabledPopupContent()}   
      disabled={this.canDelete()}
    />;
  }
  
  render() {
    return <Modal 
      open={this.state.open} 
      trigger={this.modalTrigger()} 
      onOpen={() => this.setState({open:true})}
      onClose={() => this.setState({open:false})}
      >
      <Modal.Header>{intlU("confirmDeletionHeader")}</Modal.Header>
      <Modal.Content>{intlU("confirmDeletionContent")}</Modal.Content>
      <Modal.Actions>
        <Button negative icon labelPosition="left" onClick={() => this.setState({open:false})}>
          <Icon name="cancel" />
          {intlU("no")}
        </Button>
        <Button positive icon labelPosition="left" onClick={this.deleteOnClick} >
          <Icon name="delete" />
          {intlU("yes")}
        </Button>
      </Modal.Actions>
      <SubmitErrorModal 
        contextPrefix={this.props.contextPrefix} 
        errors={this.state.submitErrors} 
        onDismiss={() => this.setState({submitErrors: [], open:false})} 
      />
    </Modal>;
  }
}

class EditButton extends Component {
  canEdit() {
    return this.props.object.accessLevel === "write";
  }
  
  onClick = (e) => {
    e.preventDefault();
    window.location.assign("/" + this.props.objectType + "/edit/" + this.props.object.id);
  };  
  
  editButton() {
    // Disabled buttons do not trigger pop-ups, so we need to wrap it in a div.    
    return <div style={{ display: "inline-block", float:"right" }}>
      <Button
        icon
        labelPosition="left"
        floated="right"
        primary
        onClick={this.onClick}
        disabled={!this.canEdit()}
      >
        <Icon name="edit" />
        {intlU("edit")}
      </Button>
    </div>;
  }
  
  render() {
    return <Popup
      trigger={this.editButton()}
      content={intlU("noDeleteNoWriteAccess")}
      disabled={this.canEdit()}
    />
  }
}

export {TopLevelWrapper, SectionWrapper, MainButton, SaveButton, DeleteButton, DeleteModal, EditButton };        