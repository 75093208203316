import React, { Component } from "react";
import {
  Table,
  Dropdown,
  Grid
} from "semantic-ui-react";
import { intlU } from "../../intl";
import LoadingStandIn from "../LoadingStandIn";
import {submitRequestAndRedirect } from "../SubmitUtils";
import { SubmitErrorMessage } from "../SubmitErrorMessage";
import {SaveButton, DeleteButton }from "../Wrappers";


class PermissionRow extends Component {
  accessLevels = [
      {value: null, text: intlU("accessNone")},
      {value: "read", text: intlU("read")},
      {value: "read_full", text: intlU("read_full")},
      {value: "write", text: intlU("write")},
  ];

  render() {
    return <Table.Row>
      <Table.Cell>
        {intlU(this.props.permission.group.name)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Dropdown
          options={this.accessLevels}
          onChange={this.props.handleLevelChange}
          defaultValue={this.props.permission.level}
          selection
        />
      </Table.Cell>
    </Table.Row>;
  }
}

class PermissionsTable extends Component {
  handleLevelChange = (groupId) => (event, value) => {
    const newPermissions = [...this.props.user.permissions];
    const newLevel = value.value;
    
    var existingPermissionIndex = newPermissions.findIndex((permission) => permission.groupId === groupId);
    if(existingPermissionIndex === -1 && newLevel) {
      // We're adding access to a group which did not have any.
      var group = this.props.groups.find((x) => x.id === groupId)
      newPermissions.push({userId: this.props.user.id, groupId: groupId, level: newLevel, group: group})
    } else if(existingPermissionIndex !== -1 && newLevel) {
      // We're changing the access level fo a group which already existed.
      newPermissions[existingPermissionIndex].level = newLevel;
    } else if(existingPermissionIndex !== -1  && !newLevel) {
      // We're removing access to a group.
      newPermissions.splice(existingPermissionIndex, 1)
    }
    
    this.props.handlePermissionsChange(newPermissions);
  }
  
  getRows() {
    var displayPermissions = this.props.groups.map((group) => {
      var permission = this.props.user.permissions.find((permission) => permission.groupId === group.id)
      if(permission) {
        return permission;
      } else {
        return {userId: this.props.user.id, groupId: group.id, level: null, group: group}
      }
    })
    
    return displayPermissions.map((permission) => <PermissionRow 
      permission={permission} 
      key={permission.userId + "_" + permission.groupId} 
      handleLevelChange={this.handleLevelChange(permission.groupId)}
    />)
  }
  
  render() {
    return <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{intlU("group")}</Table.HeaderCell>
          <Table.HeaderCell>{intlU("accessLevel")}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.getRows()}
      </Table.Body>
    </Table>;
  }
}

class UserRow extends Component {
  state = {
    user: {
      permissions: []
    }
  }
  
  userRoles = [
      {value:"root", text: intlU("root")},
      {value:"sadmin", text: intlU("sadmin")},
      {value:"user", text: intlU("user")},
      {value:"unauthorized", text: intlU("unauthorized")},
  ];  
  
  // We'll be keeping a copy of the user to modify.
  componentDidMount() {
    this.setState({user:this.props.user});
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.user !== this.props.user) {
      this.setState({user: this.props.user})
    }
  }
  
  deleteUser = (userId) => () => {
    submitRequestAndRedirect("delete", "users", this.props.user, this.props.onError)
  };

  editUser = () => {
    submitRequestAndRedirect("put", "users", this.state.user, this.props.onError)
  };

  handleChangeSelect = (input) => (event, value) => {
    var newUser = {...this.state.user}
    newUser.role = value.value
    this.setState({ user: newUser });
  };

  handlePermissionsChange = (permissions) => {
    var newUser = {...this.state.user}
    newUser.permissions = permissions;
    this.setState({user: newUser})
  }

  fullname = () => {
    return this.state.user.firstName + " " + this.state.user.lastName;
  };

  render() {
    return (
      <Table.Row>
        <Table.Cell>
          <a href={"mailto:" + this.state.user.email}><b>{this.fullname()}</b></a>
        </Table.Cell>
        <Table.Cell>
          <Dropdown
            options={this.userRoles}
            onChange={this.handleChangeSelect("role")}
            defaultValue={this.props.user.role}
            selection
            disabled={this.state.user.role==="root"}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">
          <PermissionsTable user={this.state.user} groups={this.props.groups} handlePermissionsChange={this.handlePermissionsChange} />
        </Table.Cell>

        <Table.Cell>
          <Grid columns='equal'>
            <Grid.Row width={2}>
              <Grid.Column>
                <SaveButton
                  onClick={this.editUser}
                />
              </Grid.Column>
              <Grid.Column>
                <DeleteButton
                  disabled={this.state.user.role === "root"}
                  onClick={this.deleteUser(this.state.user.id)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
      </Table.Row>
    );
  }
}

class UserTable extends Component {
  state = {
    loading1: true,
    loading2: true,
    users: [],
    groups: [],
    submitErrors: [],
  };

  async getUsers() {
    fetch("/api/users")
      .then((res) => res.json())
      .then((users) => this.setState({ loading1: false, users: users }));
  }

  async getGroups() {
    fetch("/api/groups")
      .then((res) => res.json())
      .then((groups) =>
        this.setState({ loading2: false, groups: groups })
      );
  }

  componentDidMount() {
    this.getUsers();
    this.getGroups();
  }

  onError = (errors) => {
    this.setState({submitErrors: errors})
  }

  render() {
    let rows = [];
    if (!this.state.loading && !this.state.loading2) {
      if (this.state.users.length > 0) {
        for (var i = 0; i < this.state.users.length; i++) {
          rows.push(
            <UserRow
              user={this.state.users[i]}
              key={this.state.users[i].id}
              groups={this.state.groups}
              onError={this.onError}
            />
          );
        }
      } else {
        rows = (
          <Table.Row>
            <Table.Cell colSpan="5" textAlign="center">
              {intlU("error")}
            </Table.Cell>
          </Table.Row>
        );
      }
    }

    return (
      <LoadingStandIn active={this.state.loading || this.state.loading2} >
        <SubmitErrorMessage
          errors={this.state.submitErrors}
          contextPrefix="userModify"
          onDismiss={() => this.setState({submitErrors: []})}
        />  
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{intlU("name")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("userrole")}</Table.HeaderCell>
              <Table.HeaderCell>{intlU("authorizedGroup")}</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{rows}</Table.Body>
        </Table>
      </LoadingStandIn>
    );
  }
}

export default UserTable;
