import React, { Component } from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { intlU } from "../intl";

class LoadingStandIn extends Component {
  render() {
    if(this.props.active) {
      return(
        <Segment>
          <Dimmer active>
            <Loader>{intlU("loading")}</Loader>
          </Dimmer>
        </Segment>    
      );
    } else {
      return this.props.children;
    }
  };
}

export default LoadingStandIn;