import React, { Component } from "react";
import {
  Header,
  Icon,
  Table,
  Button,
  Modal,
  Progress,
  Popup
} from "semantic-ui-react";
import { intlU } from "../../intl";
import axios from "axios";
import { makeHeader } from "./TableUtils";

function canEditSample(sample) {
  return sample.accessLevel === "write";
}

class AccessPopup extends Component {
  render() {
    // We need a containing div to make the popup work with a disabled button.
    const divStyle = { 
      display: "inline-block",
      float: this.props.floated ? this.props.floated : "none" 
    }
    
    var actionEnabled = this.props.sample.accessLevel !== "read";
    if(this.props.write) {
      actionEnabled = this.props.sample.accessLevel === "write";
    }
    
    var disabled = false;
    var content = null;
    if(actionEnabled && this.props.enabledContent) {
      content = this.props.enabledContent;
    } else if(actionEnabled && !this.props.enabledContent) {
      disabled = true
    } else if(!actionEnabled && this.props.disabledContent) {
      content = this.props.disabledContent;
    } else if(!actionEnabled) {
      content = intlU('uploadNoWriteAccess')
    }

    return <Popup 
      disabled={disabled}
      trigger={<div style={divStyle}>{this.props.children}</div>}
      content={content}
    />;
  }
}

class UploadGenotypeModal extends Component {
  state = {
    selectedFile: null,
    uploadPercent: 0,
    submitErrors: []
  }
  
  onChangeFileHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };  

  uploadOnClick = () => {
    const data = new FormData();
    data.append("sampleFile", this.state.selectedFile);

    const options = {
      onUploadProgress: (progress) => {
        const { uploaded, total } = progress;
        let percent = Math.floor((uploaded * 100) / total);

        if (percent < 100) {
          this.setState({ uploadPercent: percent });
        }
      },
    };

    axios
      .post(
        "/api/samples/attach_file/" + this.props.sample.id,
        data,
        options,
        {}
      )
      .then((res) => {
        if (res.status === 200) {
          if(this.props.onSuccess) {
            this.props.onSuccess(this.props.sample.id)
          } else {
            window.location.assign("/samples/" + this.props.sample.id);
          }
        } else {
          this.setState({submitErrors: res.data});
        }
      }).catch((err) => {
        if(err.response.status === 400) {
          this.setState({submitErrors: err.response.data});
        } else if(err.response.status === 401) {
          this.setState({submitErrors: [{field:"unauthorized", key: "unauthorized"}]});
        } else {
          this.setState({submitErrors: [{field:"unknown", key: "unknown"}]});
        }
      })
  };  

  errorContent() {
    if(this.state.submitErrors.length === 0) {
      return null;
    } else if(this.state.submitErrors[0].key === "file") {
      return intlU("uploadWrongFormat");
    } else if(this.state.submitErrors[0].key === "unauthorized") {
      return intlU("uploadNoWriteAccess");
    } else if(this.state.submitErrors[0].key === "unknown") {
      return intlU("uploadUnknownError");
    }
  }

  
  render() {
    return <Modal trigger={this.props.children} {...this.props}>
      <Modal open={this.state.submitErrors.length > 0} onClose={() => this.setState({submitErrors: []})}>
        <Header><Icon size="large" name="exclamation circle" color="red"/>{intlU("error")}</Header>
        <Modal.Content>{this.errorContent()}</Modal.Content>
        <Modal.Actions><Button positive onClick={() => this.setState({submitErrors: []})}>{intlU("OK")}</Button></Modal.Actions>
      </Modal>
      <Header icon="upload" content={intlU("uploadGenotypeFile")} />
      <Modal.Content>
        <p>{intlU("chooseGenotypeFile")}</p>
        <input
          type="file"
          name="sampleFile"
          onChange={this.onChangeFileHandler}
        />
        <br />
        <br />
      
        <Progress
          percent={this.state.uploadPercent}
          label={this.state.uploadPercent + "%"}
          indicating
          autoSuccess
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          icon
          labelPosition="left"
          floated="right"
          onClick={this.uploadOnClick}
        >
          <Icon name="upload" />
          {intlU("upload")}
        </Button>
        <br />
        <br />
        <br />
      </Modal.Actions>
    </Modal>;    
  }
}

class UploadGenotypeButton extends Component {
  state = {
    openModal: false,
  };

  onClick = (e) => this.setState({openModal:true})

  clickableIcon() {
    return <Icon 
      link 
      name="upload" 
      color="blue"
      disabled={!canEditSample(this.props.sample)}
      onClick={this.onClick}
    />;
  }
  
  clickableButton() {
    return <Button icon secondary 
      labelPosition="left" 
      floated="right" 
      disabled={!canEditSample(this.props.sample)} 
      onClick={() => this.setState({openModal:true})}
    >
      <Icon name="upload" />
      {this.props.compact || intlU("uploadGenotypeFile")}
    </Button>;
  }

  uploadButton() {
    return <AccessPopup 
      sample={this.props.sample}
      write
      floated={this.props.compact ? null : "right" }
      enabledContent={this.props.compact ? intlU("uploadGenotypeFile") : null}
      disabledContent={intlU("uploadNoWriteAccess")}
    >
      {this.props.compact ? this.clickableIcon() : this.clickableButton()}
    </AccessPopup>;
  };

  render() {
    return <UploadGenotypeModal 
      open={this.state.openModal} 
      onClose={() => this.setState({openModal: false})}
      sample={this.props.sample}
      onSuccess={(id) => {this.props.onSuccess(id); this.setState({openModal: false})}}
    >
      {this.uploadButton()}
    </UploadGenotypeModal>
  }    
}

class DownloadGenotypeButton extends Component {
  fileDownloadOnClick = () => {
    axios
      .get("/api/samples/attach_file/" + this.props.sample.id)
      .then((res) => {
        if (res.status === 404) {
          alert(intlU("fileNoFound"));
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.props.sample.animalId + ".vcf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);			
		    }
      })
      .catch((err) => {
        alert(intlU("error") + err);
      });
  };  
  
  clickableButton() {
    return <Button onClick={this.fileDownloadOnClick} disabled={this.props.sample.accessLevel === "read"}>
      <Icon name="download" />
      {this.props.compact || intlU("download") + " " + this.props.sample.animalId + ".vcf"}
    </Button>    
  }
  
  clickableIcon() {
    return <Icon 
      link 
      name="download" 
      color="green"
      disabled={this.props.sample.accessLevel === "read"} 
      onClick={this.fileDownloadOnClick}
    />
  }
  
  render() {
    return <AccessPopup sample={this.props.sample} enabledContent={this.props.compact ? intlU("download") : null} read>
      {this.props.compact ? this.clickableIcon() : this.clickableButton()}
    </AccessPopup>;
  }    
}

class DeleteGenotypeButton extends Component {
  fileDeleteOnClick = () => {
    axios.delete("/api/samples/attach_file/" + this.props.sample.id).then(() => {
      if(this.props.onSuccess) {
        this.props.onSuccess(this.props.sample.id)
      } else {
        window.location.assign("/samples/" + this.props.sample.id);
      }
    }).catch((err) => {
      alert(intlU("error") + err);
    });
  };    
  
  clickableIcon() {
    return <Icon
      link
      name="delete"
      color="red"
      onClick={this.fileDeleteOnClick}
      disabled={!canEditSample(this.props.sample)}
    />
  }
  
  clickableButton() {
    return <Button
      color="red"
      icon
      labelPosition="left"
      floated="right"
      onClick={this.fileDeleteOnClick}
      size= "medium"
      disabled={!canEditSample(this.props.sample)}
    >
      <Icon name="delete" />
      {this.props.compact || intlU("delete") + " " + this.props.sample.animalId + ".vcf"}
    </Button>
  }
  
  render() {
    return <AccessPopup sample={this.props.sample} floated="right" write>
      {this.props.compact ? this.clickableIcon() : this.clickableButton()}
    </AccessPopup>    
  }
}

class GenotypeTable extends Component {
  state = {
    openModal: false
  };
    
  fileContent = () => {
    if (this.props.sample.hasGenotype) {
      return <>
        <DownloadGenotypeButton sample={this.props.sample} />
        <DeleteGenotypeButton sample={this.props.sample} />
      </>;
    } else {
      return <>
        {intlU("noGenotypeFile")}  
        <UploadGenotypeButton sample={this.props.sample} />
      </>;
    }
  };  
  
  render() {
    return (
      <Table>
        {makeHeader("genotypeFile")}
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={2}>{this.fileContent()}</Table.Cell>
          </Table.Row>    
        </Table.Body>
      </Table>
    );
  }
}

class GenotypeControls extends Component {
  render() {
    if (this.props.sample.hasGenotype) {
      return <>
        <DownloadGenotypeButton 
          compact 
          sample={this.props.sample} 
          onSuccess={this.props.onDownloadSuccess}
          onError={this.props.onDownloadError}
        />
        <DeleteGenotypeButton 
          compact 
          sample={this.props.sample}
          onSuccess={this.props.onDeleteSuccess}
          onError={this.props.onDeleteError}
        />
      </>;
    } else {
      return <>
        <UploadGenotypeButton
          compact 
          sample={this.props.sample}
          onSuccess={this.props.onUploadSuccess}
          onError={this.props.onUploadError}
        />
      </>;
    }    
  }
}

export { GenotypeTable, GenotypeControls };