import React, { Component } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { intlU } from "../intl";

class MainPageMenu extends Component {
  buildDropdownItem = (label, target) => {
    return (
      <Dropdown.Item
        as={Link}
        to={target}
      >
        {intlU(label)}
      </Dropdown.Item>
    );
  };

  adminMenu = () => {
    if (this.props.userrole === "sadmin" || this.props.userrole === "root") {
      return (
        <Dropdown.Item>
          <Icon name='dropdown' />
          <span className='text'>{intlU("sadmin")}</span>
        
          <Dropdown.Menu>
            {this.buildDropdownItem("manageUsers", "/users/")}
            {this.buildDropdownItem("manageGroups", "/groups/")}
          </Dropdown.Menu>
        </Dropdown.Item>      
      );
    } else {
      return null;
    }
  };

  userMenu = () => {
    if (this.props.userrole === "sadmin" || this.props.userrole === "root" || this.props.userrole === "user") {    
      return <>
        <Dropdown.Item>
          <Icon name='dropdown' />
          <span className='text'>{intlU("sample")}</span>
        
          <Dropdown.Menu>
            {this.buildDropdownItem("samplesNew", "/samples/new")}
            {this.buildDropdownItem("samplesList", "/samples/")}
            {this.buildDropdownItem("batchImportNew", "/batchImport/new")}
            {this.buildDropdownItem("batchImportGenotype", "/batchImport/genotype")}
            {this.buildDropdownItem("manageVocabularies", "/vocabularies/")}
          </Dropdown.Menu>
        </Dropdown.Item>
        
        <Dropdown.Item>
          <Icon name='dropdown' />
          <span className='text'>{intlU("collections")}</span>
        
          <Dropdown.Menu>
            {this.buildDropdownItem("collectionsNew", "/collections/new")}
            {this.buildDropdownItem("collectionsList", "/collections/")}
          </Dropdown.Menu>
        </Dropdown.Item>              
        
        <Dropdown.Item>
          <Icon name='dropdown' />
          <span className='text'>{intlU("analyses")}</span>
        
          <Dropdown.Menu>
            {this.buildDropdownItem("analysesNew", "/analyses/new")}
            {this.buildDropdownItem("analysesList", "/analyses/")}
          </Dropdown.Menu>
        </Dropdown.Item>      
      </>;
    } else {
      return null;
    }
  }

  render() {
    return (
        <Dropdown item trigger={<Icon name="sidebar" size="big" />} simple>
          <Dropdown.Menu>
            {this.buildDropdownItem("home", "/")}
            {this.adminMenu()}
            {this.userMenu()}            
          </Dropdown.Menu>
        </Dropdown>        
    );
  }
}

export default MainPageMenu;
