const tableMap = [
//    {table:"/api/users/role", name:"roles"},
	{table:"/api/groups", name:"groups", id: "groupId"},
	{table:"/api/species", name:"species", id: "speciesId"},	  
	{table:"/api/sampletypes", name:"samples_type", id: "samplesTypeId"},	  
	{table:"/api/countries", name:"country", id: "countryId"},
	{table:"/api/provinces", name:"province", id: "provinceId"},
	{table:"/api/populations", name:"population", id: "populationId"},
	{table:"/api/ecotypes", name:"ecotype", id: "ecotypeId"},
	{table:"/api/sectors", name:"sector", id: "sectorId"},
  {table:"/api/ages", name: "age", id: "ageId"},
  {table:"/api/owners", name: "owner", id: "ownerId"},
];

class FetchValueMaps {
  constructor(readyCB) {
    // Keep track of the callback.
	  this.readyCB = readyCB;
      
	  // Initialize the map where we'll store our fetched tables.
	  this.valueMaps = new Map();
	  this.valueMaps.set("idToName", new Map());
	  this.valueMaps.set("nameToId", new Map());
	  
    this.cacheMap = new Map();
	  // Initialize the ready flags.
	  // We need to initialize all flags to false before we start fetching the tables,
	  // in the unlikely scenario where we fetch all submitted tables before
	  // we're done submitting them.
	  // I'm not sure that's even possible under the javascript threading model,
	  // but better safe than sorry.
	  this.readyFlags = new Map();
	  tableMap.forEach( element =>  this.readyFlags.set(element.name, false) );
	  
	  // Start the async calls to fetch the tables.
	  tableMap.forEach( element =>  this.getOneTable(element.table, element.name) );
  }

  // Function to fetch one table from the backend and add it to the
  // value map. The table should all have one id and one name column.
  async getOneTable(tableName, varName) {
    fetch(tableName)
      .then((res) => res.json())
      .then((jsonRes) => {

      this.cacheMap.set(varName, jsonRes);
		  this.valueMaps.get("idToName").set(varName, new Map());
		  this.valueMaps.get("nameToId").set(varName, new Map());
      
		  jsonRes.forEach(element => {
		  	this.valueMaps.get("idToName").get(varName).set(element.id, element.name);
		  	this.valueMaps.get("nameToId").get(varName).set(element.name, element.id);
		  });			
		  
		  this.readyFlags.set(varName, true);
		  
		  if(this.isReady()) {
		  	this.readyCB(this.valueMaps);
		  }
    });	  
  }

  noSpace(string) {
    return string.replaceAll("_", "").replaceAll(" ", "");
  }

  vocabMatch(query, vocab) {
    const spaceLess = this.noSpace(query);
    return (spaceLess.localeCompare(this.noSpace(vocab.name), "fr", {sensitivity: "base"}) === 0 ||
            spaceLess.localeCompare(this.noSpace(vocab.fr), "fr", {sensitivity: "base"}) === 0 ||
            spaceLess.localeCompare(this.noSpace(vocab.en), "en", {sensitivity: "base"}) === 0)    
  }

  getId = (table, name) => {
    const vocab = this.cacheMap.get(table).find((x) => this.vocabMatch(name, x))
    return vocab ? vocab.id : null;
  }
  
  getVocab = (table, id) => {
    return this.cacheMap.get(table).find((x) => x.id === id)
  }
  
  getName = (table, id) => {
    var tableMap = this.valueMaps.get("idToName").get(table)
	  return tableMap ? tableMap.get(id) : null;
  }
  
  hasTable = (table) => {
    return tableMap.map((x) => x.name).includes(table);
  }

  getForeignKey = (table) => {
    return tableMap.find((x) => x.name === table).id;
  }
  
  // Returns true if all tables have been fetched.
  isReady = () => {
	  var ready = true;
	  tableMap.forEach( element => {
	  	if(!this.readyFlags.get(element.name)) {
	  		ready = false;
	  	}
	  });
	
	  return ready;
  }


}

export default FetchValueMaps;