import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { intlU } from "../intl";

class TermsAndConditions extends Component {
  render() {
    return (
      <div>
        <Segment vertical>
          <p>
            {intlU("welcomeToCARLA")}, {this.props.firstname}!
          </p>
        </Segment>
        TERMS AND CONDITIONS
      </div>
    );
  }
}

export default TermsAndConditions;
