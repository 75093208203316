import React, { Component } from "react";
import FilteredAnalysesTable from "./FilteredAnalysesTable";
import { TopLevelWrapper }from "../Wrappers";

class AnalysesRecent extends Component {
  render() {
    return (
      <TopLevelWrapper icon="laptop" header="analysesRecent">
        <FilteredAnalysesTable 
          filter={{userId: this.props.userId}}
        />
      </TopLevelWrapper>
    );
  }
}

export default AnalysesRecent;
