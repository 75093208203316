// Copied from https://medium.com/@faizanv/authentication-for-your-react-and-express-application-w-json-web-tokens-923515826e0
// withAuth.jsx

import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        userid: "",
        firstname: "",
        lastname: "",
        email: "",
        role: "",
        permissions: [],
      };
    }

    componentDidMount() {
      fetch("/api/checkToken")
        .then((res) => {
          if (res.status === 200) {
            return res.json().then((user) => {
              this.setState({
                loading: false,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                role: user.role,
                userid: user.id,
                permissions: user.permissions
              });
            });
          } else {
            return res.json().then((err) => {
              console.log(err)
              this.setState({ loading: false, redirect: true });
            })
          }
        })
        .catch((err) => {
          console.error(err);
          this.setState({ loading: false, redirect: true });
        });
    }

    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      return (
        <React.Fragment>
          <ComponentToProtect
            {...this.props}
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            email={this.state.email}
            userrole={this.state.role}
            userid={this.state.userid}
            userGroupid={this.state.userGroupid}
            authorizedGroup={this.state.authorizedGroup}
          />
        </React.Fragment>
      );
    }
  };
}
