import React, { Component } from "react";
import { List, } from "semantic-ui-react";
import { intlU } from "../intl";
import { TopLevelWrapper } from "./Wrappers";

class ContactUs extends Component {
  render() {
    return (
      <TopLevelWrapper icon="address book" header="contactUs">
        <List>
          <List.Item>
            <List.Header>{intlU("projectHead")}</List.Header>
            <a href="mailto:claude.robert@fsaa.ulaval.ca" >Claude Robert</a>
          </List.Item>
          <List.Item>
            <List.Header>{intlU("webDevelopment")}</List.Header>
            <a href="mailto:eric.fournier@bioinfoef.com">Eric Fournier</a>
          </List.Item>        
        </List>
      </TopLevelWrapper>
    );
  }
}

export default ContactUs;
