import React, { Component } from "react";
import {
  Header,
  Icon,
  Form,
  Input,
  Accordion,
  Label,
  Segment,
  Grid
} from "semantic-ui-react";
import { intlU, intlIcon } from "../../intl";
import ModelOptions from "../../model_options";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import VocabularySelector from "../VocabularySelector";
import GroupSelector from "../GroupSelector";
import RangeInput from "../RangeInput";
import { validateSample} from "./SampleUtils"
    
const cookie = require("cookie");

class SectionAccordion extends Component {
  state = {
    isOpen: false
  }
  
  toggleAccordion = () => {
    this.setState({isOpen : !this.state.isOpen})
  }
  
  componentDidMount() {
    this.setState({isOpen: !!this.props.defaultOpen})
  }
  
  render() {
    return (
      <Accordion>
        <Accordion.Title onClick={this.toggleAccordion}>
          <Header className="sectionAccordion">
            <span>
              <Icon name={intlIcon(this.props.name)} />
              {intlU(this.props.name)}
              <Icon name={this.state.isOpen ? "triangle down" : "triangle right" }/>
            </span>
          </Header>
        </Accordion.Title>
        <Accordion.Content active={!!this.state.isOpen}>      
          {this.props.children}
        </Accordion.Content>
      </Accordion>    
    );
  }
}

class SampleFormSegment extends Component {
  
  render() {
    return <Segment basic vertical>
      <Grid stackable>
        {this.props.rows.map((row) => <Grid.Row columns={row.length} style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>{row.map((column) => <Grid.Column>{column}</Grid.Column>)}</Grid.Row>)}
      </Grid>
    </Segment>  
  }
}

class SampleForm extends Component {
  state = {
    filter: {
    },
    errors: []
  };

  fieldHasError(propname) {
    return this.state.errors.some((x) => x.field === propname)
  }

  fieldErrorLabel(propname) {
    if(!this.fieldHasError(propname)) {
      return null
    } else {
      var fieldError = this.state.errors.find((x) => x.field === propname);
      var message = intlU(fieldError.field) + " " + intlU("error" + fieldError.key)
      return <Label pointing color="red">{message}</Label>
    }
  }

  buildInputField = (label, propname, isDisabled = false, isRequired = false) => {
    return (
      <Form.Field error={this.fieldHasError(propname)} required={isRequired && !this.props.isFilter}>
        <label>{intlU(label)}</label>
        <Input
          onChange={this.handleSampleChange(propname)}
          type={"text"}
          disabled={isDisabled}
          fluid
          defaultValue={this.props.defaultSample ? this.props.defaultSample[propname] : null}
        />
        {this.fieldErrorLabel(propname)}
      </Form.Field>
    );
  };

  componentDidMount() {
    var defaultSample = {...this.props.defaultSample};
    if(!defaultSample) defaultSample = {};
    
    this.setState({filter: defaultSample});
  }

  validateAndSetState = (newFilter) => {
    var newErrors = [];
    if(!this.props.isFilter) {
      newErrors = validateSample(newFilter)
    }
    this.setState({ filter: newFilter, errors: newErrors }, () => {this.props.onChange(this.state.filter)});        
  }

  handleNumberChange = (propertyName) => (event, value) => {
    var newFilter = {...this.state.filter};
    var newValue = 0;    
    if(!value) {
      newValue = event.target.value;
    } else {
      newValue = value.value;      
    }
    
    if(!isNaN(Number(newValue))) {
      newFilter[propertyName] = Number(newValue)
      this.validateAndSetState(newFilter);
    }
  }

  handleSampleChange = (propertyName) => (event, value) => {
    var newFilter = {...this.state.filter};
    if(!value) {
      newFilter[propertyName] = event.target.value;
    } else {
      newFilter[propertyName] = value.value;      
    }
    
    this.validateAndSetState(newFilter);
  }

  handleVocabChange = (idName, vocabName) => (vocab) => {
    var newFilter = {...this.state.filter};
    newFilter[vocabName] = vocab ? vocab : null;
    if(idName) {
      newFilter[idName] = vocab ? vocab.id : null;
    }
    
    this.validateAndSetState(newFilter);
  }

  remoteVocab = (singularName, parentId, pluralName, idName, required=false, vocabName=null) => {
    pluralName = pluralName || singularName + "s"
    idName = idName || singularName + "Id"
    vocabName = vocabName ? vocabName : singularName
    return (
      <VocabularySelector
        objectType={pluralName}
        source={"/api/" + pluralName}
        label={singularName}
        onChange={this.handleSampleChange(idName)}
        onChangeVocab={this.handleVocabChange(idName, vocabName)}
        parentId={parentId ? this.state.filter[parentId] : null}
        parentIdName={parentId}
        disableIfParentNull={!!parentId}
        defaultValue={this.props.defaultSample ? this.props.defaultSample[idName] : null}
        errorLabel={this.fieldErrorLabel(idName)}
        isRequired={required && !this.props.isFilter}
        any={this.props.isFilter}        
        allowAdd={!this.props.isFilter}
      />
    );
  }

  localVocab = (attribute, options) => {
    return (
      <VocabularySelector
        source={options}
        label={attribute}
        onChange={this.handleSampleChange(attribute)}
        defaultValue={this.props.defaultSample ? this.props.defaultSample[attribute] : null}
        errorLabel={this.fieldErrorLabel(attribute)}
        any={this.props.isFilter}
      />
    );    
  }

  rangeShortcut = (name, min, max, disabled) => {
    if(this.props.isFilter) {
      return(
        <RangeInput
          label={name}
          onChange={this.handleNumberChange(name)}
          min={min}
          max={max}
        />      
      );
    } else {
      return(
        <Form.Field>
          <label>{intlU(name)}</label>
          <Input
            fluid
            type="number"
            onChange={this.handleNumberChange(name)}
            min={min}
            max={max}
            defaultValue={this.props.defaultSample ? this.props.defaultSample[name] : null}
            disabled={disabled}
          />          
          {this.fieldErrorLabel(name)}
        </Form.Field>
      );
    }
  }
  
  // Because of the way submitting samples to the backend works, sometimes sample.groups
  // will be an array of Group objects {id, name, fr, en, etc), and sometimes it will
  // be an array of integers corresponding to the group ids.
  // When passing in the default values for the GroupSelector, we must always pass in the
  // id-array form.
  // This function detects what was passed in and return a suitable default for the GroupSelector.
  setDefaultGroups() {
    if(this.props.defaultSample && this.props.defaultSample.groups && Array.isArray(this.props.defaultSample.groups)) {
      if(this.props.defaultSample.groups.every((x) => !isNaN(Number(x)))) {
        return this.props.defaultSample.groups;
      } else {
        console.log(this.props.defaultSample.groups)
        return this.props.defaultSample.groups.map((x) => x.id);
      }
    } else {
      // No groups were provided, so we use an empty array.
      return [];
    }
  }
  
  getGroupsControl() {
    return <GroupSelector
        onVocabChange={this.handleVocabChange(null, "groups")}
        defaultGroups={this.setDefaultGroups()}
        errorLabel={this.fieldErrorLabel("groups")}
        isRequired={!this.props.isFilter}
        minLevel={this.props.isFilter ? null : "write"}
    />;
  }
  
  buildSampleField(type, locale) {
    return <SemanticDatepicker
        onChange={this.handleSampleChange(type)}
        value={
          this.state.filter[type]
            ? new Date(this.state.filter[type])
            : null
        }
        locale={locale}
        showToday={false}
        fluid
      />;
  }
  
  getSampleDateControl() {
    const cookieLocale = cookie.parse(document.cookie).locale || "fr-CA";
    const datePickerLocale = cookieLocale === "fr-CA" ? "fr-FR" : "en-US";   
    
    if(!this.props.isFilter) {
      return <Form.Field>
        <label>{intlU("sampleDate")}</label>   
        {this.buildSampleField("sampleDate", datePickerLocale)}
      </Form.Field>;
    } else {
      return <Form.Field>
        <label>{intlU("sampleDateFromTo")}</label>   
        {this.buildSampleField("sampleDateFrom", datePickerLocale)} - 
        {this.buildSampleField("sampleDateTo", datePickerLocale)}
      </Form.Field>
    }
  }
  
  render() {
 
    
    return (<Form>
      <SampleFormSegment 
        label="Id"
        rows={[
          [
            this.buildInputField("animalId", "animalId", false, true),
            this.buildInputField("gappId", "gappId", false, true),
            this.getGroupsControl()
          ]
        ]}
      />
      
      <SampleFormSegment 
        label="sample"
        rows={[
          [
            this.remoteVocab("sampletypes", null, "sampletypes", "samplesTypeId", true),
            this.getSampleDateControl()
          ]
        ]}
      />        
      
      <SampleFormSegment 
        label="animal"
        rows={[
          [
            this.localVocab("sex", ModelOptions.sex),
            this.remoteVocab("ages", null, "ages", "ageId"),
            this.remoteVocab("species", null, "species", "speciesId", true),            
            this.remoteVocab("ecotype", "speciesId")
          ]
        ]}
      />

      <SampleFormSegment 
        label="location"
        rows={[
          [
                this.remoteVocab("country", null, "countries"),
                this.remoteVocab("province", "countryId"),
                this.remoteVocab("sector", "provinceId"),
                this.remoteVocab("population", "provinceId")
          ],
          [
                this.rangeShortcut("latitude", -90, 90),
                this.rangeShortcut("longitude", -190, 180)
          ]
        ]}
      />
      
      <SampleFormSegment 
        label="extra"
        rows={[
          [
            this.remoteVocab("owner", null),
            this.localVocab("followupTele", ModelOptions.yesno),
            this.localVocab("followupCond", ModelOptions.yesno),
            this.props.isFilter && this.localVocab("hasGenotype", [{name: "no", id: "0"}, {name:"yes", id:"1"}])            
          ]
        ]}
      />
    </Form>);
  }
}

export default SampleForm;