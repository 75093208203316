import React, { Component } from "react";
import {
  Form, Message
} from "semantic-ui-react";
import CSVDragAndDrop from "./CSVDragAndDrop";
import BatchEdit from "./BatchEdit";
import GroupSelector from "../../GroupSelector";
import {TopLevelWrapper, SectionWrapper, MainButton} from "../../Wrappers";
import { intlU } from "../../../intl";

class BatchImportNew extends Component {
  state = {
    animals: [],
    groups: [],
    startImport: false,
    importStatus: []
  };

  animalCB = (animals) => {
    this.setState({animals: animals, importStatus: Array(animals.length).fill("waiting")});
  }

  onGroupChange = (groups) => {
    this.setState({groups: groups})
  }

  startImport = () => {
    var newImportStatus = [...this.state.importStatus]
    newImportStatus[0] = "importing"
    this.setState({startImport: true, importStatus: newImportStatus})
  }

  onImportDone = (index, animal, status) => {
    var newImportStatus = [...this.state.importStatus]
    newImportStatus[index] = status
    newImportStatus[newImportStatus.indexOf("waiting")] = "importing"
    this.setState({importStatus: newImportStatus})    
  }

  onImportSuccess = (index, animal) => {
    this.onImportDone(index, animal, "success")
  }
  
  onImportError = (index, animal) => {
    this.onImportDone(index, animal, "error")
  }

  onAnimalChange = (animals) => {
    this.setState({animals: animals})
  }

  render() {
    return (
       <TopLevelWrapper icon="clipboard list" header="enterBatchImport">
        <SectionWrapper>
          <Message
            icon='cloud upload'
            header={intlU("batchImportMesageHeader")}
            content={<>{intlU("batchImportMesageContentBefore")} <a href='../modele.csv'>{intlU("batchImportMesageContentFile")}</a> {intlU("batchImportMesageContentAfter")}</>}
          />
          <Form>
          <GroupSelector
            onVocabChange={this.onGroupChange}
            defaultGroups={[]}
            disabled={this.state.animals.length > 0}
            minLevel="write"
          />
          </Form>
          <CSVDragAndDrop 
            groups={this.state.groups}
            animalCB={this.animalCB}
          />
        </SectionWrapper>
        <SectionWrapper>
          <BatchEdit 
            animals={this.state.animals}
            onAnimalChange={this.onAnimalChange}
            startImport={this.state.importStatus.map((status) => status === "importing")}
            importStatus={this.state.importStatus}
            onSuccess={this.onImportSuccess}
            onError={this.onImportError}
          />
          <MainButton 
            disabled={this.state.startImport || (this.state.animals.length === 0)} 
            onClick={this.startImport}
            icon="upload"
            label="importAllSamples"
          />
        </SectionWrapper>
      </TopLevelWrapper>
    );
  }
}

export default BatchImportNew;
