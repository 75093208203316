import React, { Component } from "react";
import {
  Table,
  Icon,
  Popup,
  Form,
  Button
} from "semantic-ui-react";
import { intlU } from "../../intl";
import LoadingStandIn from "../LoadingStandIn";
import { SectionWrapper, SaveButton, DeleteButton } from "../Wrappers";
import {submitRequest } from "../SubmitUtils";
import { SubmitErrorMessage } from "../SubmitErrorMessage";

class GroupRow extends Component {
  deleteGroups = () => {
    submitRequest("delete", "groups", this.props.group,
      () => window.location.assign("/groups/"),
      (errors) => this.props.onError(errors)
    );
  };

  render() {
    return (
      <Table.Row>
        <Table.Cell>{intlU(this.props.group.name)}</Table.Cell>
        <Table.Cell>{intlU(this.props.group.fr)}</Table.Cell>
        <Table.Cell>{intlU(this.props.group.en)}</Table.Cell>
        <Table.Cell>
          <DeleteButton
            notFluid
            onClick={this.deleteGroups}
          />
        </Table.Cell>        
      </Table.Row>
    );
  }
}

class GroupsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loading: true,
      groups: [],
      submitDeleteErrors: [],
      submitCreateErrors: [],
    };
  }

  async getGroups() {
    fetch("/api/groups")
      .then((res) => res.json())
      .then((groups) =>
        this.setState({ loading: false, groups: groups })
      );
  }

  componentDidMount() {
    this.getGroups();
  }

  nullOrUndefined = (val) => {
    return typeof val === "undefined" || val === null || val === "";
  };

  validAll = () => {
    return !this.nullOrUndefined(this.state.fr) && !this.nullOrUndefined(this.state.en);
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  submitAndSave = (e) => {
    e.preventDefault();
    
    submitRequest("post", "groups", { name: this.state.fr, fr: this.state.fr, en: this.state.en },
      () => window.location.assign("/groups/"),
      (errors) => this.setState({submitCreateErrors: errors})
    );
  };

  onDeleteError = (errors) => {
    this.setState({submitDeleteErrors: errors})
  }

  render() {
    let rows = [];
    if(!this.state.loading) {
      if (this.state.groups.length > 0) {
        for (var i = 0; i < this.state.groups.length; i++) {
          rows.push(
            <GroupRow
              group={this.state.groups[i]}
              key={this.state.groups[i].id}
              onError={this.onDeleteError}
            />
          );
        }
      } else {
        rows = (
          <Table.Row>
            <Table.Cell colSpan="5" textAlign="center">
              {intlU("error")}
            </Table.Cell>
          </Table.Row>
        );
      }
    }

    return(
      <LoadingStandIn active={this.state.loading} >
        <SubmitErrorMessage
          errors={this.state.submitCreateErrors}
          contextPrefix="userGroupCreate"
          onDismiss={() => this.setState({submitCreateErrors:[]})}
        />
        <SubmitErrorMessage
          errors={this.state.submitDeleteErrors}
          contextPrefix="userGroupDelete"
          onDismiss={() => this.setState({submitDeleteErrors:[]})}
        />        
        <SectionWrapper header={intlU("addGroups")}>
          <Form>
            <Form.Group inline>
              <Form.Field required={true}>
                <label> {intlU("french")} </label>
                <input
                  name="fr"
                  onChange={this.handleInputChange}
                  type="text"
                />
              </Form.Field>
              <Form.Field required={true}>
                <label> {intlU("english")} </label>
                <input
                  name="en"
                  onChange={this.handleInputChange}
                  type="text"
                />
              </Form.Field>                    
              <SaveButton
                notFluid
                onClick={this.submitAndSave}
                disabled={!this.validAll()}
              />
            </Form.Group>
          </Form>        
        </SectionWrapper>

        <SectionWrapper header={intlU("deleteGroups")}>        
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{intlU("name")}</Table.HeaderCell>
                <Table.HeaderCell>{intlU("french")}</Table.HeaderCell>
                <Table.HeaderCell>{intlU("english")}</Table.HeaderCell>
                <Table.HeaderCell>{intlU("delete")}</Table.HeaderCell>                
              </Table.Row>
            </Table.Header>          
            <Table.Body>{rows}</Table.Body>
          </Table>
        </SectionWrapper>
      </LoadingStandIn>
    );
  }
}

export default GroupsTable;
