import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
  Modal,
  Icon
} from "semantic-ui-react";
import { intlU } from "../../intl";

class SubmitModal extends Component {
  isSuccess() {
    return this.props.submitState === "emailSent"
  }
  
  getHeader() {
    if(this.isSuccess()) {
      return <Header><Icon color="green" name="checkmark" />{intlU("success")}</Header>;
    } else {
      return <Header><Icon color="red" name="close" />{intlU("error")}</Header>;      
    }
  }

  getActions() {
    if(this.isSuccess()) {
      return <Button positive onClick={this.props.onClose}>{intlU("goToLogin")}</Button>
    } else {
      return <Button negative onClick={this.props.onClose}>{intlU("OK")}</Button>
    }      
  }
  
  render() {
    return <Modal
      onClose={this.props.closeModal}
      open={!!this.props.submitState}
      actions={["OK"]}
      size="small"
    >      
      {this.getHeader()}
      <Modal.Content>
        {intlU(this.props.submitState)}
      </Modal.Content>
      <Modal.Actions>
        {this.getActions()}
      </Modal.Actions>
    </Modal>    
  }
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      submitState: null
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  login = (e) => {
    e.preventDefault();
    fetch("/api/resetPassword", {
      method: "PUT",
      body: JSON.stringify({email: this.state.email, password: this.state.password}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res.status)
        if (res.status === 200) {
          this.setState({submitState: "emailSent"});
        } else if (res.status === 404){
          this.setState({submitState: "forgotPasswordNoEmail"});
        } else {
          this.setState({submitState: "forgotPasswordErrorUnknown"});
        }
      })
      .catch((err) => {
        this.setState({submitState: "forgotPasswordErrorUnknown"});
      });
  };

  closeModal = (e) => {
    if(this.state.submitState === "emailSent") {
      this.props.history.push("/");
    } else {
      this.setState({submitState: null})
    }
  }

  render() {
    return (
      <div className="login-form" style={{    position: "absolute", top: "50%", left: "50%",   transform: "translate(-50%, -50%)"}}>
        <Grid
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="black" textAlign="center">
              <Image src="/logo.png" />
              {intlU("resetPassword")}
            </Header>
            <SubmitModal submitState={this.state.submitState} onClose={this.closeModal} />
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder={intlU("emailPrompt")}
                  name="email"
                  onChange={this.handleInputChange}
                />
                <Button
                  style={{ background: "#217666", color: "white" }}
                  fluid
                  size="large"
                  onClick={this.login}
                >
                  {intlU("send")}
                </Button>
              </Segment>
            </Form>
            <Message>
              {intlU("noAccount")} <a href="/signup">{intlU("signUp")}</a>
            </Message>
            <Message>
              <a href="/login">{intlU("login")}</a>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default ForgotPassword;
