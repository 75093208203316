import React, { Component } from "react";
import {
  Form,
  Message,
} from "semantic-ui-react";
import { intlU } from "../../../intl";
import GroupSelector from "../../GroupSelector";
import VocabularySelector from "../../VocabularySelector";
import VCFProgress from "./VCFProgress";
import ImportGenotypeTable from "./ImportGenotypeTable.jsx";
import {TopLevelWrapper, SectionWrapper, MainButton} from "../../Wrappers";

class BatchImportGenotypes extends Component {
  state = {
    startImport: false,
    sampleList: [],
    sampleFiles: [],
    readVCFState: "none",
    headerInfo: null,
    vcfFile: null,
    groups: 1,
    speciesId: null,
  };

  showMessage() {
    if(this.state.readVCFState === "errorHeader") {
      return <Message negative header="Error" content={intlU("errorVCFHeader")} />
    }
  }

  showImportAllButton() {
    if(this.state.readVCFState === "success") {
      return <MainButton 
        disabled={this.state.startImport} 
        onClick={() => {this.setState({startImport: true})}}
        icon="upload"
        label="importAll"
      />
    } else {
      return null;
    }
  }

  onGroupsChange = (event, value) => {
    this.setState({groups: value.value})
  }
  
  onSpeciesChange = (event, value) => {
    this.setState({speciesId: value.value})    
  }

  onVCFSelect = (event) => {
    this.setState({vcfFile: event.target.files[0]})
  }
  
  onVCFHeader = (sampleList, headerInfo) => {
    this.setState({sampleList: sampleList, headerInfo: headerInfo, readVCFState: "reading"})
  }
  
  onVCFSuccess = (sampleFiles) => {
    this.setState({sampleFiles: sampleFiles, readVCFState: "success"})
  }
  
  onVCFError = (errorType) => {
    if(errorType === "header") {
    this.setState({readVCFState: "errorHeader"})
    } else {
      this.setState({readVCFState: "errorGenotype"})
    }
  }
  
  render() {
    return (
      <TopLevelWrapper icon="clipboard list" header="enterBatchImport">
        <SectionWrapper>        
          <Form>
            <Form.Group inline>
              <label>{intlU("vcfFile")}</label>
              <input
                type="file"
                name="sampleFile2"
                accept=".vcf"
                onChange={this.onVCFSelect}
              />
            </Form.Group>
            <Form.Group inline  widths='equal'>
              <GroupSelector
                onChange={this.onGroupsChange}
                defaultGroup={1}
                disabled={this.state.startImport}
              />
              <VocabularySelector
                source={"/api/species"}
                label={"species"}
                onChange={this.onSpeciesChange}
              />
            </Form.Group>
          </Form>
        </SectionWrapper>
        <SectionWrapper>
          {this.showMessage()}
          <VCFProgress 
            vcfFile={this.state.vcfFile}
            onHeaderRead={this.onVCFHeader}
            onSuccess={this.onVCFSuccess}
            onError={this.onVCFError}
          />
          {this.showImportAllButton()}
          <ImportGenotypeTable
            sampleList={this.state.sampleList}
            headerInfo={this.state.headerInfo}
            startImport={this.state.startImport}
            sampleFiles={this.state.sampleFiles}
            speciesId={this.state.speciesId}
            groups={this.state.groups}
          />
        </SectionWrapper>
      </TopLevelWrapper>
    );
  }
}

export default BatchImportGenotypes;
