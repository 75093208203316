import axios from "axios";

function submitRequest(verb, objectType, object, onSuccess, onError) {
  var url = "/api/" + objectType;
  if(verb === "put" || verb === "delete") {
    url = "/api/" + objectType + "/" + object.id;
  }
  
  axios({
    method: verb,
    url: url,
    data: object
  }).then((res) => {
    onSuccess(res.data);
  }).catch((err) => {
    if(err.response.status === 400) {
      onError(err.response.data);
    } else if(err.response.status === 401) {
      onError([{field:"unauthorized", key: "unauthorized"}])
    } else {
      onError([{field:"unknown", key: "unknown"}])
    }
  });  
  
}

function submitRequestAndRedirect(verb, objectType, object, onError, redirectURL=null) {
  submitRequest(verb, 
    objectType, 
    object, 
    (res) => {
      if(!redirectURL) {
        redirectURL = verb === "delete" ? "/" + objectType + "/" :  "/" + objectType + "/" + res.id;
      }
      window.location.assign(redirectURL);
    },
    onError,     
  );
}

export {submitRequest, submitRequestAndRedirect };