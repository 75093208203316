import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { intlU } from "../intl";

import MySamples from "./samples/SamplesListRecent";
import AnalysisTableRecent from "./analysis/AnalysisTableRecent";

class MainPage extends Component {
  render() {
    return (
      <div>
        <Segment vertical>
          <p>
            {intlU("welcomeToCARLA")}, {this.props.firstname}!
          </p>
        </Segment>
        <Segment vertical>
          <AnalysisTableRecent userId={this.props.userId}/>
        </Segment>
        <MySamples header={intlU("samplesRecent")} userId={this.props.userId}/>
      </div>
    );
  }
}

export default MainPage;
